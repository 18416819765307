import { forEach } from 'lodash-es';

import { type IElementDom } from '../enums';

export class DomService {
	public static runOnDomReady(fn: any): void {
		if (typeof fn !== 'function') {
			return;
		}

		if (document.readyState === 'complete') {
			fn();
		} else {
			document.addEventListener('DOMContentLoaded', fn, false);
		}
	}

	public static createElement<T extends HTMLElement>(
		elementParams: IElementDom
	): T {
		const { tag, classNames, template, attributes } = elementParams;
		const element = document.createElement(tag ?? 'div');

		if (classNames?.length) {
			element.classList.add(...classNames);
		}

		if (template) {
			element.innerHTML =
				typeof template === 'function' ? template() : template;
		}

		if (attributes) {
			forEach(attributes, (attribute: string, key: string) => {
				element.setAttribute(key, attribute);
			});
		}

		return element as T;
	}

	public static getElements<T>(
		selector: string,
		node?: any
	): T[] | undefined {
		if (!selector) {
			return undefined;
		}

		const elements = (node || document).querySelectorAll(selector);

		if (!elements) {
			return undefined;
		}

		return elements;
	}

	public static getElement<T>(selector: string, node?: any): T | undefined {
		if (!selector) {
			return undefined;
		}

		const elements = (node || document).querySelector(selector);
		document.querySelector(selector);

		if (!elements) {
			return undefined;
		}

		return elements;
	}

	public static insertAfter(newNode: Node, referenceNode: Node): void {
		if (!referenceNode.parentNode) {
			return;
		}

		referenceNode.parentNode.insertBefore(
			newNode,
			referenceNode.nextSibling
		);
	}

	public static areMissingElementsInDOM(
		elements: Record<string, HTMLElement | Element>
	): boolean {
		return Object.values(elements).some(
			(element: HTMLElement | Element) => !element
		);
	}

	public static initButtonAnimation(
		buttonElements: Array<HTMLElement | Element>
	): void {
		if (!buttonElements) {
			throw new Error('Missing animate button elements');
		}

		if (buttonElements.length && 'IntersectionObserver' in window) {
			const options: IntersectionObserverInit = {
				threshold: 1
			};
			const observerCallback = (
				entries: IntersectionObserverEntry[]
			): void => {
				entries.forEach((entry: IntersectionObserverEntry) => {
					if (entry.intersectionRatio) {
						entry.target.classList.add('animate-border');
					}
				});
			};

			const observer = new IntersectionObserver(
				observerCallback,
				options
			);

			buttonElements.forEach((element: HTMLElement) => {
				observer.observe(element);
			});
		} else if (buttonElements.length) {
			buttonElements.forEach((element: HTMLElement) => {
				element.classList.add('animate-border');
			});
		}
	}

	public static disableBodyScroll(): void {
		document.body.style.overflow = 'hidden';
	}

	public static enableBodyScroll(): void {
		document.body.style.overflow = '';
	}
}
