export enum PaymentOperatorGateStatus {
	Ok = 'ok',
	Degraded = 'degraded',
	Inactive = 'inactive'
}

export interface IPaymentOperator {
	displayName: string;
	logoUrl: string;
	countryCode: string;
	name: string;
	availableCurrencies: string;
	limitCurrency: string;
	minDeposit: number;
	maxDeposit: number;
	minWithdrawalAmount: number;
	maxWithdrawalAmount: number;
	minWithdrawalFee: number | string;
	maxWithdrawalFee: number;
	percentWithdrawalFee: number;
	minutesToDeposit: string;
	minutesToWithdrawal: string;
	isDepositEnabled: boolean;
	isWithdrawalEnabled: boolean;
	operatorDisplayName: string;
	gateStatus: PaymentOperatorGateStatus;
	isOperational: boolean;
}
