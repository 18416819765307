import { CollectionSearch } from '../modules';
import { DomService } from '../services';

export class HelpCenterPage {
	constructor() {
		if (document.querySelector('body.page-help-center')) {
			this.init();
		}
	}

	private init(): void {
		const search = new CollectionSearch('.faq__group');
	}
}

DomService.runOnDomReady(() => new HelpCenterPage());
