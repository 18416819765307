import axios, { type AxiosResponse } from 'axios';
import { assign, isEmpty } from 'lodash-es';

import '../../../scripts/telegram-passport';

import { API, AXIOS_OPTIONS } from '../../config';
import { AnalyticsService, UtilsService } from '../../services';

import { emailValidator } from './sign-up.utils';

export class SignUpService {
	private getCampaignParams(unilinkAffiliateId?: string | null): {
		campaignSource?: string | null;
		campaignName?: string | null;
	} {
		if (UtilsService.getUrlParam('utm_source')) {
			return {
				campaignSource: UtilsService.getUrlParam('utm_source'),
				campaignName: UtilsService.getUrlParam('utm_campaign') ?? ''
			};
		}

		// gclid, gclsrc
		if (
			UtilsService.getUrlParam('gclid') ??
			UtilsService.getUrlParam('gclsrc')
		) {
			return {
				campaignSource: 'AdWords',
				campaignName: UtilsService.getUrlParam('adg') ?? ''
			};
		}

		if (unilinkAffiliateId) {
			return {
				campaignSource: 'Unilink',
				campaignName: unilinkAffiliateId
			};
		}

		return {};
	}

	public isEmailValid(input: HTMLInputElement): boolean {
		return (
			input.value.length > 0 &&
			input.validity.valid &&
			emailValidator(input.value)
		);
	}

	public areFormConsentsChecked(input: HTMLInputElement): boolean {
		return input.checked;
	}

	public async submitByEmail(
		userEmail: string,
		consentChecked: boolean,
		captchaToken: string
	): Promise<AxiosResponse> {
		const sfxRParam = UtilsService.getUrlParam('sfx-r');
		const gclidParam = UtilsService.getUrlParam('gclid');
		const uniId = AnalyticsService.getUniId();
		const promoCodeParam = UtilsService.getUrlParam('promo_code');
		const referrerParam = UtilsService.getUrlParam('referrer');

		const params = {
			email: userEmail,
			captcha: {
				token: captchaToken,
				type: 'ReCaptcha'
			},
			options: {},
			userSource: 'HOME', // TODO: consider sending full url path, same as LP register event,
			unilinkAffiliateId: null
		};

		if (sfxRParam) {
			assign(params, {
				referrer: sfxRParam // TODO: ask backend to sending referrer param
			});
		}

		if (gclidParam) {
			assign(params, {
				trackingKey: 'gclid',
				trackingValue: gclidParam
			});
		}

		if (uniId) {
			assign(params, {
				unilinkAffiliateId: uniId
			});
		}

		const campaignParams = this.getCampaignParams(
			params.unilinkAffiliateId
		);
		if (!isEmpty(campaignParams)) {
			assign(params, {
				campaign: campaignParams
			});
		}

		if (consentChecked) {
			assign(params, {
				termsAndConditionsAccepted: true,
				gdprAccepted: true,
				marketingAccepted: true
			});
		}

		if (promoCodeParam && referrerParam) {
			assign(params, {
				promoCode: promoCodeParam,
				referrer: referrerParam
			});
		}

		const headers = [
			['X-Captcha-Type', 'ReCaptcha'],
			['X-Captcha-Authorization', captchaToken]
		];

		return await axios.post(
			`${API.backend}/v3/auth/register`,
			params,
			assign({}, AXIOS_OPTIONS, { headers })
		);
	}

	public async registerBySocial(
		provider: string
	): Promise<{ redirectUrl: string }> {
		return await axios
			.get(
				`${API.backend}/v3/auth/external/ExternalAuthenticationParameters?provider=${provider}`,
				AXIOS_OPTIONS
			)
			.then((response: AxiosResponse) => response.data.data);
	}

	public async registerByTelegram(): Promise<{ nonce: string }> {
		return await axios
			.get(
				`${API.backend}/v3/auth/external/telegram/token`,
				AXIOS_OPTIONS
			)
			.then((response: AxiosResponse) => response.data.data);
	}
}
