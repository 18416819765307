import { entries, every, forEach, values } from 'lodash-es';

import { CustomSelect, Tabs } from '../modules';
import { DomService, UtilsService } from '../services';
import { HEADER_HEIGHT } from '../config';
import { InstrumentPage } from './utils';
import { Chart } from '../modules/chart';

interface ITradingInterval {
	from: number;
	to: number;
}
type TradingDay = Array<ITradingInterval>;
type TradingWeek = Array<TradingDay>;

const MAX_SECONDS_IN_DAY: number = 86399;
const DAY_IN_SECONDS: number = 86400;

const getHours = (seconds: number): number => Math.floor(seconds / (60 * 60));
const getMinutes = (seconds: number): number => Math.floor((seconds / 60) % 60);
const getFormattedDate = (seconds: number): string =>
	`${getHours(seconds)}:${getMinutes(seconds) < 10 ? '0' : ''}${getMinutes(
		seconds
	)}`;

export class InstrumentsPage extends InstrumentPage {
	private isMobile: boolean;
	private statisticsHighElements: HTMLElement[] | undefined;
	private statisticsLowElements: HTMLElement[] | undefined;
	private timezoneOffset: number = 0;

	constructor() {
		super();

		if (document.querySelector('body.page-instrument-single-item')) {
			this.init();
			this.initElements();
			this.initCalculator();
			this.initTradingHours();
			this.initSimilarInstrumentsPagination(
				parseFloat(this.instrumentData.similarPageCount)
			);
			this.initBlogCarousel();
			this.initChart();

			setTimeout(() => {
				this.initWebQuotes();
			}, 1000); //  init quotes after 1s to avoid "uninterested" users and not overload Quotes server
		}
	}

	private init(): void {
		this.isMobile = UtilsService.isMobile();

		const tabs = new Tabs();

		const readMoreBtn = DomService.getElement<HTMLElement>(
			`#read-long-description-btn`
		);
		const longDescription =
			DomService.getElement<HTMLElement>(`#long-description`);

		const descriptionSection = DomService.getElement<HTMLElement>(
			`.instrument__section-description`
		);

		const tradingSpecificationTitle = DomService.getElement<HTMLElement>(
			`.instrument__trading-specification-title`
		);

		const tradingSpecificationSection = DomService.getElement<HTMLElement>(
			`.instrument__trading-specifications`
		);

		this.statisticsHighElements = DomService.getElements<HTMLElement>(
			`.instrument__statistic-value--high`
		);
		this.statisticsLowElements = DomService.getElements<HTMLElement>(
			`.instrument__statistic-value--low`
		);

		if (readMoreBtn && longDescription && descriptionSection) {
			readMoreBtn.addEventListener('click', (event) => {
				event.preventDefault();
				longDescription.classList.toggle('hidden');
				readMoreBtn.classList.toggle('active');

				if (!readMoreBtn.classList.contains('active')) {
					UtilsService.scrollIntoViewWithOffset(
						this.isMobile ? longDescription : descriptionSection,
						HEADER_HEIGHT
					);
				}
			});
		}

		if (tradingSpecificationTitle && tradingSpecificationSection) {
			tradingSpecificationTitle.addEventListener('click', () => {
				tradingSpecificationTitle.classList.toggle('active');
				tradingSpecificationSection.classList.toggle('hidden');
			});
		}
	}

	private initCalculator(): void {
		const inputElement = DomService.getElement<HTMLInputElement>(
			`.instrument__calculator-form input`
		);
		const currencyElement = DomService.getElement<HTMLElement>(
			'.instrument__calculator-currency-wrapper'
		);
		const sourceValueElement = DomService.getElement<HTMLElement>(
			'#calculator-source-value'
		);
		const destinationValueElement = DomService.getElement<HTMLElement>(
			'#calculator-destination-value'
		);

		if (
			!inputElement ||
			!currencyElement ||
			!sourceValueElement ||
			!destinationValueElement
		) {
			return;
		}

		inputElement.addEventListener('input', (event: InputEvent) => {
			const conversionRate = this.instrumentData?.conversionRate ?? 1;
			const value = (event.target as any)?.value;
			const convertedPrice = parseFloat(value) * conversionRate;

			sourceValueElement.innerText = value;
			destinationValueElement.innerText = convertedPrice.toFixed(
				this.instrumentData?.precision
			);
		});

		const currencySelect = new CustomSelect(currencyElement);
		currencySelect.subscribe((currency: string) => {});
	}

	private initTradingHours(): void {
		const wrapperElement = DomService.getElement<HTMLElement>(
			'.instrument__trading-hours-wrapper'
		);
		const hoursWrapperElement = DomService.getElement<HTMLElement>(
			'.instrument__statistics--hours'
		);
		const hoursElements = DomService.getElements<HTMLElement>(
			'.instrument__trading-hours-value',
			hoursWrapperElement
		);

		const tradingHoursTitle = DomService.getElement<HTMLElement>(
			`.instrument__trading-hours-title`
		);

		const tradingHoursSection = DomService.getElement<HTMLElement>(
			`.instrument__trading-hours-section`
		);

		if (!wrapperElement || !hoursElements) {
			return;
		}

		const week = this.prepareTradingHours(this.instrumentData.tradingHours);
		this.updateTradingHours(hoursElements, week);

		const timezoneSelect = new CustomSelect(wrapperElement);
		timezoneSelect.subscribe(([timezone]: string) => {
			this.timezoneOffset = Number(timezone);

			const week = this.prepareTradingHours(
				this.instrumentData.tradingHours
			);
			this.updateTradingHours(hoursElements, week);
		});

		if (this.isMobile && tradingHoursTitle && tradingHoursSection) {
			tradingHoursTitle.addEventListener('click', () => {
				wrapperElement.classList.toggle('active');
				tradingHoursTitle.classList.toggle('active');
				tradingHoursSection.classList.toggle('hidden');
			});
		}
	}

	private getJoinedIntervals(tradingWeek: TradingWeek): TradingWeek {
		const week: TradingWeek = [];
		const sortedTradingWeek = tradingWeek.map(
			(tradingDay: Array<ITradingInterval>) =>
				tradingDay.sort(
					(a: ITradingInterval, b: ITradingInterval) =>
						a.from - b.from
				)
		);

		sortedTradingWeek.forEach((dayIntervals: Array<ITradingInterval>) => {
			const day: TradingDay = [];

			if (dayIntervals.length > 1) {
				dayIntervals.forEach(
					(interval: ITradingInterval, index: number) => {
						if (
							day[index - 1] &&
							interval.from <= day[index - 1].to
						) {
							// SKIP this interval, because it was added by extending previous interval
						} else if (
							dayIntervals[index + 1] &&
							interval.to + 1 === dayIntervals[index + 1].from
						) {
							const extendedInterval = {
								from: interval.from,
								to: dayIntervals[index + 1].to
							};

							day.push(extendedInterval);
						} else {
							day.push(interval);
						}
					}
				);

				week.push(day);
			} else {
				week.push(dayIntervals);
			}
		});

		return week;
	}

	private prepareTradingHours(
		tradingHours: Array<Array<ITradingInterval>>
	): TradingWeek {
		if (!this.timezoneOffset) {
			return tradingHours;
		}

		const isNonStopTradeOpen = every(
			values(tradingHours),
			(daySessions: TradingDay, index: number, array: TradingWeek) => {
				return (
					array.length === 7 &&
					daySessions[0].from === 0 &&
					daySessions[0].to === MAX_SECONDS_IN_DAY
				);
			}
		);

		if (isNonStopTradeOpen) {
			return tradingHours;
		}

		const week: TradingWeek = [[], [], [], [], [], [], []];

		forEach(entries(tradingHours), (item: [string, TradingDay]) => {
			const initialDaySessions: TradingDay = item[1];
			const index: number = Number(item[0]);
			const day: TradingDay = [];

			forEach(initialDaySessions, (session: ITradingInterval) => {
				let from = session.from + this.timezoneOffset;
				let to = session.to + this.timezoneOffset;

				if (to === MAX_SECONDS_IN_DAY) {
					to = DAY_IN_SECONDS;
				}

				if (from < 0) {
					// Add interval to previous day
					const previousDayInterval = {
						from: DAY_IN_SECONDS + from,
						to: to >= 0 ? DAY_IN_SECONDS : DAY_IN_SECONDS + to
					};
					const previousIndex =
						index - 1 < 0 ? week.length - 1 : index - 1;

					week[previousIndex].push(previousDayInterval);
				} else if (to > DAY_IN_SECONDS) {
					// Add interval to next day
					const nextDayInterval = {
						from:
							from <= DAY_IN_SECONDS ? 0 : from - DAY_IN_SECONDS,
						to: to - DAY_IN_SECONDS
					};
					const nextIndex = index + 1 < week.length ? index + 1 : 0;

					week[nextIndex].push(nextDayInterval);
				}

				if (to > 0 && to <= DAY_IN_SECONDS && from < 0) {
					// Reset from to add today interval
					from = 0;
				}

				if (from >= 0 && from < DAY_IN_SECONDS) {
					// Add today interval
					const dayInterval = {
						from,
						to: Math.min(to, DAY_IN_SECONDS)
					};
					day.push(dayInterval);
				}
			});

			week[index].push(...day);
		});

		return this.getJoinedIntervals(week);
	}

	private updateTradingHours(
		hoursElements: Array<HTMLElement>,
		week: TradingWeek
	): void {
		forEach(hoursElements, (element: HTMLElement, index: number) => {
			const dayIntervals = week[index];
			if (dayIntervals?.length) {
				let text = '';

				forEach(dayIntervals, (interval: ITradingInterval) => {
					text += `<div>${getFormattedDate(
						interval.from
					)}-${getFormattedDate(
						interval.to === MAX_SECONDS_IN_DAY
							? DAY_IN_SECONDS
							: interval.to
					)}</div>`;
				});

				element.innerHTML = text;
			} else {
				element.innerHTML = this.instrumentData.closedText ?? '';
			}
		});
	}

	private initChart(): void {
		const chartContainer = DomService.getElement<HTMLElement>(
			'.chart-widget-container'
		);

		if (!chartContainer || !this.instrumentData.symbol) {
			return;
		}

		this.chart = new Chart(this.instrumentData.symbol, chartContainer);
	}

	public updateStatistics(price: number): void {
		if (this.statisticsHighElements) {
			forEach(this.statisticsHighElements, (element: HTMLElement) => {
				const oldPrice = parseFloat(element.innerText);

				if (price > oldPrice) {
					element.innerText = `${price}`;
				}
			});
		}

		if (this.statisticsLowElements) {
			forEach(this.statisticsLowElements, (element: HTMLElement) => {
				const oldPrice = parseFloat(element.innerText);

				if (price < oldPrice) {
					element.innerText = `${price}`;
				}
			});
		}
	}
}

DomService.runOnDomReady(() => new InstrumentsPage());
