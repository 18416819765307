import { escape, forEach, isString } from 'lodash-es';

import { DeviceEnum } from '../enums';
import { DomService } from './dom.service';
import { URL_PARAMS } from '../config';

export class UtilsService {
	private static instance: UtilsService;
	private windowWidth: number = window.innerWidth;
	private readonly windowResizeCallbacks: Array<() => void> = [];
	private queryString: string;

	private constructor() {
		this.initWindowResizeListener();
	}

	public static getInstance(): UtilsService {
		if (!UtilsService.instance) {
			UtilsService.instance = new UtilsService();
		}

		return UtilsService.instance;
	}

	public static getWindowSize(): number {
		const { innerWidth, outerWidth } = window;

		return Math.min(innerWidth, outerWidth);
	}

	public static getDevice(): DeviceEnum {
		const windowSize = this.getWindowSize();

		switch (true) {
			case windowSize <= 525:
				return DeviceEnum.Mobile;
			case windowSize > 525 && windowSize <= 765:
				return DeviceEnum.LargeMobile;
			case windowSize > 765 && windowSize <= 1024:
				return DeviceEnum.Tablet;
			case windowSize > 1024 && windowSize <= 1250:
				return DeviceEnum.Desktop;
			default:
				return DeviceEnum.LargeDesktop;
		}
	}

	public static isMobile(): boolean {
		return [DeviceEnum.Mobile, DeviceEnum.LargeMobile].includes(
			this.getDevice()
		);
	}

	public static getUnixStartOfDay(date: Date = new Date()): number {
		const day = date.getUTCDate();
		const month = date.getUTCMonth();
		const year = date.getUTCFullYear();

		return Date.UTC(year, month, day, 0, 0, 0, 0) / 1000;
	}

	public static async wait(ms: number): Promise<void> {
		await new Promise((resolve: any) => setTimeout(resolve, ms));
	}

	private initWindowResizeListener(): void {
		window.addEventListener('resize', () => {
			if (window.innerWidth !== this.windowWidth) {
				this.windowWidth = window.innerWidth;

				forEach(this.windowResizeCallbacks, (fn: () => void) => {
					fn();
				});
			}
		});
	}

	public runOnWindowResize(fn: () => void): void {
		if (!fn || typeof fn !== 'function') {
			return;
		}

		this.windowResizeCallbacks.push(fn);
	}

	public setQueryString(queryString: string): void {
		this.queryString = queryString;
	}

	public clearQueryParam(value: string): string {
		return escape(value);
	}

	public static removeQueryParams(paramName: string): void {
		const url = new URL(window.location.href);
		url.searchParams.delete(paramName);
		history.replaceState(history.state, '', url);
	}

	public static updateQueryParams(name: string, value: string): void {
		const url = new URL(window.location.href);
		url.searchParams.set(name, value);
		history.replaceState(history.state, '', url);
	}

	public getQueryParam(name: string): string | null {
		const match = RegExp('[?&]' + name + '=([^&]*)').exec(this.queryString);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	}

	public static isTouchDevice(): boolean {
		return (
			'ontouchstart' in window ||
			navigator.maxTouchPoints > 0 ||
			(navigator as any).msMaxTouchPoints > 0
		);
	}

	public static scrollIntoViewWithOffset(
		element: HTMLElement,
		pageOffset: number
	): void {
		const offset =
			element.getBoundingClientRect().top -
			document.body.getBoundingClientRect().top -
			pageOffset;

		window.scrollTo({
			behavior: 'smooth',
			top: offset
		});
	}

	public static formatPriceValue(price: number, currency?: string): string {
		return (
			price.toLocaleString('en-US').replace(/,/g, ' ') +
			(currency ? ` ${currency}` : '')
		);
	}

	public static isLeftToRightDirection(): boolean {
		return document.dir === 'ltr';
	}

	public static findClosestIndex(
		value: number,
		collection: Array<number>
	): number {
		return collection.reduce(
			(closestIndex: number, current: number, index: number) =>
				Math.abs(current - value) <
				Math.abs(collection[closestIndex] - value)
					? index
					: closestIndex,
			0
		);
	}

	public static getTranslation(key: string): string | null {
		let translation = null;

		const commonTranslations: string | null | undefined =
			DomService.getElement<HTMLScriptElement>(
				'script#commonTranslationsData'
			)?.textContent;

		if (!isString(commonTranslations)) {
			return translation;
		}

		try {
			const parsed = JSON.parse(commonTranslations);
			translation = parsed[key];
		} catch (_) {
			console.error(`Can't parse data`);
		}

		return translation;
	}

	public static getUrlParam(name: string): string | null {
		return URL_PARAMS.get(name)
			? escape(URL_PARAMS.get(name) ?? undefined)
			: null;
	}
}
