import { API } from '../config';
import {
	WebQuotesEvents,
	WebQuotesMethods,
	WebSocketConnectionStatus
} from '../enums';
import { Quote } from '../models';

export class QuoteService {
	private webSocket: WebSocket;
	private webSocketStatus: WebSocketConnectionStatus =
		WebSocketConnectionStatus.Disconnected;

	private subscriber: {
		topic: WebQuotesEvents;
		listener: (quotes: Quote[]) => void;
	};

	private get isConnected(): boolean {
		return this.webSocketStatus === WebSocketConnectionStatus.Connected;
	}

	private updateConnectionStatus(status: WebSocketConnectionStatus): void {
		this.webSocketStatus = status;
	}

	private invoke(
		methodName: WebQuotesMethods,
		data: string | string[]
	): void {
		if (this.isConnected) {
			this.webSocket.send(
				JSON.stringify({
					p: methodName,
					d: data
				})
			);
		}
	}

	private updateListener(topic: WebQuotesEvents, quotes: Quote[]): void {
		if (this.subscriber && this.subscriber.topic === topic) {
			this.subscriber.listener(quotes);
		}
	}

	public async initConnection(): Promise<void> {
		await new Promise((resolve: any, reject: any) => {
			this.webSocket = new WebSocket(API.webQuotes);

			this.webSocket.onmessage = (event: MessageEvent) => {
				const message = JSON.parse(event.data);
				const messageData = message.d;

				if (message?.p && messageData) {
					const quotes = messageData.map(
						(data: any) => new Quote(data)
					);

					switch (message.p) {
						case WebQuotesEvents.LastPrices:
							this.updateListener(
								WebQuotesEvents.LastPrices,
								quotes
							);
							break;
						case WebQuotesEvents.Quotes:
							this.updateListener(WebQuotesEvents.Quotes, quotes);
							break;
					}
				}
			};

			this.webSocket.onopen = () => {
				this.updateConnectionStatus(
					WebSocketConnectionStatus.Connected
				);
				resolve();
			};

			this.webSocket.onclose = () => {
				this.updateConnectionStatus(
					WebSocketConnectionStatus.Disconnected
				);
				reject(new Error('WebSocket Close'));
			};

			this.webSocket.onerror = (error: any) => {
				this.updateConnectionStatus(
					WebSocketConnectionStatus.Disconnected
				);
				reject(error);
			};
		});
	}

	public getLatestQuotes(symbols: string[]): void {
		this.invoke(WebQuotesMethods.LastPrices, symbols);
	}

	public watchSymbols(symbols: string[]): void {
		this.invoke(WebQuotesMethods.Subscribe, symbols);
	}

	public unwatchSymbols(symbols: string[]): void {
		this.invoke(WebQuotesMethods.Unsubscribe, symbols);
	}

	public subscribe(
		topic: WebQuotesEvents,
		listener: (quotes: Quote[]) => void
	): void {
		this.subscriber = { topic, listener };
	}
}
