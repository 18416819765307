import { DomService } from '../../services';

export abstract class VideoPlayerControls {
	public initPlayerControls(className: string): void {
		const videoPlayer = DomService.getElement<HTMLElement>(className);

		if (videoPlayer) {
			videoPlayer.addEventListener('click', () => {
				videoPlayer.setAttribute('controls', '');
			});
		}
	}
}
