import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { assign, forEach, keys, map } from 'lodash-es';

import { API, AXIOS_OPTIONS } from '../config';
import { timeToLocal } from '../modules/chart';

export interface ISessionChange {
	openValue: number;
	change: number;
	points: number;
}

export interface ICandle {
	time: number;
	open: number;
	high: number;
	low: number;
	close: number;
	volume?: number;
}

export interface IDailyCandle extends ICandle {
	prevClose: number;
}

export enum ResolutionToInterval {
	'1H' = 'H1',
	'4H' = 'H4',
	'1D' = 'D1',
	'1W' = 'W1',
	'1MN' = 'MN1'
}

export type ResolutionToIntervalKey = keyof typeof ResolutionToInterval;

const intervalToChartPeriod = {
	M1: 60,
	M5: 300,
	M15: 900,
	M30: 1800,
	H1: 3600,
	H4: 14400,
	D1: 86400,
	W1: 604800,
	MN1: 2592000
};

function getHistoryMonthsCount(resolution: ResolutionToInterval): number {
	switch (resolution) {
		case ResolutionToInterval['1H']:
			return 1;
		case ResolutionToInterval['4H']:
			return 4;
		case ResolutionToInterval['1W']:
			return 12 * 3;
		case ResolutionToInterval['1MN']:
			return 12 * 5;
		default:
			return 12;
	}
}

export class CandleService {
	public async getDailyChange(
		symbols: string[]
	): Promise<Record<string, ISessionChange>> {
		const symbolsString = encodeURIComponent(symbols.join(','));
		const startOfDay = new Date();
		startOfDay.setUTCHours(0, 0, 0, 0);
		const timeFrom = startOfDay.getTime() / 1000;

		const requestQueryParams = {
			symbols: symbolsString,
			timeFrom
		};

		return await axios
			.get(
				`${API.candles}/v3/candles/daily/withPreviousClose`,
				assign({ params: requestQueryParams }, AXIOS_OPTIONS)
			)
			.then((response: AxiosResponse) => response.data)
			.then((data: any) => {
				const sessionChange = data.data || {};

				forEach(keys(sessionChange), (symbol: string) => {
					const candle = sessionChange[symbol];
					const openValue = candle.prevClose
						? candle.prevClose
						: candle.open;

					sessionChange[symbol] = {
						openValue,
						change: (candle.close * 100) / openValue - 100,
						points: candle.close - openValue
					};
				});

				return sessionChange;
			})
			.catch((error: AxiosError) => {
				console.error(error);
			});
	}

	public async getCandles(
		symbol: string,
		resolution: ResolutionToInterval
	): Promise<Array<ILightweightCandle>> {
		const historyMonthsCount = getHistoryMonthsCount(resolution);
		const startDate = new Date();
		startDate.setUTCHours(0, 0, 0, 0);
		startDate.setMonth(startDate.getMonth() - historyMonthsCount);
		const endDate = new Date();
		endDate.setMilliseconds(0);
		endDate.setSeconds(0);
		endDate.setMinutes(0);
		const cPeriod = intervalToChartPeriod[resolution];

		const requestQueryParams = {
			symbol,
			timeFrom: startDate.getTime(),
			timeTo: endDate.getTime(),
			cPeriod
		};

		return await axios
			.get(
				`${API.candles}/v3/candles/tradingViewData`,
				assign({ params: requestQueryParams }, AXIOS_OPTIONS)
			)
			.then((response: AxiosResponse) => response.data.data)
			.then(({ candles }: { candles: Array<ICandle> }) => {
				return map(candles, (candle: ICandle) => {
					return {
						time: timeToLocal(candle.time),
						value: candle.close
					};
				});
			})
			.catch((error: AxiosError) => {
				console.error(error);
				return [];
			});
	}

	public static getCandleTime(
		lastCandleTime: number,
		resolution: ResolutionToInterval
	): number {
		const cPeriod = intervalToChartPeriod[resolution];
		const nextCandleTime = lastCandleTime + cPeriod;

		return timeToLocal(Date.now()) > nextCandleTime
			? nextCandleTime
			: lastCandleTime;
	}
}
