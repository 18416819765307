import { forEach } from 'lodash-es';

import { CollectionSearch } from '../modules';
import { DomService, UtilsService } from '../services';

export class TradingGlossaryPage {
	private readonly scrollOffset: number = 65;
	private stickyOnScroll: number = 490;
	private sectionElement: HTMLElement;

	constructor() {
		if (document.querySelector('body.page-trading-glossary')) {
			this.scrollOffset = UtilsService.getWindowSize() > 765 ? 65 : 35;

			this.initSearch();
			this.initStickyLetters();
			this.initLettersScroll();
		}
	}

	private initSearch(): void {
		const search = new CollectionSearch(
			'.glossary__item',
			'.glossary__group'
		);
	}

	private initStickyLetters(): void {
		const sectionElement = DomService.getElement<HTMLElement>(
			'.glossary__top-section'
		);
		const topSection =
			DomService.getElement<HTMLElement>('.section-standard');
		const mobileButtonElement = DomService.getElement<HTMLElement>(
			'.glossary__mobile-letters-button'
		);

		if (!sectionElement || !topSection || !mobileButtonElement) {
			return;
		}

		this.sectionElement = sectionElement;
		this.stickyOnScroll = topSection.getBoundingClientRect().height + 30; // 30 - margin

		window.addEventListener('scroll', () => {
			if (window.scrollY > this.stickyOnScroll) {
				sectionElement.classList.add('glossary__top-section--visible');
			} else {
				sectionElement.classList.remove(
					'glossary__top-section--visible'
				);
			}
		});

		mobileButtonElement.addEventListener('click', () => {
			sectionElement.classList.toggle(
				'glossary__top-section--mobile-open'
			);
		});
	}

	private initLettersScroll(): void {
		const letterElements =
			DomService.getElements<HTMLElement>('.glossary__letter');

		if (!letterElements) {
			return;
		}

		forEach(letterElements, (element: HTMLElement) => {
			element.addEventListener('click', (event: MouseEvent) => {
				event.preventDefault();

				const targetId = element.dataset.forid;
				const targetElement = DomService.getElement<HTMLElement>(
					`#${targetId}`
				);

				if (targetElement) {
					UtilsService.scrollIntoViewWithOffset(
						targetElement,
						this.scrollOffset
					);

					setTimeout(() => {
						this.sectionElement.classList.remove(
							'glossary__top-section--mobile-open'
						);
					}, 100); // small delay to improve UX
				}
			});
		});
	}
}

DomService.runOnDomReady(() => new TradingGlossaryPage());
