import { forEach, trim } from 'lodash-es';
import { DomService } from './dom.service';
import { UtilsService } from './utils.service';
import { CookieService } from './cookie.service';

export class AnalyticsService {
	private static instance: AnalyticsService;

	private static readonly cookieService: CookieService =
		CookieService.getInstance();

	private constructor() {}

	public static getInstance(): AnalyticsService {
		if (!AnalyticsService.instance) {
			AnalyticsService.instance = new AnalyticsService();
		}

		return AnalyticsService.instance;
	}

	public static sendEvent(
		name: string,
		params?: Record<string, unknown>
	): void {
		if (typeof gtag === 'function') {
			gtag('event', name, params);
		}
	}

	public static initAnalyticsCommonEvents(): void {
		const socialLinks = DomService.getElements<HTMLAnchorElement>(
			'.footer__platforms-links a'
		);
		if (socialLinks) {
			forEach(socialLinks, (item: HTMLAnchorElement) => {
				const label = item.ariaLabel;

				if (label) {
					item.addEventListener('click', () => {
						AnalyticsService.sendEvent('social_media_click', {
							medium: label
						});
					});
				}
			});
		}

		const navigationLinks = DomService.getElements<HTMLAnchorElement>(
			'.header__menu-link a'
		);
		if (navigationLinks) {
			forEach(navigationLinks, (item: HTMLAnchorElement) => {
				if (item.href) {
					item.addEventListener('click', () => {
						AnalyticsService.sendEvent('menu_option_click', {
							source: window.location.pathname,
							text: trim(item.innerText),
							destination: item.href
						});
					});
				}
			});
		}

		const languageLinks = DomService.getElements<HTMLAnchorElement>(
			'.header__language-item'
		);
		if (languageLinks) {
			forEach(languageLinks, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('change_language', {
						source: window.location.pathname,
						language: item.href
					});
				});
			});
		}

		const footerLinks =
			DomService.getElements<HTMLAnchorElement>('.footer__menu-item');
		if (footerLinks) {
			forEach(footerLinks, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('subpage_in_footer_click', {
						source: window.location.pathname,
						text: trim(item.innerText),
						destination: item.href
					});
				});
			});
		}

		const instrumentTableLinks = DomService.getElements<HTMLAnchorElement>(
			'.instruments-table a'
		);
		if (instrumentTableLinks) {
			forEach(instrumentTableLinks, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent(
						'instrument_table_instrument_click',
						{
							source: window.location.pathname,
							instrument: item.ariaLabel,
							destination: item.dataset.destination
						}
					);
				});
			});
		}

		const blogPostLinks = DomService.getElements<HTMLAnchorElement>(
			'.related-articles__item'
		);
		if (blogPostLinks) {
			forEach(blogPostLinks, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('blog_post_click', {
						source: window.location.pathname,
						title: item.title
					});
				});
			});
		}

		const livechatLink =
			DomService.getElement<HTMLAnchorElement>('.livechat');
		if (livechatLink) {
			livechatLink.addEventListener('click', () => {
				AnalyticsService.sendEvent('livechat_click');
			});
		}
	}

	public static initAnalyticsHomeEvents(): void {
		const instrumentTabsLinks = DomService.getElements<HTMLElement>(
			'.section-instruments__left-column .tab__item'
		);
		if (instrumentTabsLinks) {
			forEach(instrumentTabsLinks, (item: HTMLElement) => {
				const textElement = DomService.getElement<HTMLElement>(
					'span',
					item
				);
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('instrument_table_tab_click', {
						tab: trim(textElement?.innerText)
					});
				});
			});
		}

		const theyWroteLinks = DomService.getElements<HTMLAnchorElement>(
			'.section-wrote__wrapper a'
		);
		if (theyWroteLinks) {
			forEach(theyWroteLinks, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('article_click', {
						destination: item.href
					});
				});
			});
		}

		const paymentsLinks = DomService.getElements<HTMLAnchorElement>(
			'.section-operators__item'
		);
		if (paymentsLinks) {
			forEach(paymentsLinks, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('payment_method_click');
				});
			});
		}

		const banners =
			DomService.getElements<HTMLAnchorElement>('.banner__item');
		if (banners) {
			forEach(banners, (item: HTMLAnchorElement) => {
				item.addEventListener('click', () => {
					AnalyticsService.sendEvent('carousel_click', {
						title: item.ariaLabel,
						url: item.href,
						promotion: item.dataset.promotion
					});
				});
			});
		}
	}

	public static getUniId(): string | null {
		const uniIdParam = UtilsService.getUrlParam('uni_id');
		const uniIdCookie =
			this.cookieService.get(`SFXv2.uni_id`) ??
			this.cookieService.get(`amp-uni_id`);

		return uniIdParam ?? uniIdCookie;
	}

	public static getLinkToMobileAppInGooglePlay(): string {
		const uniId = this.getUniId();

		if (!uniId) {
			return APPLICATIONS_URLS.Android;
		}

		let url = APPLICATIONS_URLS.AndroidAppsFlyer;
		url += url.includes('?') ? '&' : '?';
		url += `uni_id=${uniId}`;

		return url;
	}

	public static getLinkToMobileAppInAppStore(): string {
		const uniId = this.getUniId();

		if (!uniId) {
			return APPLICATIONS_URLS.iOS;
		}

		let url = APPLICATIONS_URLS.iOSAppsFlyer;
		url += url.includes('?') ? '&' : '?';
		url += `uni_id=${uniId}`;

		return url;
	}
}
