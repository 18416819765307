enum InstrumentTradeMode {
	NoTrade,
	CloseOnly,
	Full
}

enum MarginMode {
	Forex = 0,
	CFD = 1,
	Futures = 2,
	CFDIndex = 3,
	CFDLeverage = 4
}

interface IQuote {
	s: string;
	a: number;
	b: number;
	t: number;
}

interface ITradingInterval {
	from: number;
	to: number;
}

export interface IInstrument {
	symbol: string;
	displayName: string;
	friendlyName?: string;
	tagsList: Array<string>;
	tags: string;
	securitie: string; // deprecated - use type field
	type: string;
	priceCurrency: string;
	marginCurrency: string;
	description: string;
	marginDivider: number;
	marginPercentage: number;
	contractSize: number;
	digits: number;
	profitMode: number;
	marginMode: MarginMode;
	stopsLevel: number;
	step: number;
	stepCents: number;
	maxSize: number;
	maxSizeCents: number;
	swapType: number;
	swapLong: number;
	swapShort: number;
	rollover3Days: number;
	executionMode: number;
	tradeMode: InstrumentTradeMode;
	longOnly: boolean;
	onePip: number;
	countryCode: string;
	flagName: string;
	targetSpread: number;
	isin: string;
	expiryDate: Date;
	info: string;
	sessions: any[];
	stockExchangeName: string;
	linkToExchange: string;
	ticker: string;
	tradingHours: ITradingInterval[][];

	quote: IQuote;

	openTimes: number[];
	closeTimes: number[];

	status: any;
	industryNameTranslationKey: string;
	popularityOrder?: number;
}

export type Instruments = Record<string, IInstrument>;
export type SearchInstrument = Pick<
	IInstrument,
	| 'symbol'
	| 'displayName'
	| 'friendlyName'
	| 'description'
	| 'tagsList'
	| 'tags'
	| 'type'
	| 'ticker'
	| 'industryNameTranslationKey'
	| 'stockExchangeName'
	| 'priceCurrency'
	| 'marginCurrency'
	| 'countryCode'
> & {
	category: string;
};
