import {
	debounce,
	type DebouncedFunc,
	escape,
	filter,
	flow,
	forEach,
	isArray,
	isEmpty,
	isString,
	toString
} from 'lodash-es';
import didYouMean from 'didyoumean';

import { DomService, InstrumentsService, StorageService } from '../../services';
import { type SearchInstrument } from '../../models';

const STORAGE_KEY = 'searchHistory';
const MAX_HISTORY_ITEMS = 6;

class HeaderInstrumentsSearch {
	private readonly storageService: StorageService =
		StorageService.getInstance();

	private elements: {
		triggerButton: HTMLButtonElement;
		searchWrapperDesktop: HTMLElement;
		searchWrapperMobile: HTMLElement;
		searchListWrapperMobile: HTMLElement;
		contentForInactiveSearch: HTMLElement;
		contentForActiveSearch: HTMLElement;
		searchInputDesktop: HTMLInputElement;
		searchInputMobile: HTMLInputElement;
		searchCategories: Array<HTMLDivElement>;
		searchSymbols: Array<HTMLAnchorElement>;
		noData: HTMLDivElement;
		menuButtonsMobile: HTMLDivElement;
		menuListMobile: Array<HTMLDivElement>;
		menuTriggerMobile: HTMLElement;
		backButtonMobile: HTMLElement;
		searchHistoryWrapper: HTMLDivElement;
		searchHistoryChipsWrapper: HTMLDivElement;
		searchDidYouMean: HTMLDivElement;
		searchDidYouMeanSymbol: Array<HTMLSpanElement>;
	};

	private query?: string;
	private dymQuery?: string | Array<string>;
	private isDomChangedForMobile: boolean = false;
	private searchHistory: Array<string> = [];
	private updateSearchHistoryDebouncedFn?: DebouncedFunc<() => void>;

	constructor() {
		this.initElements();
		this.readSearchHistory();
	}

	private initElements(): void {
		const triggerButton = DomService.getElement<HTMLButtonElement>(
			'#headerInstrumentsSearchBtn'
		);
		const searchWrapperDesktop = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search--desktop'
		);
		const searchWrapperMobile = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search--mobile'
		);
		const searchListWrapperMobile = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__mobile-list',
			searchWrapperMobile
		);
		const headerSearchWrapper = DomService.getElement<HTMLDivElement>(
			'.header__instruments-search'
		);
		const searchInputDesktop = DomService.getElement<HTMLInputElement>(
			'.header-instruments-search--desktop input'
		);
		const searchInputMobile = DomService.getElement<HTMLInputElement>(
			'.header-instruments-search--mobile input'
		);
		const contentForInactiveSearch = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__inactive-search'
		);
		const contentForActiveSearch = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__active-search'
		);
		const searchCategories = DomService.getElements<HTMLDivElement>(
			'.header-instruments-search__active-search .header-instruments-search__section-title'
		);
		const searchSymbols = DomService.getElements<HTMLAnchorElement>(
			'.header-instruments-search__active-search .header-instruments-search__instrument'
		);
		const searchHistoryChipsWrapper = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__history .header-instruments-search__section-content'
		);
		const searchHistoryWrapper = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__history'
		);
		const noData = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__no-data'
		);
		const menuButtonsMobile = DomService.getElement<HTMLDivElement>(
			'.header__menu-mobile .header__menu-mobile-buttons'
		);
		const menuListMobile = DomService.getElements<HTMLDivElement>(
			'.header__menu-mobile .collapsible'
		);
		const menuTriggerMobile = DomService.getElement<HTMLElement>(
			'#mobile-menu-trigger'
		);
		const backButtonMobile = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__back-btn'
		);

		const searchDidYouMean = DomService.getElement<HTMLDivElement>(
			'.header-instruments-search__did-you-mean'
		);
		const searchDidYouMeanSymbol = DomService.getElements<HTMLSpanElement>(
			'.did-you-mean__symbol'
		);

		if (
			!triggerButton ||
			!searchWrapperDesktop ||
			!searchListWrapperMobile ||
			!searchWrapperMobile ||
			!headerSearchWrapper ||
			!searchInputDesktop ||
			!searchInputMobile ||
			!contentForActiveSearch ||
			!contentForInactiveSearch ||
			!searchCategories ||
			!searchSymbols ||
			!noData ||
			!menuButtonsMobile ||
			!menuListMobile ||
			!menuTriggerMobile ||
			!backButtonMobile ||
			!searchHistoryWrapper ||
			!searchHistoryChipsWrapper ||
			!searchDidYouMean ||
			!searchDidYouMeanSymbol
		) {
			throw new Error('Missing DOM elements');
		}

		this.elements = {
			triggerButton,
			searchWrapperDesktop,
			searchWrapperMobile,
			searchListWrapperMobile,
			contentForInactiveSearch,
			contentForActiveSearch,
			searchInputDesktop,
			searchInputMobile,
			searchCategories,
			searchSymbols,
			noData,
			menuButtonsMobile,
			menuListMobile,
			menuTriggerMobile,
			backButtonMobile,
			searchHistoryWrapper,
			searchHistoryChipsWrapper,
			searchDidYouMean,
			searchDidYouMeanSymbol
		};

		triggerButton.addEventListener('click', () => {
			searchWrapperDesktop.classList.toggle('active');

			if (!searchWrapperDesktop.classList.contains('active')) {
				this.onClose();
			} else {
				this.elements.searchInputDesktop.focus();

				if (!InstrumentsService.areInstrumentsFetched) {
					void this.initData();
				}
			}
		});

		document.addEventListener('click', (event: MouseEvent) => {
			if (!headerSearchWrapper.contains(event.target as HTMLElement)) {
				searchWrapperDesktop.classList.remove('active');
				this.onClose();

				this.updateSearchHistoryDebouncedFn?.cancel();
			}
		});

		searchInputDesktop.addEventListener('keyup', (event: KeyboardEvent) => {
			this.query = (event.target as HTMLInputElement)?.value
				?.toLowerCase()
				?.trim();

			this.searchInstruments();
			this.elements.contentForActiveSearch.scrollTo(0, 0);

			this.updateSearchHistoryDebouncedFn?.cancel();
			this.updateSearchHistoryDebouncedFn = debounce(
				this.updateSearchHistory,
				1000
			);
			this.updateSearchHistoryDebouncedFn();
		});

		searchInputMobile.addEventListener('click', () => {
			if (!InstrumentsService.areInstrumentsFetched) {
				void this.initData();
			}

			this.onSearchStartOnMobile();

			if (this.isDomChangedForMobile) {
				return;
			}

			this.changeDomForMobile();
		});

		searchInputMobile.addEventListener('keyup', (event: KeyboardEvent) => {
			if (!InstrumentsService.areInstrumentsFetched) {
				void this.initData();
			}

			if (!this.isDomChangedForMobile) {
				this.changeDomForMobile();
				return;
			}
			this.onSearchStartOnMobile();

			this.query = (event.target as HTMLInputElement)?.value
				?.toLowerCase()
				?.trim();

			this.searchInstruments();

			this.updateSearchHistoryDebouncedFn?.cancel();
			this.updateSearchHistoryDebouncedFn = debounce(
				this.updateSearchHistory,
				1000
			);
			this.updateSearchHistoryDebouncedFn();
		});

		menuTriggerMobile.addEventListener('click', () => {
			setTimeout(() => {
				if (!menuTriggerMobile.classList.contains('active')) {
					this.onSearchEndOnMobile();
				}
			}, 300);
		});

		backButtonMobile.addEventListener('click', () => {
			this.onSearchEndOnMobile();
		});

		searchDidYouMeanSymbol[0].addEventListener('click', () => {
			this.searchDidYouMeanInstruments(searchInputDesktop);
		});
	}

	private changeDomForMobile(): void {
		this.elements.searchListWrapperMobile.appendChild(
			this.elements.contentForInactiveSearch
		);
		this.elements.searchListWrapperMobile.appendChild(
			this.elements.contentForActiveSearch
		);

		this.isDomChangedForMobile = true;
	}

	private onSearchStartOnMobile(): void {
		this.elements.menuButtonsMobile.style.opacity = '0';
		this.elements.contentForInactiveSearch.hidden = false;
		this.elements.backButtonMobile.hidden = false;
		this.elements.searchListWrapperMobile.hidden = false;
		this.elements.menuButtonsMobile.style.pointerEvents = 'none';

		forEach(this.elements.menuListMobile, (element: HTMLDivElement) => {
			element.hidden = true;
		});

		setTimeout(() => {
			this.elements.searchWrapperMobile.style.height = `${this.elements.searchWrapperMobile.clientHeight}px`;
			const contentHeight =
				this.elements.searchWrapperMobile.clientHeight -
				(this.elements.searchInputMobile.parentElement?.clientHeight ??
					0);
			this.elements.contentForInactiveSearch.style.height = `${contentHeight}px`;
			this.elements.contentForActiveSearch.style.height = `${contentHeight}px`;
		});
	}

	private onSearchEndOnMobile(): void {
		this.elements.menuButtonsMobile.style.opacity = '1';
		this.elements.menuButtonsMobile.style.pointerEvents = 'unset';
		this.elements.contentForActiveSearch.hidden = true;
		this.elements.contentForActiveSearch.style.height = '';
		this.elements.contentForInactiveSearch.hidden = true;
		this.elements.contentForInactiveSearch.style.height = '';
		this.elements.backButtonMobile.hidden = true;
		this.elements.searchListWrapperMobile.hidden = true;
		this.elements.searchInputMobile.value = '';
		this.elements.searchWrapperMobile.style.height = '';

		forEach(this.elements.menuListMobile, (element: HTMLDivElement) => {
			element.hidden = false;
		});

		this.updateSearchHistoryDebouncedFn?.cancel();
	}

	private async initData(): Promise<void> {
		await InstrumentsService.prepareData();
		this.searchInstruments();
	}

	private onClose(clearInput: boolean = true): void {
		this.elements.contentForActiveSearch.hidden = true;
		this.elements.contentForInactiveSearch.hidden = false;
		this.query = undefined;

		if (clearInput) {
			this.elements.searchInputDesktop.value = '';
		}

		forEach(
			[...this.elements.searchCategories, ...this.elements.searchSymbols],
			(element: HTMLElement) => {
				element.hidden = false;
			}
		);
	}

	private getSearchedInstruments(
		query: string,
		symbolsToShow: Array<string>,
		categoriesToShow: Array<string>
	): void {
		InstrumentsService.searchInstruments(
			query.toLowerCase(),
			(instrument: SearchInstrument, showInstrument: boolean) => {
				if (showInstrument) {
					if (!symbolsToShow.includes(instrument.symbol)) {
						symbolsToShow.push(instrument.symbol);
					}
					if (!categoriesToShow.includes(instrument.category)) {
						categoriesToShow.push(instrument.category);
					}
				}
			}
		);
	}

	private searchInstruments(): void {
		if (!this.query || this.query.length < 2) {
			this.onClose(false);
		} else if (InstrumentsService.areInstrumentsFetched) {
			this.elements.contentForActiveSearch.hidden = false;
			this.elements.contentForInactiveSearch.hidden = true;
			this.elements.searchDidYouMean.hidden = true;

			const categoriesToShow: Array<string> = [];
			const symbolsToShow: Array<string> = [];

			this.getSearchedInstruments(
				this.query,
				symbolsToShow,
				categoriesToShow
			);

			if (isEmpty(symbolsToShow)) {
				this.dymQuery = didYouMean(
					this.query,
					InstrumentsService.getInstrumentsDidYouMeanWords()
				);
				const query: string =
					typeof this.dymQuery === 'string'
						? this.dymQuery.toUpperCase()
						: this.dymQuery?.join(' ').toUpperCase();

				if (this.dymQuery) {
					this.elements.searchDidYouMean.hidden = false;
					forEach(
						this.elements.searchDidYouMeanSymbol,
						(element: HTMLSpanElement) => {
							element.innerHTML = query;
						}
					);
					this.getSearchedInstruments(
						query,
						symbolsToShow,
						categoriesToShow
					);
				}
			}

			forEach(
				this.elements.searchCategories,
				(element: HTMLDivElement) => {
					const category = element.dataset.category;
					element.hidden = category
						? !categoriesToShow.includes(category)
						: true;
				}
			);

			forEach(
				this.elements.searchSymbols,
				(element: HTMLAnchorElement) => {
					const symbol = element.dataset.symbol;
					element.hidden = symbol
						? !symbolsToShow.includes(symbol)
						: true;
				}
			);

			const showNoData = isEmpty(symbolsToShow);
			this.elements.noData.hidden = !showNoData;

			showNoData
				? this.elements.contentForActiveSearch.classList.add('no-data')
				: this.elements.contentForActiveSearch.classList.remove(
						'no-data'
				  );
		}
	}

	private searchDidYouMeanInstruments(
		searchInputDesktop: HTMLInputElement
	): void {
		if (this.dymQuery) {
			this.query = this.dymQuery?.toString().toLowerCase();
			this.searchInstruments();
			searchInputDesktop.value = this.query;
		}
	}

	private readSearchHistory(): void {
		try {
			const searchHistory = flow(
				toString,
				escape
			)(this.storageService.get(STORAGE_KEY));

			if (!searchHistory || !isString(searchHistory)) {
				return;
			}

			this.searchHistory = searchHistory
				.split(',')
				.slice(0, MAX_HISTORY_ITEMS);

			if (isEmpty(this.searchHistory) || !isArray(this.searchHistory)) {
				return;
			}

			forEach(this.searchHistory, (item: string) => {
				this.addSearchHistoryChips(item);
			});

			this.elements.searchHistoryWrapper.hidden = false;
		} catch (error) {
			console.error(error);
		}
	}

	private updateSearchHistory(): void {
		if (!this.query || !this.elements.noData.hidden) {
			// Skip empty query or when search return no results
			return;
		}

		if (!this.searchHistory.includes(this.query)) {
			this.addSearchHistoryChips(this.query);
			this.searchHistory.push(this.query);
			this.saveSearchHistory();

			this.elements.searchHistoryWrapper.hidden = false;

			if (this.searchHistory.length > MAX_HISTORY_ITEMS) {
				this.searchHistory.shift();
				this.elements.searchHistoryChipsWrapper.children[0].remove();
			}
		}
	}

	private saveSearchHistory(): void {
		this.storageService.set(STORAGE_KEY, this.searchHistory.join(','));
	}

	private addSearchHistoryChips(chipsText: string): void {
		const chipsElement = document.createElement('div');
		const textWrapperElement = document.createElement('span');
		const closeBtnElement = document.createElement('button');
		closeBtnElement.classList.add('chips-close-btn');
		chipsElement.classList.add('chips');
		textWrapperElement.innerText = chipsText.toUpperCase();

		chipsElement.addEventListener('click', () => {
			this.query = chipsText.toLowerCase().trim();
			this.elements.searchInputMobile.value = chipsText.toUpperCase();
			this.elements.searchInputDesktop.value = chipsText.toUpperCase();

			this.searchInstruments();
			this.elements.contentForActiveSearch.scrollTo(0, 0);
		});
		closeBtnElement.addEventListener(
			'click',
			(event: MouseEvent) => {
				event.stopPropagation();

				closeBtnElement.remove();
				chipsElement.remove();

				this.searchHistory = filter(
					this.searchHistory,
					(item: string) => item !== chipsText
				);
				this.saveSearchHistory();

				if (isEmpty(this.searchHistory)) {
					this.elements.searchHistoryWrapper.hidden = true;
				}
			},
			{ once: true }
		);

		chipsElement.appendChild(textWrapperElement);
		chipsElement.appendChild(closeBtnElement);
		this.elements.searchHistoryChipsWrapper.appendChild(chipsElement);
	}
}

DomService.runOnDomReady(() => new HeaderInstrumentsSearch());
