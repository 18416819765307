import { forEach } from 'lodash-es';

import { DomService } from '../services';

export class OfferPage {
	constructor() {
		if (document.querySelector('body.page-offer')) {
			this.initOfferMobileElements();
		}
	}

	private initOfferMobileElements(): void {
		if (window.innerWidth > 1024) {
			// desktop-up
			return;
		}

		const offerElements =
			DomService.getElements<HTMLElement>(`.offer__item`);

		if (!offerElements) {
			return;
		}

		offerElements[0].classList.add('active');

		forEach(offerElements, (offerElement: HTMLElement) => {
			offerElement.addEventListener('click', (event: MouseEvent) => {
				if (!(event.target as HTMLElement)?.classList.contains('btn')) {
					event.preventDefault();
					offerElement.classList.toggle('active');
				}
			});
		});
	}
}

DomService.runOnDomReady(() => new OfferPage());
