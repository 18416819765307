import { DomService } from '../services';
import { TestimonialsCarousel } from '../modules';

export class AffiliatePage {
	constructor() {
		if (document.querySelector('body.page-affiliate')) {
			this.init();
		}
	}

	private init(): void {
		const testimonialsCarousel = new TestimonialsCarousel();
	}
}

DomService.runOnDomReady(() => new AffiliatePage());
