export enum WebQuotesEvents {
	LastPrices = '/lastprices/list',
	Quotes = '/quotes/subscribed'
}

export enum WebQuotesMethods {
	LastPrices = '/lastprices/list',
	Subscribe = '/subscribe/addlist',
	Unsubscribe = '/subscribe/removelist'
}
