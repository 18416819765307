export function emailValidator(value: string): boolean {
	const EMAIL_REGEXP =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return EMAIL_REGEXP.test(value);
}

export const isAppleDevice = (): boolean => {
	return (
		/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) &&
		!(window as any).MSStream
	);
};
