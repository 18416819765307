import { CollectionSearch } from '../modules';
import { DomService } from '../services';

export class FaqPage {
	constructor() {
		if (document.querySelector('body.page-faq')) {
			this.init();
		}
	}

	private init(): void {
		const search = new CollectionSearch('.faq__item-group', '.collapsible');
	}
}

DomService.runOnDomReady(() => new FaqPage());
