enum CaptchaType {
	Geetest = 'Geetest',
	ReCaptcha = 'ReCaptcha'
}

enum VerificationMethod {
	Generic = 'Generic',
	Sumsub = 'Sumsub'
}

export interface ICountry {
	countryId: number;
	countryName: string;
	enabled: boolean;
	countryCode: string;
	cryptoDeposit: boolean; // AnonymousCryptoDeposit from BO - obsolete
	borderDate: number;
	displayGeoblockingAlert: boolean;
	displayCustomAlertHome: string;
	euMember: boolean;
	signup?: boolean;
	captchaType?: CaptchaType;
	verificationMethod: VerificationMethod;
	isGenericVerification: boolean;
}
