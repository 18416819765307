import { FullWidthCarousel } from '../modules';
import { DomService, TableOfContentService } from '../services';
import { Lightbox } from '../modules/lightbox';

export class SingleTutorialPage {
	constructor() {
		if (document.querySelector('body.page-single-tutorial')) {
			this.initBlogCarousel();
			this.initTableOfContent();
			this.initLightbox();
		}
	}

	private initBlogCarousel(): void {
		const blogWrapperElement = DomService.getElement<HTMLElement>(
			'.single-article__related-articles'
		);

		if (!blogWrapperElement) {
			return;
		}

		const sliderContainer = DomService.getElement<HTMLElement>(
			'.related-articles__slider-wrapper',
			blogWrapperElement
		);

		if (sliderContainer) {
			const carousel = new FullWidthCarousel(
				blogWrapperElement,
				sliderContainer,
				'.related-articles__item'
			);
		}
	}

	private initTableOfContent(): void {
		const tableOfContentService = new TableOfContentService();
	}

	private initLightbox(): void {
		const lightbox = new Lightbox('.wp-block-image img');
	}
}

DomService.runOnDomReady(() => new SingleTutorialPage());
