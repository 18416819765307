import { type AxiosRequestConfig } from 'axios';

export const AXIOS_OPTIONS: AxiosRequestConfig = {
	withCredentials: true
};

export const URL_PARAMS = new URLSearchParams(window.location.search);

export const HEADER_HEIGHT: number = 64;

export const USERSNAP_COUNTRIES = ['AU', 'IE', 'NZ', 'GB', 'PH', 'MT', 'ZA'];
