import Hammer from 'hammerjs';
import { forEach, times } from 'lodash-es';

import { UtilsService } from '../../services';

export class Slider {
	private readonly leftArrow: HTMLElement;
	private readonly rightArrow: HTMLElement;
	private readonly carouselWrapper: HTMLElement;
	private readonly sectionWrapper: HTMLElement;
	private readonly elements: Array<HTMLElement>;
	private readonly dots: Array<HTMLElement>;
	private readonly isLeftToRightDirection: boolean =
		UtilsService.isLeftToRightDirection();

	private activeIndex: number = 0;
	private carouselIntervalId?: number;

	constructor({
		leftArrow,
		rightArrow,
		carouselWrapper,
		sectionWrapper,
		elements,
		dots
	}: {
		leftArrow: HTMLElement;
		rightArrow: HTMLElement;
		carouselWrapper: HTMLElement;
		sectionWrapper: HTMLElement;
		elements: Array<HTMLElement>;
		dots: Array<HTMLElement>;
		disabledArrowClassName?: string;
	}) {
		this.leftArrow = leftArrow;
		this.rightArrow = rightArrow;
		this.dots = dots;
		this.carouselWrapper = carouselWrapper;
		this.sectionWrapper = sectionWrapper;
		this.elements = elements;

		this.init();
	}

	private readonly onNext = (): void => {
		const nextActiveIndex =
			this.activeIndex + 1 < this.elements.length
				? this.activeIndex + 1
				: 0;

		this.animateSlider(nextActiveIndex);
	};

	private readonly onPrevious = (): void => {
		const nextActiveIndex =
			this.activeIndex - 1 >= 0
				? this.activeIndex - 1
				: this.elements.length - 1;

		this.animateSlider(nextActiveIndex);
	};

	private init(): void {
		this.rightArrow.addEventListener('click', this.onNext);
		this.leftArrow.addEventListener('click', this.onPrevious);

		this.startInterval();

		forEach(this.dots, (element: HTMLElement, index) => {
			element.addEventListener('click', () => this.animateSlider(index));
		});

		document.addEventListener('visibilitychange', () => {
			document.visibilityState === 'hidden'
				? this.stopInterval()
				: this.startInterval();
		});

		this.sectionWrapper.addEventListener(
			UtilsService.isTouchDevice() ? 'touchstart' : 'mouseenter',
			() => {
				if (!this.carouselIntervalId) {
					return;
				}

				this.stopInterval();
			}
		);

		if (!UtilsService.isTouchDevice()) {
			this.sectionWrapper.addEventListener('mouseleave', () => {
				if (this.carouselIntervalId) {
					return;
				}

				this.startInterval();
			});
		}

		this.initHammerManager();
	}

	private initHammerManager(): void {
		const points = times(
			this.elements.length,
			(index: number) =>
				index *
				this.carouselWrapper.clientWidth *
				(this.isLeftToRightDirection ? -1 : 1)
		);

		const hammerManager = new Hammer(this.carouselWrapper, {
			touchAction: 'pan-y'
		});

		const getCurrentTranslateXPosition = (
			direction: number,
			deltaX: number
		): number => {
			const directionMultiplier =
				direction === Hammer.DIRECTION_LEFT ? -1 : 1;
			const translateXFrom =
				this.activeIndex *
				(this.isLeftToRightDirection ? -1 : 1) *
				this.carouselWrapper.clientWidth;

			return translateXFrom + (directionMultiplier + deltaX);
		};

		const onPanMove = ({
			direction,
			deltaX,
			srcEvent
		}: HammerInput): void => {
			if (
				srcEvent instanceof PointerEvent &&
				srcEvent?.pointerType === 'mouse'
			) {
				return;
			}

			this.stopInterval();

			this.carouselWrapper.style.transform = `translateX(${getCurrentTranslateXPosition(
				direction,
				deltaX
			)}px)`;
		};

		const onPanEnd = ({
			direction,
			deltaX,
			velocityX,
			srcEvent
		}: HammerInput): void => {
			if (
				srcEvent instanceof PointerEvent &&
				srcEvent?.pointerType === 'mouse'
			) {
				return;
			}

			const translateXFrom = getCurrentTranslateXPosition(
				direction,
				deltaX
			);
			let translateXTo =
				translateXFrom +
				velocityX * this.carouselWrapper.clientWidth * 0.3;

			const closestIndex = UtilsService.findClosestIndex(
				translateXTo,
				points
			);
			translateXTo =
				closestIndex * (this.isLeftToRightDirection ? -1 : 1) * 100;

			this.updateDotsState(closestIndex);

			const animation = this.carouselWrapper.animate(
				[
					{ transform: `translateX(${translateXFrom}px` },
					{ transform: `translateX(${translateXTo}%` }
				],
				{
					duration: 300,
					easing: 'ease-in-out'
				}
			);

			animation.onfinish = () => {
				this.activeIndex = closestIndex;
				this.carouselWrapper.style.transform = `translateX(${translateXTo}%)`;
			};
		};

		hammerManager.on('panmove', onPanMove);
		hammerManager.on('panend', onPanEnd);
		hammerManager.on('pancancel', onPanEnd);
	}

	private startInterval(): void {
		this.stopInterval();

		this.carouselIntervalId = window.setInterval(() => {
			const nextIndex =
				this.activeIndex + 1 === this.elements.length
					? 0
					: this.activeIndex + 1;

			this.animateSlider(nextIndex);
		}, 4000);
	}

	private stopInterval(): void {
		if (!this.carouselIntervalId) {
			return;
		}

		clearInterval(this.carouselIntervalId);
		this.carouselIntervalId = undefined;
	}

	private animateSlider(nextIndex: number): void {
		if (nextIndex === this.activeIndex) {
			return;
		}

		const translateXFrom =
			this.activeIndex * (this.isLeftToRightDirection ? -1 : 1) * 100;
		const translateXTo =
			nextIndex * (this.isLeftToRightDirection ? -1 : 1) * 100;

		this.updateDotsState(nextIndex);

		const animation = this.carouselWrapper.animate(
			[
				{ transform: `translateX(${translateXFrom}%)` },
				{ transform: `translateX(${translateXTo}%)` }
			],
			{
				duration: 300,
				easing: 'ease-in'
			}
		);
		animation.onfinish = () => {
			this.activeIndex = nextIndex;
			this.carouselWrapper.style.transform = `translateX(${translateXTo}%)`;
		};
	}

	private updateDotsState(activeIndex: number): void {
		forEach(this.dots, (element: HTMLElement, index: number) => {
			if (activeIndex === index) {
				element.classList.add('active');
			} else {
				element.classList.remove('active');
			}
		});
	}
}
