import {
	filter,
	flow,
	forEach,
	isBoolean,
	isEmpty,
	isString,
	some,
	without,
	toNumber,
	escape,
	map
} from 'lodash-es';

import {
	CustomSelect,
	CustomSelectType,
	PaymentsFilters,
	Tabs
} from '../modules';
import { CountriesService, DomService, UtilsService } from '../services';
import { type IPaymentGroup, type IPaymentGroupOperator } from '../models';
import { URL_PARAMS } from '../config';
import { DeviceEnum } from '../enums';

interface IFilters {
	query?: string;
	country?: string;
	timeToFund?: Array<string>;
	jurisdictions?: Array<string>;
	earn?: boolean;
}

interface ITableElements {
	clearAllFilter: HTMLElement;
	chipsWrapper: HTMLElement;
	countrySelect: HTMLElement;
	earnSelect: HTMLElement;
	jurisdictionSelect: HTMLElement;
	filtersWrapper: HTMLElement;
	noData: HTMLElement;
	methodsNumber: HTMLElement;
	tableWrapper: HTMLElement;
	filterBtnElement: HTMLElement;
	countriesSearch: HTMLInputElement;
	countriesSearchNoResult: HTMLElement;
}

interface ITableSelects {
	country: CustomSelect;
	earn: CustomSelect;
	jurisdictions: CustomSelect;
}

enum SortBy {
	PopularDesc = 'PopularDesc',
	PopularAsc = 'PopularAsc',
	DateDesc = 'DateDesc',
	DateAsc = 'DateAsc',
	NameAsc = 'NameAsc',
	NameDesc = 'NameDesc'
}

type TableType = 'deposit' | 'withdrawal';
type QueryParamsTab = 'tab' | 'country' | 'earn' | 'jurisdiction';

export class PaymentsPage {
	private readonly depositFilters: IFilters = {
		country: undefined,
		timeToFund: undefined,
		earn: undefined,
		jurisdictions: undefined
	};

	private readonly withdrawalFilters: IFilters = {
		country: undefined,
		timeToFund: undefined,
		earn: undefined,
		jurisdictions: undefined
	};

	private depositSortBy: SortBy = SortBy.PopularDesc;
	private withdrawalSortBy: SortBy = SortBy.PopularDesc;
	private depositSelects: ITableSelects;
	private withdrawalSelects: ITableSelects;
	private depositCollection: PaymentsFilters;
	private withdrawalCollection: PaymentsFilters;
	private data: Array<IPaymentGroup>;
	private stakingData: Record<string, number>;
	private jurisdictions: Array<string>;
	private countriesData: Array<string>;
	private depositGroupsElements?: Array<HTMLDivElement>;
	private withdrawalGroupsElements?: Array<HTMLDivElement>;
	private depositTableElements: ITableElements;
	private withdrawalTableElements: ITableElements;

	constructor() {
		if (document.querySelector('body.page-payments')) {
			this.initDepositElements();
			this.initWithdrawalElements();
			this.initTabs();
			this.initAccordion();
			this.initTables();
			void this.initData();
			this.checkNewChips();
			this.addListenerToEarnChips();
		}
	}

	private initDepositElements(): void {
		const clearAllFilter = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__chips-filters--clear-all'
		);
		const chipsWrapper = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__chips-filters'
		);
		const countrySelect = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__filters--country-select'
		);
		const jurisdictionSelect = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__filters--jurisdictions-select'
		);
		const earnSelect = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__filters--earn-select'
		);
		const filtersWrapper = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__filters'
		);
		const noData = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__no-data'
		);
		const methodsNumber = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__methods-number'
		);
		const tableWrapper = DomService.getElement<HTMLButtonElement>(
			'.payments-table--deposits'
		);
		const filterBtnElement = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__filter-btn'
		);
		const countriesSearch = DomService.getElement<HTMLInputElement>(
			'#depositCountriesSearch'
		);
		const countriesSearchNoResult = DomService.getElement<HTMLElement>(
			'.payments-table--deposits .payments-table__filters--country-select .custom-select__no-result'
		);

		if (
			!clearAllFilter ||
			!chipsWrapper ||
			!countrySelect ||
			!jurisdictionSelect ||
			!earnSelect ||
			!filtersWrapper ||
			!noData ||
			!methodsNumber ||
			!tableWrapper ||
			!filterBtnElement ||
			!countriesSearch ||
			!countriesSearchNoResult
		) {
			throw new Error('Missing DOM elements');
		}

		this.depositTableElements = {
			clearAllFilter,
			chipsWrapper,
			countrySelect,
			jurisdictionSelect,
			earnSelect,
			filtersWrapper,
			noData,
			methodsNumber,
			tableWrapper,
			filterBtnElement,
			countriesSearch,
			countriesSearchNoResult
		};
	}

	private initWithdrawalElements(): void {
		const clearAllFilter = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__chips-filters--clear-all'
		);
		const chipsWrapper = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__chips-filters'
		);
		const countrySelect = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__filters--country-select'
		);
		const jurisdictionSelect = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__filters--jurisdictions-select'
		);
		const earnSelect = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__filters--earn-select'
		);
		const filtersWrapper = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__filters'
		);
		const noData = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__no-data'
		);
		const methodsNumber = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__methods-number'
		);
		const tableWrapper = DomService.getElement<HTMLButtonElement>(
			'.payments-table--withdrawals'
		);
		const filterBtnElement = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__filter-btn'
		);
		const countriesSearch = DomService.getElement<HTMLInputElement>(
			'#withdrawalCountriesSearch'
		);
		const countriesSearchNoResult = DomService.getElement<HTMLElement>(
			'.payments-table--withdrawals .payments-table__filters--country-select .custom-select__no-result'
		);

		if (
			!clearAllFilter ||
			!chipsWrapper ||
			!countrySelect ||
			!jurisdictionSelect ||
			!earnSelect ||
			!filtersWrapper ||
			!noData ||
			!methodsNumber ||
			!tableWrapper ||
			!filterBtnElement ||
			!countriesSearch ||
			!countriesSearchNoResult
		) {
			throw new Error('Missing DOM elements');
		}

		this.withdrawalTableElements = {
			clearAllFilter,
			chipsWrapper,
			countrySelect,
			jurisdictionSelect,
			earnSelect,
			filtersWrapper,
			noData,
			methodsNumber,
			tableWrapper,
			filterBtnElement,
			countriesSearch,
			countriesSearchNoResult
		};
	}

	private async initData(): Promise<void> {
		const data = DomService.getElement<HTMLScriptElement>(
			'script#paymentsData'
		)?.textContent;
		const stakingData =
			DomService.getElement<HTMLScriptElement>('script#stakingData')
				?.textContent;
		const countriesData = DomService.getElement<HTMLScriptElement>(
			'script#countriesData'
		)?.textContent;
		const jurisdictionsData = DomService.getElement<HTMLScriptElement>(
			'script#jurisdictionsData'
		)?.textContent;

		if (
			!isString(data) ||
			!isString(countriesData) ||
			!isString(stakingData) ||
			!isString(jurisdictionsData)
		) {
			return;
		}

		let countryToSet: string | undefined;
		let earnToSet: boolean | undefined;
		let jurisdictionsToSet: Array<string> | undefined;

		try {
			this.data = JSON.parse(data);
			this.countriesData = JSON.parse(countriesData);
			this.stakingData = JSON.parse(stakingData);
			this.jurisdictions = JSON.parse(jurisdictionsData);

			const countryParam = escape(
				URL_PARAMS.get('country') ?? undefined
			)?.toUpperCase();
			const jurisdictionsParam = escape(
				URL_PARAMS.get('jurisdiction') ?? undefined
			)?.toUpperCase();
			const earnParam = escape(
				URL_PARAMS.get('earn') ?? undefined
			)?.toLowerCase();

			if (
				countryParam &&
				!isEmpty(countryParam) &&
				this.countriesData.includes(countryParam)
			) {
				countryToSet = countryParam;
			} else {
				try {
					const country = await CountriesService.getCountryByIp();
					countryToSet = this.countriesData.includes(
						country?.countryCode
					)
						? country?.countryCode
						: undefined;
				} catch (error) {
					console.error(error);
				}
			}

			if (jurisdictionsParam) {
				const jurisdictions = jurisdictionsParam.split(',');
				jurisdictionsToSet = filter(
					jurisdictions,
					(jurisdiction: string) =>
						this.jurisdictions.includes(jurisdiction)
				);

				if (jurisdictionsToSet.length === this.jurisdictions.length) {
					// Don't select all jurisdictions. Set jurisdictionsToSet to undefined
					jurisdictionsToSet = undefined;
				}
			}

			if (earnParam) {
				earnToSet =
					earnParam === 'yes'
						? true
						: earnParam === 'no'
						? false
						: undefined;
			}
		} catch (_) {
			console.error("Can't parse data");
		}

		if (countryToSet) {
			this.depositFilters.country = countryToSet;
			this.withdrawalFilters.country = countryToSet;

			const label =
				DomService.getElement<HTMLLabelElement>(
					`label[data-value='${countryToSet}']`,
					this.depositTableElements.countrySelect
				)?.innerText?.trim() ?? countryToSet;

			this.addFiltersChips({
				chipsLabel: label,
				chipId: `depositCountry`,
				chipsWrapper: this.depositTableElements.chipsWrapper,
				clearAllFilterElement: this.depositTableElements.clearAllFilter,
				callback: () => {
					this.depositSelects.country?.reset();
					this.depositFilters.country = undefined;
					this.updateDepositsTable();
					this.deleteQueryParams('country');
				}
			});
			this.addFiltersChips({
				chipsLabel: label,
				chipId: `withdrawalCountry`,
				chipsWrapper: this.withdrawalTableElements.chipsWrapper,
				clearAllFilterElement:
					this.withdrawalTableElements.clearAllFilter,
				callback: () => {
					this.withdrawalSelects.country?.reset();
					this.withdrawalFilters.country = undefined;
					this.updateWithdrawalTable();
					this.deleteQueryParams('country');
				}
			});

			this.depositSelects.country.updateItems([countryToSet]);
			this.withdrawalSelects.country.updateItems([countryToSet]);
		}

		if (jurisdictionsToSet) {
			this.depositFilters.jurisdictions = jurisdictionsToSet;
			this.withdrawalFilters.jurisdictions = jurisdictionsToSet;

			forEach(jurisdictionsToSet, (jurisdiction: string) => {
				this.addFiltersChips({
					chipsLabel: jurisdiction,
					chipsWrapper: this.depositTableElements.chipsWrapper,
					clearAllFilterElement:
						this.depositTableElements.clearAllFilter,
					chipId: `depositJurisdiction${jurisdiction}`,
					callback: () => {
						this.depositFilters.jurisdictions = without(
							this.depositFilters.jurisdictions,
							jurisdiction
						);
						this.depositSelects.jurisdictions.updateItems(
							this.depositFilters.jurisdictions
						);
						this.updateDepositsTable();

						if (isEmpty(this.depositFilters.jurisdictions)) {
							this.deleteQueryParams('jurisdiction');
						} else {
							this.updateQueryParams(
								'jurisdiction',
								this.depositFilters.jurisdictions.join(',')
							);
						}
					}
				});

				this.addFiltersChips({
					chipsLabel: jurisdiction,
					chipsWrapper: this.withdrawalTableElements.chipsWrapper,
					clearAllFilterElement:
						this.withdrawalTableElements.clearAllFilter,
					chipId: `withdrawalJurisdiction${jurisdiction}`,
					callback: () => {
						this.withdrawalFilters.jurisdictions = without(
							this.withdrawalFilters.jurisdictions,
							jurisdiction
						);
						this.withdrawalSelects.jurisdictions.updateItems(
							this.withdrawalFilters.jurisdictions
						);
						this.updateWithdrawalTable();

						if (isEmpty(this.withdrawalFilters.jurisdictions)) {
							this.deleteQueryParams('jurisdiction');
						} else {
							this.updateQueryParams(
								'jurisdiction',
								this.withdrawalFilters.jurisdictions.join(',')
							);
						}
					}
				});
			});

			this.depositSelects.jurisdictions.updateItems(jurisdictionsToSet);
			this.withdrawalSelects.jurisdictions.updateItems(
				jurisdictionsToSet
			);
		}

		if (isBoolean(earnToSet)) {
			this.depositFilters.earn = earnToSet;
			this.withdrawalFilters.earn = earnToSet;

			const earn = earnToSet ? 'yes' : 'no';

			this.addEarnChips({
				earn,
				type: 'deposit',
				tableElements: this.depositTableElements,
				updateTable: () => this.updateDepositsTable(),
				filters: this.depositFilters,
				selects: this.depositSelects
			});
			this.addEarnChips({
				earn,
				type: 'withdrawal',
				tableElements: this.withdrawalTableElements,
				updateTable: () => this.updateWithdrawalTable(),
				filters: this.withdrawalFilters,
				selects: this.withdrawalSelects
			});

			this.depositSelects.earn.updateItems([earn]);
			this.withdrawalSelects.earn.updateItems([earn]);
		}

		if (countryToSet ?? jurisdictionsToSet ?? isBoolean(earnToSet)) {
			this.updateDepositsTable();
			this.updateWithdrawalTable();
			this.updateInitialScrollToFragment();
		}
	}

	private initTabs(): void {
		const urlParamsTab = escape(
			URL_PARAMS.get('tab') ?? undefined
		)?.toLowerCase();
		const activeTab = urlParamsTab === 'withdrawal' ? 1 : 0;
		const tabsWrapper = DomService.getElement<HTMLElement>('#payments-tab');

		if (!tabsWrapper) {
			return;
		}

		const tabs = new Tabs(this.onTabChange, activeTab, tabsWrapper);
	}

	private readonly onTabChange = (activeTab: string): void => {
		const accordionElements = Array.from(
			document.getElementsByClassName('accordion active')
		);

		accordionElements.forEach((accordion: HTMLDivElement) => {
			accordion.classList.remove('active');
			(accordion.nextElementSibling as HTMLDivElement).style.maxHeight =
				'';
		});

		const tab = activeTab.toLowerCase();
		this.updateQueryParams('tab', tab);
	};

	private initAccordion(): void {
		const accordionElements =
			DomService.getElements<HTMLDivElement>('.accordion') ?? [];

		for (let i = 0; i < accordionElements.length; i++) {
			const element = accordionElements[i];
			element.addEventListener('click', () => {
				element.classList.toggle('active');

				const id = element.dataset.accordion;
				if (!id) {
					return;
				}

				const panel = DomService.getElement<HTMLDivElement>(`#${id}`);
				if (!panel) {
					return;
				}

				panel.classList.toggle('active');
				panel.style.maxHeight = panel.style.maxHeight
					? ''
					: panel.scrollHeight + 'px';
			});
		}
	}

	private updateOpenAccordionElements(tableWrapper: HTMLElement): void {
		const accordionElements =
			DomService.getElements<HTMLDivElement>(
				'.accordion.active',
				tableWrapper
			) ?? [];

		forEach(accordionElements, (element: HTMLDivElement) => {
			const id = element.dataset.accordion;

			if (!id) {
				return;
			}

			const panel = DomService.getElement<HTMLDivElement>(`#${id}`);
			if (!panel) {
				return;
			}

			panel.style.maxHeight = panel.scrollHeight + 'px';
		});
	}

	private initTables(): void {
		const pageCount = [
			DeviceEnum.Desktop,
			DeviceEnum.LargeDesktop,
			DeviceEnum.Tablet
		].includes(UtilsService.getDevice())
			? 6
			: 9;

		this.depositGroupsElements =
			DomService.getElements<HTMLDivElement>(
				'.payments-table--deposits .payments-table__group'
			) ?? [];
		this.withdrawalGroupsElements =
			DomService.getElements<HTMLDivElement>(
				'.payments-table--withdrawals .payments-table__group'
			) ?? [];

		if (!isEmpty(this.depositGroupsElements)) {
			this.depositCollection = new PaymentsFilters({
				collection: this.depositGroupsElements,
				elementsNode: DomService.getElement(
					'.payments-table--deposits'
				) as Element,
				pageCount,
				contentWrapper: DomService.getElement<HTMLElement>(
					'.payments-table--deposits .payments-table__groups-wrapper'
				) as HTMLElement
			});

			this.initDepositTable();
		}

		if (!isEmpty(this.withdrawalGroupsElements)) {
			this.withdrawalCollection = new PaymentsFilters({
				collection: this.withdrawalGroupsElements,
				elementsNode: DomService.getElement(
					'.payments-table--withdrawals'
				) as Element,
				pageCount,
				contentWrapper: DomService.getElement<HTMLElement>(
					'.payments-table--withdrawals .payments-table__groups-wrapper'
				) as HTMLElement
			});

			this.initWithdrawalTable();
		}
	}

	private initDepositTable(): void {
		this.depositSelects = {
			country: new CustomSelect(
				this.depositTableElements.countrySelect,
				CustomSelectType.Radio
			),
			jurisdictions: new CustomSelect(
				this.depositTableElements.jurisdictionSelect,
				CustomSelectType.MultiChoice
			),
			earn: new CustomSelect(
				this.depositTableElements.earnSelect,
				CustomSelectType.Radio
			)
		};

		this.initTableElements({
			tableElements: this.depositTableElements,
			filters: this.depositFilters,
			selects: this.depositSelects,
			type: 'deposit',
			updateTable: () => this.updateDepositsTable()
		});
	}

	private initWithdrawalTable(): void {
		this.withdrawalSelects = {
			country: new CustomSelect(
				this.withdrawalTableElements.countrySelect,
				CustomSelectType.Radio
			),
			jurisdictions: new CustomSelect(
				this.withdrawalTableElements.jurisdictionSelect,
				CustomSelectType.MultiChoice
			),
			earn: new CustomSelect(
				this.withdrawalTableElements.earnSelect,
				CustomSelectType.Radio
			)
		};

		this.initTableElements({
			tableElements: this.withdrawalTableElements,
			filters: this.withdrawalFilters,
			selects: this.withdrawalSelects,
			type: 'withdrawal',
			updateTable: () => this.updateWithdrawalTable()
		});
	}

	private initTableElements({
		tableElements,
		filters,
		selects,
		type,
		updateTable
	}: {
		tableElements: ITableElements;
		filters: IFilters;
		selects: ITableSelects;
		type: TableType;
		updateTable: () => void;
	}): void {
		const timeToFundSelectWrapperElement =
			DomService.getElement<HTMLElement>(
				'.payments-table__filters--time-to-fund-select',
				tableElements.filtersWrapper
			);
		const modalCloseBtnElement = DomService.getElement<HTMLElement>(
			'.payments-modal__close',
			tableElements.tableWrapper
		);
		const modalFilterBtnElement = DomService.getElement<HTMLElement>(
			'.payments-modal__btn-wrapper .btn',
			tableElements.tableWrapper
		);
		const modalContentElement = DomService.getElement<HTMLElement>(
			'.payments-modal__content',
			tableElements.tableWrapper
		);
		const sortByDesktopWrapperElement = DomService.getElement<HTMLElement>(
			'.payments-table__methods-number-wrapper .payments-table__sort-by',
			tableElements.tableWrapper
		);
		const sortByMobileWrapperElement = DomService.getElement<HTMLElement>(
			'.payments-modal .payments-table__sort-by',
			tableElements.tableWrapper
		);
		const filterModalWrapperElement = DomService.getElement<HTMLDivElement>(
			'.payments-modal',
			tableElements.tableWrapper
		);
		const searchInputElement = DomService.getElement<HTMLInputElement>(
			'.payments-table__search-wrapper input',
			tableElements.tableWrapper
		);

		if (
			!timeToFundSelectWrapperElement ||
			!modalCloseBtnElement ||
			!modalFilterBtnElement ||
			!modalContentElement ||
			!sortByDesktopWrapperElement ||
			!sortByMobileWrapperElement ||
			!searchInputElement ||
			!filterModalWrapperElement
		) {
			throw new Error('Missing DOM elements');
		}

		// Country select
		selects.country.subscribe(([countryCode]: [string]) => {
			filters.country = countryCode;
			this.updateQueryParams('country', countryCode);
			updateTable();

			const label =
				DomService.getElement<HTMLLabelElement>(
					`label[data-value='${countryCode}']`,
					tableElements.countrySelect
				)?.innerText?.trim() ?? countryCode;

			DomService.getElement<HTMLElement>(`#${type}Country`)?.remove();

			this.addFiltersChips({
				chipsLabel: label,
				chipId: `${type}Country`,
				chipsWrapper: tableElements.chipsWrapper,
				clearAllFilterElement: tableElements.clearAllFilter,
				callback: () => {
					selects.country.reset();
					filters.country = undefined;
					updateTable();
					this.deleteQueryParams('country');
				}
			});
		});

		// Time to fund select
		const timeToFundSelect = new CustomSelect(
			timeToFundSelectWrapperElement,
			CustomSelectType.MultiChoice
		);
		timeToFundSelect.subscribe((timeToFund: Array<string>) => {
			const newChips: Array<string> = without(
				timeToFund,
				...(filters.timeToFund ?? [])
			);
			const chipsToRemove: Array<string> = without(
				filters.timeToFund ?? [],
				...timeToFund
			);

			filters.timeToFund = [...timeToFund];
			updateTable();

			forEach(newChips, (timeToFund: string) => {
				const label =
					DomService.getElement<HTMLLabelElement>(
						`label[data-value='${timeToFund}']`,
						timeToFundSelectWrapperElement
					)?.innerText ?? timeToFund;

				this.addFiltersChips({
					chipsLabel: label,
					chipsWrapper: tableElements.chipsWrapper,
					clearAllFilterElement: tableElements.clearAllFilter,
					chipId: `${type}TimeToFund${timeToFund}`,
					callback: () => {
						filters.timeToFund = without(
							filters.timeToFund,
							timeToFund
						);
						timeToFundSelect.updateItems(filters.timeToFund);
						updateTable();
					}
				});
			});

			forEach(chipsToRemove, (timeToFund: string) => {
				DomService.getElement<HTMLElement>(
					`#${type}TimeToFund${timeToFund}`
				)?.remove();
			});
		});

		// Jurisdictions select
		selects.jurisdictions.subscribe((jurisdictions: Array<string>) => {
			const newChips: Array<string> = without(
				jurisdictions,
				...(filters.jurisdictions ?? [])
			);
			const chipsToRemove: Array<string> = without(
				filters.jurisdictions ?? [],
				...jurisdictions
			);

			filters.jurisdictions = [...jurisdictions];

			if (!isEmpty(jurisdictions)) {
				this.updateQueryParams('jurisdiction', jurisdictions.join(','));
			} else {
				this.deleteQueryParams('jurisdiction');
			}
			updateTable();

			forEach(newChips, (jurisdiction: string) => {
				this.addFiltersChips({
					chipsLabel: jurisdiction,
					chipsWrapper: tableElements.chipsWrapper,
					clearAllFilterElement: tableElements.clearAllFilter,
					chipId: `${type}Jurisdiction${jurisdiction}`,
					callback: () => {
						filters.jurisdictions = without(
							filters.jurisdictions,
							jurisdiction
						);
						selects.jurisdictions.updateItems(
							filters.jurisdictions
						);
						updateTable();

						if (isEmpty(filters.jurisdictions)) {
							this.deleteQueryParams('jurisdiction');
						} else {
							this.updateQueryParams(
								'jurisdiction',
								filters.jurisdictions.join(',')
							);
						}
					}
				});
			});

			forEach(chipsToRemove, (jurisdiction: string) => {
				DomService.getElement<HTMLElement>(
					`#${type}Jurisdiction${jurisdiction}`
				)?.remove();
			});
		});

		// Earn select
		selects.earn.subscribe(([earn]: [string]) => {
			filters.earn =
				earn === 'yes' ? true : earn === 'no' ? false : undefined;

			this.updateQueryParams('earn', earn);
			updateTable();

			if (isBoolean(filters.earn)) {
				this.addEarnChips({
					earn,
					type,
					tableElements,
					updateTable,
					filters,
					selects
				});
			}
		});

		// Sort by select
		const sortBySelects: Array<CustomSelect> = [];
		forEach(
			[sortByDesktopWrapperElement, sortByMobileWrapperElement],
			(wrapperElement: HTMLElement) => {
				const sortBySelect = new CustomSelect(
					wrapperElement,
					CustomSelectType.Standard
				);
				sortBySelects.push(sortBySelect);

				sortBySelect.subscribe(([sortBy]: [SortBy]) => {
					if (type === 'deposit') {
						this.depositSortBy = sortBy;
					} else {
						this.withdrawalSortBy = sortBy;
					}

					updateTable();
				});
			}
		);

		tableElements.clearAllFilter.addEventListener('click', () => {
			forEach(
				[
					...sortBySelects,
					selects.country,
					timeToFundSelect,
					selects.jurisdictions,
					selects.earn
				],
				(select: CustomSelect) => select.reset()
			);

			filters.country = undefined;
			filters.timeToFund = undefined;
			filters.earn = undefined;
			filters.jurisdictions = undefined;
			this.deleteQueryParams('earn');
			this.deleteQueryParams('jurisdiction');
			this.deleteQueryParams('country');

			const elements =
				DomService.getElements<HTMLDivElement>('.chips--filter');
			forEach(elements ?? [], (element: HTMLDivElement) =>
				element.remove()
			);

			updateTable();
		});

		searchInputElement.addEventListener('keyup', (event: KeyboardEvent) => {
			filters.query = (event.target as HTMLInputElement)?.value
				?.toLowerCase()
				?.trim();
			updateTable();
		});

		tableElements.filterBtnElement.addEventListener('click', () => {
			filterModalWrapperElement.classList.add('payments-modal--open');
			modalContentElement.scrollTo(0, 0);

			DomService.disableBodyScroll();

			forEach(
				[
					...sortBySelects,
					selects.country,
					timeToFundSelect,
					selects.jurisdictions,
					selects.earn
				],
				(select: CustomSelect) => {
					select.emitChanges = false;
				}
			);
		});
		const closeModal = (): void => {
			filterModalWrapperElement.classList.remove('payments-modal--open');
			DomService.enableBodyScroll();

			forEach(
				[
					...sortBySelects,
					selects.country,
					timeToFundSelect,
					selects.jurisdictions,
					selects.earn
				],
				(select: CustomSelect) => {
					select.emitChanges = true;
				}
			);
		};

		modalCloseBtnElement.addEventListener('click', () => {
			forEach(
				[
					...sortBySelects,
					selects.country,
					timeToFundSelect,
					selects.jurisdictions,
					selects.earn
				],
				(select: CustomSelect) => select.restoreLastSavedFilters()
			);

			closeModal();
		});

		modalFilterBtnElement.addEventListener('click', () => {
			forEach(
				[
					...sortBySelects,
					selects.country,
					timeToFundSelect,
					selects.jurisdictions,
					selects.earn
				],
				(select: CustomSelect) => select.applyFilters()
			);

			closeModal();
		});

		const countries = DomService.getElements<HTMLLabelElement>(
			'.country-item',
			tableElements.tableWrapper
		);
		tableElements.countriesSearch.addEventListener(
			'keyup',
			(event: KeyboardEvent) => {
				const query = (event.target as HTMLInputElement)?.value
					?.toLowerCase()
					?.trim();
				let showNoResult: boolean = true;

				forEach(countries, (countryElement: HTMLLabelElement) => {
					const { value: countryCode, countryName } =
						countryElement.dataset;
					const showElement = query
						? (countryCode?.toLowerCase().includes(query) ??
								false) ||
						  (countryName?.toLowerCase().includes(query) ?? false)
						: true;

					if (showElement) {
						showNoResult = false;
					}

					countryElement.hidden = !showElement;
				});

				tableElements.countriesSearchNoResult.hidden = !showNoResult;
			}
		);
	}

	private updateDepositsTable(): void {
		if (!this.depositGroupsElements) {
			throw Error('Missing DOM elements');
		}

		this.updateTable({
			type: 'deposit',
			collection: this.depositCollection,
			filters: this.depositFilters,
			groupElements: this.depositGroupsElements,
			tableElements: this.depositTableElements,
			sortBy: this.depositSortBy
		});

		const activeFilterNumber = this.getActiveFilterNumber(
			this.depositFilters
		);
		this.depositTableElements.clearAllFilter.hidden = !activeFilterNumber;

		activeFilterNumber
			? (this.depositTableElements.filterBtnElement.dataset.activeFilters = `${activeFilterNumber}`)
			: delete this.depositTableElements.filterBtnElement.dataset
					.activeFilters;

		this.updateOpenAccordionElements(
			this.depositTableElements.tableWrapper
		);
	}

	private updateWithdrawalTable(): void {
		if (!this.withdrawalGroupsElements) {
			throw Error('Missing DOM elements');
		}

		this.updateTable({
			type: 'withdrawal',
			collection: this.withdrawalCollection,
			filters: this.withdrawalFilters,
			groupElements: this.withdrawalGroupsElements,
			tableElements: this.withdrawalTableElements,
			sortBy: this.withdrawalSortBy
		});

		const activeFilterNumber = this.getActiveFilterNumber(
			this.withdrawalFilters
		);
		this.withdrawalTableElements.clearAllFilter.hidden =
			!activeFilterNumber;

		activeFilterNumber
			? (this.withdrawalTableElements.filterBtnElement.dataset.activeFilters = `${activeFilterNumber}`)
			: delete this.withdrawalTableElements.filterBtnElement.dataset
					.activeFilters;

		this.updateOpenAccordionElements(
			this.withdrawalTableElements.tableWrapper
		);
	}

	private updateTable({
		collection,
		filters,
		groupElements,
		tableElements,
		type,
		sortBy
	}: {
		collection: PaymentsFilters;
		filters: IFilters;
		groupElements: Array<HTMLDivElement>;
		tableElements: ITableElements;
		type: TableType;
		sortBy: SortBy;
	}): void {
		const { noData, methodsNumber } = tableElements;
		const isDeposit = type === 'deposit';
		const paymentsGroups = filter(this.data, (group: IPaymentGroup) =>
			group.operators.some((operator: IPaymentGroupOperator) =>
				isDeposit
					? operator.isDepositEnabled
					: operator.isWithdrawalEnabled
			)
		);
		const groupsWrapper = DomService.getElement<HTMLDivElement>(
			'.payments-table__groups-wrapper',
			tableElements.tableWrapper
		);

		if (!groupsWrapper) {
			return;
		}

		let methodsCounter: number = 0;

		const groupsElementsToShow = flow(
			(items: Array<HTMLDivElement>) => {
				const collection = Array.from(items);
				collection.sort((a, b) => {
					const groupA = paymentsGroups[toNumber(a.dataset.groupId)];
					const groupB = paymentsGroups[toNumber(b.dataset.groupId)];

					switch (sortBy) {
						case SortBy.DateDesc:
							return groupB.dateAdded - groupA.dateAdded;
						case SortBy.DateAsc:
							return groupA.dateAdded - groupB.dateAdded;
						case SortBy.NameAsc:
							return groupA.name.localeCompare(groupB.name);
						case SortBy.NameDesc:
							return groupB.name.localeCompare(groupA.name);
						case SortBy.PopularAsc:
							return groupA.isPopular && groupB.isPopular
								? 0
								: groupA.isPopular
								? 1
								: -1;
						case SortBy.PopularDesc:
							return groupA.isPopular && groupB.isPopular
								? 0
								: groupA.isPopular
								? -1
								: 1;
					}

					return 0;
				});
				collection.forEach((item) => {
					groupsWrapper.appendChild(item);
				});

				return collection;
			},
			(items: Array<HTMLDivElement>) =>
				filter(items, (groupElement: HTMLDivElement) => {
					const groupIndex = groupElement.dataset.groupId;

					let showGroup: boolean = false;
					let showOperatorsCount: number = 0;
					let isOperatorStakeable: boolean = false;

					const operatorsElements =
						DomService.getElements<HTMLDivElement>(
							'.payments-table__methods-details',
							groupElement
						) ?? [];

					forEach(
						operatorsElements,
						(operatorElement: HTMLDivElement) => {
							const operatorIndex =
								operatorElement.dataset.operatorId;
							const operator: IPaymentGroupOperator =
								operatorIndex
									? // @ts-expect-error
									  paymentsGroups?.[groupIndex]?.operators[
										// prettier-ignore
										operatorIndex
										]
									: null;

							if (!operator) {
								throw new Error(
									`Missing operator (${groupIndex}-${operatorIndex})`
								);
							}

							let showOperator;

							// Check country
							showOperator = filters.country
								? operator.countries.includes(filters.country)
								: true;

							if (showOperator) {
								if (filters.query) {
									// Search query
									showOperator = some(
										[
											operator.displayName,
											operator.name,
											operator.limitCurrency,
											operator.availableCurrencies.join(
												','
											)
										],
										(data: string) =>
											filters.query
												? data
														.toLowerCase()
														.includes(filters.query)
												: false
									);
								}

								// Check time to fund
								if (
									!isEmpty(filters.timeToFund) &&
									filters.timeToFund
								) {
									showOperator = filters.timeToFund.includes(
										operator.minutesToDeposit.toString()
									);
								}

								// Check jurisdictions
								if (
									showOperator &&
									!isEmpty(filters.jurisdictions) &&
									filters.jurisdictions
								) {
									showOperator = operator.jurisdictions.some(
										(jurisdictionId: string) =>
											(
												filters.jurisdictions ?? []
											).includes(jurisdictionId)
									);
								}

								// Check earn
								if (showOperator && isBoolean(filters.earn)) {
									const isOperatorStakeable =
										!!this.stakingData[
											operator.limitCurrency
										];

									showOperator = filters.earn
										? isOperatorStakeable
										: !isOperatorStakeable;
								}
							}

							operatorElement.hidden = !showOperator;

							if (showOperator) {
								methodsCounter++;
								showGroup = true;
								showOperatorsCount++;

								if (!isOperatorStakeable) {
									isOperatorStakeable =
										!!this.stakingData[
											operator.limitCurrency
										];
								}
							}
						}
					);

					groupElement.hidden = !showGroup;

					if (showGroup) {
						// Update methods chips
						const methodsChipsElement =
							DomService.getElement<HTMLDivElement>(
								'.payments-chips__methods',
								groupElement
							);

						if (methodsChipsElement) {
							methodsChipsElement.hidden =
								showOperatorsCount === 1;

							if (showOperatorsCount > 1) {
								const methodsCountElement =
									DomService.getElement<HTMLDivElement>(
										'.payments-chips__methods-number',
										groupElement
									);

								if (methodsCountElement) {
									methodsCountElement.innerText =
										showOperatorsCount.toString();
								}
							}
						}

						// Update earn chips
						const earnChipsElement =
							DomService.getElement<HTMLDivElement>(
								'.payments-chips__earn'
							);

						if (earnChipsElement) {
							earnChipsElement.hidden = !isOperatorStakeable;
						}
					}

					return showGroup;
				})
		)(groupElements) as unknown as Array<HTMLDivElement>;

		collection.updateData(groupsElementsToShow);
		noData.hidden = !!groupsElementsToShow.length;
		methodsNumber.innerText = methodsCounter.toString();
	}

	private addFiltersChips({
		chipsLabel,
		chipId,
		chipsWrapper,
		clearAllFilterElement,
		callback
	}: {
		chipsLabel: string;
		chipId: string;
		chipsWrapper: HTMLElement;
		clearAllFilterElement: HTMLElement;
		callback: () => void;
	}): void {
		const newElement = document.createElement('div');
		newElement.classList.add('chips--filter');
		newElement.innerText = chipsLabel;
		newElement.id = chipId;

		newElement.addEventListener(
			'click',
			() => {
				newElement.remove();
				callback();
			},
			{ once: true }
		);

		chipsWrapper.insertBefore(newElement, clearAllFilterElement);
	}

	private updateQueryParams(name: QueryParamsTab, value: string): void {
		UtilsService.updateQueryParams(name, value);
	}

	private deleteQueryParams(name: QueryParamsTab): void {
		UtilsService.removeQueryParams(name);
	}

	private checkNewChips(): void {
		const newsChips = DomService.getElements<HTMLElement>(
			'.payments-table__wrapper .chips--blue'
		);

		if (!newsChips) {
			return;
		}

		forEach(newsChips, (element: HTMLElement) => {
			const dateAdded = toNumber(element.dataset.dateAdded);

			if (!dateAdded) {
				return;
			}

			if (
				dateAdded * 1000 <
				new Date().valueOf() - 31 * 60 * 60 * 24 * 1000
			) {
				element.hidden = true;
			}
		});
	}

	private addEarnChips({
		earn,
		type,
		tableElements,
		updateTable,
		filters,
		selects
	}: {
		earn: string;
		type: TableType;
		filters: IFilters;
		selects: ITableSelects;
		tableElements: ITableElements;
		updateTable: () => void;
	}): void {
		const title =
			DomService.getElement<HTMLDivElement>(
				'.custom-select__list-title',
				tableElements.earnSelect
			)?.innerText?.trim() ?? 'Earn';
		const label =
			DomService.getElement<HTMLLabelElement>(
				`label[data-value='${earn}']`,
				tableElements.earnSelect
			)?.innerText?.trim() ?? earn;

		DomService.getElement<HTMLDivElement>(
			`#${type}Earn`,
			tableElements.chipsWrapper
		)?.remove();

		this.addFiltersChips({
			chipsLabel: `${title}: ${label}`,
			chipId: `${type}Earn`,
			chipsWrapper: tableElements.chipsWrapper,
			clearAllFilterElement: tableElements.clearAllFilter,
			callback: () => {
				filters.earn = undefined;
				selects.earn.reset();
				this.deleteQueryParams('earn');
				updateTable();
			}
		});
	}

	private getActiveFilterNumber(filters: IFilters): number {
		return filter(
			[
				isBoolean(filters.earn),
				isString(filters.country),
				...map(filters.jurisdictions, toString),
				...map(filters.timeToFund, toString)
			],
			Boolean
		).length;
	}

	private updateInitialScrollToFragment(): void {
		if (window.location.hash) {
			const targetElement = DomService.getElement<HTMLElement>(
				window.location.hash
			);

			if (targetElement) {
				setTimeout(() => {
					const { top } = targetElement.getBoundingClientRect();

					window.scrollTo({
						top: window.scrollY + top,
						behavior: 'smooth'
					});
				});
			}
		}
	}

	private addListenerToEarnChips(): void {
		const chips = DomService.getElements<HTMLAnchorElement>('.earn-link');

		forEach(chips, (element: HTMLAnchorElement) => {
			element.addEventListener('click', (event: MouseEvent) => {
				event.stopImmediatePropagation();
			});
		});
	}
}

DomService.runOnDomReady(() => new PaymentsPage());
