import { Tabs, VideoPlayerControls } from '../modules';
import { DomService } from '../services';

export class WebTraderPage extends VideoPlayerControls {
	constructor() {
		super();

		if (document.querySelector('body.page-web-trader')) {
			this.init();
			this.initPlayerControls(`.platform__video-player`);
		}
	}

	private init(): void {
		const tabs = new Tabs();
	}
}

DomService.runOnDomReady(() => new WebTraderPage());
