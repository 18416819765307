import { Tabs } from '../modules';
import { DomService, UtilsService } from '../services';

export class BitcoinLightningNetworkPage {
	constructor() {
		if (document.querySelector('body.page-bitcoin-lightning-network')) {
			this.init();
			this.initReadMore();
		}
	}

	private init(): void {
		const tabs = new Tabs();
	}

	private initReadMore(): void {
		const readMoreBtn = DomService.getElement<HTMLElement>(
			`.bitcoin-ln__read-more-btn`
		);
		const longDescription = DomService.getElement<HTMLElement>(
			`.bitcoin-ln__long-description-section`
		);

		const sectionDescription = DomService.getElement<HTMLElement>(
			`.bitcoin-ln__read-more-section`
		);

		if (readMoreBtn && longDescription && sectionDescription) {
			readMoreBtn.addEventListener('click', () => {
				readMoreBtn.classList.toggle('active');
				longDescription.classList.toggle('hidden');

				if (!readMoreBtn.classList.contains('active')) {
					UtilsService.scrollIntoViewWithOffset(
						sectionDescription,
						20
					);
				}
			});
		}
	}
}

DomService.runOnDomReady(() => new BitcoinLightningNetworkPage());
