import { reduce } from 'lodash-es';

import {
	FullWidthCarousel,
	InstrumentsCollectionQuotes,
	Tabs
} from '../modules';
import { AnalyticsService, DomService, UtilsService } from '../services';
import { Slider } from '../modules/slider';

export class HomePage {
	private homeData: {
		instrumentsPageCount: number;
	};

	constructor() {
		if (document.querySelector('body.page-home')) {
			this.initBannersCarousel();
			this.initBlogCarousel();
			this.initElements();
			this.initInstrumentsTable();
			this.initBitcoinQuotes();
			this.initPlatformsSlider();
			AnalyticsService.initAnalyticsHomeEvents();
		}
	}

	private initElements(): void {
		const homeDataElement =
			DomService.getElement<HTMLElement>(`#home-data`);
		if (homeDataElement?.textContent) {
			this.homeData = JSON.parse(homeDataElement.textContent);
		}
	}

	private getActiveTabElement(): HTMLElement | undefined {
		return DomService.getElement<HTMLElement>(`.tab__content--active`);
	}

	private initInstrumentsTable(): void {
		const firstTableElement = this.getActiveTabElement();

		if (!firstTableElement) {
			return;
		}

		const instrumentsCollectionQuotes = new InstrumentsCollectionQuotes(
			undefined,
			undefined,
			firstTableElement
		);

		const tabs = new Tabs(() => {
			instrumentsCollectionQuotes.updateElements(
				this.getActiveTabElement()
			);
		});
	}

	private initBitcoinQuotes(): void {
		const bitcoinQuotes = new InstrumentsCollectionQuotes(
			'.section-discover__symbol-data',
			'.section-discover__left-column'
		);
	}

	private initBannersCarousel(): void {
		const wrapperElement =
			DomService.getElement<HTMLElement>('.section-banners');

		if (!wrapperElement) {
			return;
		}

		const sliderContainer = DomService.getElement<HTMLElement>(
			'.section-banners__wrapper',
			wrapperElement
		);

		if (sliderContainer) {
			const requiredWidth = reduce(
				Array.from(sliderContainer.children),
				(sum: number, element: HTMLElement) =>
					sum + element.clientWidth,
				0
			);
			const startWithLoop = requiredWidth >= window.innerWidth;

			const carousel = new FullWidthCarousel(
				wrapperElement,
				sliderContainer,
				'.banner__item',
				{
					autoPlay: true,
					initialTranslateXValue:
						this.getInitialCarouselTranslateXValue(wrapperElement),
					loop: startWithLoop
				}
			);
		}
	}

	private initPlatformsSlider(): void {
		const rightArrow = DomService.getElement<HTMLElement>(
			'.section-trade-device__arrow--next'
		);
		const leftArrow = DomService.getElement<HTMLElement>(
			'.section-trade-device__arrow--previous'
		);
		const carouselWrapper = DomService.getElement<HTMLElement>(
			'.section-trade-device__slider-wrapper'
		);
		const sectionWrapper = DomService.getElement<HTMLElement>(
			'.section-trade-device__wrapper'
		);
		const elements = DomService.getElements<HTMLElement>(
			'.section-trade-device__main-slide'
		);
		const dots = DomService.getElements<HTMLElement>(
			'.section-trade-device__bullet'
		);

		if (
			!rightArrow ||
			!leftArrow ||
			!elements ||
			!dots ||
			!carouselWrapper ||
			!sectionWrapper
		) {
			return;
		}

		const slider = new Slider({
			leftArrow,
			rightArrow,
			carouselWrapper,
			sectionWrapper,
			dots,
			elements
		});
	}

	private initBlogCarousel(): void {
		const blogWrapperElement = DomService.getElement<HTMLElement>(
			'.section-blog__content'
		);

		if (!blogWrapperElement) {
			return;
		}

		const sliderContainer = DomService.getElement<HTMLElement>(
			'.related-articles__slider-wrapper',
			blogWrapperElement
		);

		if (sliderContainer) {
			const carousel = new FullWidthCarousel(
				blogWrapperElement,
				sliderContainer,
				'.related-articles__item',
				{
					initialTranslateXValue:
						this.getInitialCarouselTranslateXValue(
							blogWrapperElement
						)
				}
			);
		}
	}

	private getInitialCarouselTranslateXValue(element: HTMLElement): number {
		return (
			Math.max((window.innerWidth - element.clientWidth) / 2 - 100, 0) *
			(UtilsService.isLeftToRightDirection() ? -1 : 1)
		);
	}
}

DomService.runOnDomReady(() => new HomePage());
