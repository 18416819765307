import { CLASS_NAMES } from '../../config';
import { DomService } from '../../services';

export class CollectionSearch {
	private readonly topLevelClass?: string;
	private readonly contentClass: string;
	private readonly wrapperElement?: HTMLElement;

	constructor(
		contentClass: string,
		topLevelClass?: string,
		wrapperElement?: HTMLElement,
		protected datasetField?: string
	) {
		this.contentClass = contentClass;
		this.topLevelClass = topLevelClass;
		this.wrapperElement = wrapperElement;
		this.init();
	}

	private init(): void {
		const searchInputElement =
			DomService.getElement<HTMLInputElement>('#inputSearch');
		const closeInputElement = DomService.getElement<HTMLElement>(
			'.main .form__icon-close',
			this.wrapperElement
		);
		const groupItems = DomService.getElements<HTMLSelectElement>(
			this.contentClass,
			this.wrapperElement
		);
		let topLevelItems: Array<HTMLSelectElement> | undefined;

		if (this.topLevelClass) {
			topLevelItems = DomService.getElements<HTMLSelectElement>(
				this.topLevelClass,
				this.wrapperElement
			);

			if (!topLevelItems) {
				return;
			}
		}

		if (!searchInputElement || !groupItems) {
			return;
		}

		searchInputElement.addEventListener('keyup', (event: KeyboardEvent) => {
			const searchedText = (
				event.target as HTMLInputElement
			).value.toLowerCase();

			if (closeInputElement) {
				if (searchedText.length) {
					closeInputElement.classList.add(
						CLASS_NAMES.inputIconActive
					);
				} else {
					closeInputElement.classList.remove(
						CLASS_NAMES.inputIconActive
					);
				}
			}

			if (topLevelItems) {
				topLevelItems.forEach((element: HTMLElement) => {
					element.classList.add(CLASS_NAMES.searchDisplayNone);
				});
			}

			groupItems.forEach((element: HTMLElement) => {
				let datasetMatch = true;
				if (this.datasetField) {
					datasetMatch = (element.dataset[this.datasetField] ?? '')
						.toLowerCase()
						.includes(searchedText);
				}
				if (
					element.innerText.toLowerCase().includes(searchedText) ||
					(this.datasetField && datasetMatch)
				) {
					element.classList.remove(CLASS_NAMES.searchDisplayNone);
					if (this.topLevelClass) {
						element
							.closest(this.topLevelClass)
							?.classList.remove(CLASS_NAMES.searchDisplayNone);
					}
				} else {
					element.classList.add(CLASS_NAMES.searchDisplayNone);
				}
			});
		});

		if (closeInputElement) {
			closeInputElement.addEventListener('click', () => {
				searchInputElement.value = '';
				searchInputElement.dispatchEvent(new KeyboardEvent('keyup'));
			});
		}
	}
}
