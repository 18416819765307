import { Tabs } from '../modules';
import { DomService } from '../services';

export class MobileAppPage {
	constructor() {
		if (document.querySelector('body.page-mobile-app')) {
			this.init();
		}
	}

	private init(): void {
		const tabs = new Tabs();
	}
}

DomService.runOnDomReady(() => new MobileAppPage());
