import ClipboardJS from 'clipboard';

import { DomService } from '../services';

export class ConnectForBusinessPage {
	constructor() {
		if (document.querySelector('body.page-connect-for-business')) {
			this.initCopyButtons();
		}
	}

	private initCopyButtons(): void {
		const clipboardJs = new ClipboardJS('[data-copy]');

		let messageElement: HTMLElement;
		let timer: number;

		const createMessage = (
			type: 'success' | 'error',
			element: HTMLElement
		): void => {
			if (timer) {
				clearTimeout(timer);
			}

			if (messageElement) {
				messageElement.remove();
			}

			const { onCopySuccess, onCopyError } = element.dataset;

			messageElement = document.createElement('div');
			messageElement.classList.add('alert', `alert--${type}`);
			messageElement.innerText =
				type === 'success'
					? onCopySuccess ?? 'Copied to clipboard'
					: onCopyError ?? 'Copy to clipboard field';
			document.body.appendChild(messageElement);

			timer = window.setTimeout(() => {
				messageElement.remove();
			}, 3000);
		};

		clipboardJs
			.on('success', (event: ClipboardJS.Event) => {
				if (event.trigger instanceof HTMLElement) {
					createMessage('success', event.trigger);
				}
			})
			.on('error', (event: ClipboardJS.Event) => {
				if (event.trigger instanceof HTMLElement) {
					createMessage('error', event.trigger);
				}
			});
	}
}

DomService.runOnDomReady(() => new ConnectForBusinessPage());
