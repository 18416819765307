import { forEach, times } from 'lodash-es';
import Hammer from 'hammerjs';

import { DomService, UtilsService } from '../services';
import { TestimonialsCarousel } from '../modules';

export class AboutPage {
	private hideHistorySectionAnimation?: Animation;
	private showHistorySectionAnimation?: Animation;
	private timelineHistorySectionAnimation?: Animation;
	private onPanEndAnimation: Animation;
	private currentHistoryIndex: number = 0;
	private translateXValue: number = 0;
	private readonly isLeftToRightDirection: boolean =
		UtilsService.isLeftToRightDirection();

	private historySectionElements: {
		timelineYears: Array<HTMLElement>;
		timelineYearsWrapper: HTMLElement;
		yearsContent: Array<HTMLElement>;
	};

	constructor() {
		if (document.querySelector('body.page-about')) {
			this.init();
			this.initHistoryCarousel();
		}
	}

	private get timelineContainerWidth(): number {
		return this.historySectionElements.timelineYearsWrapper.clientWidth;
	}

	private get timelineItemWidth(): number {
		return this.historySectionElements.timelineYearsWrapper.children[0]
			.clientWidth;
	}

	private get timelineItemsGap(): number {
		return (
			(this.historySectionElements.timelineYearsWrapper.scrollWidth -
				this.timelineItemWidth *
					this.historySectionElements.timelineYearsWrapper.children
						.length) /
			(this.historySectionElements.timelineYearsWrapper.children.length -
				1)
		);
	}

	private init(): void {
		const testimonialsCarousel = new TestimonialsCarousel();
	}

	private initHistoryCarousel(): void {
		const timelineYears = Array.from(
			DomService.getElements<HTMLElement>(
				'.section-history__timeline-year'
			) ?? []
		);
		const timelineYearsWrapper = DomService.getElement<HTMLElement>(
			'.section-history__timeline-years-wrapper'
		);
		const yearsContent = Array.from(
			DomService.getElements<HTMLElement>(
				'.section-history__year-wrapper'
			) ?? []
		);

		if (
			!timelineYears.length ||
			!timelineYearsWrapper ||
			!yearsContent.length
		) {
			throw new Error('Missing DOM elements');
		}

		this.historySectionElements = {
			timelineYears,
			timelineYearsWrapper,
			yearsContent
		};

		this.initTimelineCarousel();

		forEach(timelineYears, (element: HTMLElement, index) => {
			element.addEventListener('click', () => {
				if (index === this.currentHistoryIndex) {
					return;
				}

				this.hideHistorySectionAnimation?.cancel();
				this.showHistorySectionAnimation?.cancel();
				this.timelineHistorySectionAnimation?.cancel();

				forEach(timelineYears, (element: HTMLDivElement) =>
					element.classList.remove('active')
				);

				element.classList.add('active');

				this.animateTimelineHistorySection(index);

				this.animateHistorySections(index);
			});
		});
	}

	private animateHistorySections(index: number): void {
		const elementToHide =
			this.historySectionElements.yearsContent[this.currentHistoryIndex];

		if (!elementToHide) {
			return;
		}

		this.hideHistorySectionAnimation = elementToHide.animate(
			[{ opacity: 1 }, { opacity: 0 }],
			{
				duration: 300,
				fill: 'forwards',
				easing: 'ease-out'
			}
		);
		this.hideHistorySectionAnimation.oncancel = () => {
			this.hideHistorySectionAnimation = undefined;
		};
		this.hideHistorySectionAnimation.onfinish = () => {
			this.hideHistorySectionAnimation = undefined;
			this.currentHistoryIndex = index;

			elementToHide.hidden = true;
			this.historySectionElements.yearsContent[index].hidden = false;

			this.showHistorySectionAnimation =
				this.historySectionElements.yearsContent[index].animate(
					[{ opacity: 0 }, { opacity: 1 }],
					{
						duration: 300,
						fill: 'forwards',
						easing: 'ease-in'
					}
				);
			this.showHistorySectionAnimation.onfinish = () => {
				this.showHistorySectionAnimation = undefined;
			};
			this.showHistorySectionAnimation.oncancel = () => {
				this.currentHistoryIndex = index;
				this.showHistorySectionAnimation = undefined;
			};
		};
	}

	private animateTimelineHistorySection(index: number): void {
		let transformXFrom =
			this.timelineContainerWidth * 0.5 -
			this.timelineItemWidth / 2 -
			this.currentHistoryIndex *
				(this.timelineItemWidth + this.timelineItemsGap);
		let transformXTo =
			this.timelineContainerWidth * 0.5 -
			this.timelineItemWidth / 2 -
			index * (this.timelineItemWidth + this.timelineItemsGap);

		if (!this.isLeftToRightDirection) {
			transformXFrom *= -1;
			transformXTo *= -1;
		}

		this.translateXValue = transformXTo;

		this.timelineHistorySectionAnimation =
			this.historySectionElements.timelineYearsWrapper.animate(
				[
					{ transform: `translateX(${transformXFrom}px)` },
					{ transform: `translateX(${transformXTo}px)` }
				],
				{
					duration: 300,
					easing: 'ease-out'
				}
			);
		this.timelineHistorySectionAnimation.onfinish = () => {
			this.historySectionElements.timelineYearsWrapper.style.transform = `translateX(${this.translateXValue}px)`;
		};
		this.timelineHistorySectionAnimation.oncancel = () => {
			this.historySectionElements.timelineYearsWrapper.style.transform = `translateX(${this.translateXValue}px)`;
		};
	}

	private initTimelineCarousel(): void {
		const hammerManager = new Hammer(
			this.historySectionElements.timelineYearsWrapper,
			{
				touchAction: 'pan-y'
			}
		);

		let initialTranslateXValue =
			this.timelineContainerWidth * 0.5 - this.timelineItemWidth / 2;

		if (!this.isLeftToRightDirection) {
			initialTranslateXValue *= -1;
		}

		this.historySectionElements.timelineYearsWrapper.style.transform = `translateX(${initialTranslateXValue}px)`;
		this.translateXValue = initialTranslateXValue;

		const points = times(
			this.historySectionElements.timelineYearsWrapper.children.length,
			(index: number) => {
				return (
					initialTranslateXValue +
					(this.isLeftToRightDirection ? -1 : 1) *
						index *
						(this.timelineItemWidth + this.timelineItemsGap)
				);
			}
		);

		const onPanMove = ({
			direction,
			deltaX,
			srcEvent
		}: HammerInput): void => {
			if (
				srcEvent instanceof PointerEvent &&
				srcEvent?.pointerType === 'mouse'
			) {
				return;
			}
			this.onPanEndAnimation?.cancel();

			const directionMultiplier =
				direction === Hammer.DIRECTION_LEFT ? -1 : 1;
			const transformTo =
				this.translateXValue + (directionMultiplier + deltaX);

			this.historySectionElements.timelineYearsWrapper.style.transform = `translateX(${transformTo}px)`;
		};

		const onPanEnd = ({
			direction,
			deltaX,
			velocityX,
			srcEvent
		}: HammerInput): void => {
			if (
				srcEvent instanceof PointerEvent &&
				srcEvent?.pointerType === 'mouse'
			) {
				return;
			}

			const directionMultiplier =
				direction === Hammer.DIRECTION_LEFT ? -1 : 1;
			const transformFrom =
				this.translateXValue + (directionMultiplier + deltaX);
			let transformTo =
				transformFrom + velocityX * this.timelineContainerWidth;

			const closestIndex = UtilsService.findClosestIndex(
				transformTo,
				points
			);
			forEach(
				this.historySectionElements.timelineYears,
				(element: HTMLDivElement) => element.classList.remove('active')
			);
			this.historySectionElements.timelineYears[
				closestIndex
			].classList.add('active');

			transformTo = points[closestIndex];

			this.onPanEndAnimation =
				this.historySectionElements.timelineYearsWrapper.animate(
					[
						{ transform: `translateX(${transformFrom}px` },
						{ transform: `translateX(${transformTo}px` }
					],
					{
						duration: 300,
						easing: 'ease-in-out'
					}
				);

			this.onPanEndAnimation.onfinish = () => {
				this.historySectionElements.timelineYearsWrapper.style.transform = `translateX(${transformTo}px)`;
				this.translateXValue = transformTo;

				this.animateHistorySections(closestIndex);
			};
		};

		hammerManager.on('panmove', onPanMove);
		hammerManager.on('panend', onPanEnd);
		hammerManager.on('pancancel', onPanEnd);
	}
}

DomService.runOnDomReady(() => new AboutPage());
