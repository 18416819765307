import { escape } from 'lodash-es';

export class CookieService {
	private static instance: CookieService;
	private readonly _prefix = 'SFXHome.';

	public static getInstance(): CookieService {
		if (!CookieService.instance) {
			CookieService.instance = new CookieService();
		}

		return CookieService.instance;
	}

	public get maxExpiryDate(): string {
		const date = new Date();
		date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
		return date.toUTCString();
	}

	public set(
		key: string,
		value: boolean | string,
		expiry?: Date,
		withoutPrefix?: boolean
	): void {
		document.cookie = `${withoutPrefix ? '' : this._prefix}${key}=${
			value || ''
		}; Expires=${expiry?.toUTCString() ?? this.maxExpiryDate}; Path=/`;
	}

	public get(key: string): string | null {
		const match = new RegExp(key + '=([^;]+)').exec(document.cookie);
		return match ? escape(match[1]) : null;
	}
}
