import {
	escape,
	filter,
	forEach,
	includes,
	inRange,
	isEmpty,
	isString,
	pull
} from 'lodash-es';

import { URL_PARAMS } from '../config';
import { DomService, InstrumentsService, UtilsService } from '../services';
import { CustomSelect, CustomSelectType } from '../modules/custom-select';
import { CollectionFilters, SortTypes } from '../modules/collection-filters';
import { InstrumentsCollectionQuotes } from '../modules';

type FilterField = 'category' | 'market';

interface IFilters {
	category: Array<string>;
	market: Array<string>;
}

export class InstrumentsSearchPage {
	private initialInstruments: Array<HTMLElement> = [];
	private filters: IFilters = {
		category: [],
		market: []
	};

	private categoriesData: Array<string>;
	private query?: string;
	private collection: CollectionFilters;
	private readonly instrumentsCollectionQuotes: InstrumentsCollectionQuotes;

	constructor() {
		if (document.querySelector('body.page-instruments')) {
			this.initElements();
			this.initData();
			this.initReadMore();
			this.initSearch();

			this.instrumentsCollectionQuotes = new InstrumentsCollectionQuotes(
				`.instrument-tile__item:not(.display-none)`,
				`.instruments-search__wrapper`
			);
		}
	}

	private initData(): void {
		const categoriesData = DomService.getElement<HTMLScriptElement>(
			'script#categoriesData'
		)?.textContent;

		if (!isString(categoriesData)) {
			return;
		}

		let categoriesToSet;

		try {
			this.categoriesData = JSON.parse(categoriesData);
			const categoriesParam = escape(
				URL_PARAMS.get('category') ?? undefined
			)?.toLowerCase();

			if (categoriesParam) {
				const categories = categoriesParam.split(',');
				categoriesToSet = filter(categories, (category: string) =>
					this.categoriesData.includes(category)
				);

				if (categoriesToSet.length === this.categoriesData.length) {
					categoriesToSet = undefined;
				}
			}
		} catch (_) {
			console.error(`Can't parse data`);
		}

		this.initSelects(categoriesToSet);
	}

	private initElements(): void {
		const instruments = DomService.getElements<HTMLElement>(
			'.instrument-tile__item'
		);
		const contentWrapper = DomService.getElement<HTMLElement>(
			'.instruments-search__wrapper'
		);

		if (!instruments) {
			return;
		}

		this.initialInstruments = instruments;

		// Show 15 items for standard desktop (1025 <= screen width < 1367)
		const showOddPageCont = inRange(
			UtilsService.getWindowSize(),
			1025,
			1367
		);
		const pageCount = showOddPageCont ? 15 : 16;

		this.collection = new CollectionFilters({
			collection: instruments,
			pageCount,
			contentWrapper,
			initialSortType: SortTypes.Newest
		});

		if (showOddPageCont) {
			// Rerender table
			this.filterInstruments();
		}

		this.collection.onAfterCollectionRendered(() => {
			if (this.instrumentsCollectionQuotes) {
				this.instrumentsCollectionQuotes.updateElements();
			}
		});
	}

	private initSelects(categoriesToSet?: Array<string>): void {
		const typeElement = DomService.getElement<HTMLElement>('#type-select');
		const marketElement =
			DomService.getElement<HTMLElement>('#market-select');
		const sortDesktopElement = DomService.getElement<HTMLElement>(
			'#sort-select-desktop'
		);
		const sortMobileElement = DomService.getElement<HTMLElement>(
			'#sort-select-mobile'
		);
		const categoryChipsWrapperElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-chips-wrapper--category'
		);
		const marketChipsWrapperElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-chips-wrapper--market'
		);

		if (
			!typeElement ||
			!marketElement ||
			!sortDesktopElement ||
			!sortMobileElement ||
			!categoryChipsWrapperElement ||
			!marketChipsWrapperElement
		) {
			return;
		}

		const onSelectUpdate = (
			newElements: Array<string>,
			select: CustomSelect,
			filtersField: FilterField,
			chipsWrapper: HTMLElement,
			removeCallback?: () => void
		): void => {
			this.filters[filtersField] = newElements;
			chipsWrapper.innerHTML = '';

			newElements.forEach((item: string) => {
				const newElement = document.createElement('div');
				newElement.classList.add('chips--filter');
				newElement.innerText = item.replace('-', ' ');

				newElement.addEventListener(
					'click',
					() => {
						newElement.remove();
						select.updateItems(
							pull(this.filters[filtersField], item)
						);
						this.filterInstruments();

						if (removeCallback) {
							removeCallback();
						}
					},
					{ once: true }
				);

				chipsWrapper.appendChild(newElement);
			});

			this.filterInstruments();
		};

		const updateCategoryQueryParam = (): void => {
			if (isEmpty(this.filters.category)) {
				UtilsService.removeQueryParams('category');
			} else {
				UtilsService.updateQueryParams(
					'category',
					this.filters.category.join(',')
				);
			}
		};

		const typeSelect = new CustomSelect(
			typeElement,
			CustomSelectType.MultiChoice
		);
		typeSelect.subscribe((types: Array<string>) => {
			if (!isEmpty(types)) {
				UtilsService.updateQueryParams('category', types.join(','));
			} else {
				UtilsService.removeQueryParams('category');
			}

			onSelectUpdate(
				types,
				typeSelect,
				'category',
				categoryChipsWrapperElement,
				() => updateCategoryQueryParam()
			);
		});

		if (categoriesToSet) {
			onSelectUpdate(
				categoriesToSet,
				typeSelect,
				'category',
				categoryChipsWrapperElement,
				() => updateCategoryQueryParam()
			);

			setTimeout(() => {
				typeSelect.updateItems(categoriesToSet);
			}, 0);
		}

		const marketSelect = new CustomSelect(
			marketElement,
			CustomSelectType.MultiChoice
		);
		marketSelect.subscribe((markets: Array<string>) => {
			onSelectUpdate(
				markets,
				marketSelect,
				'market',
				marketChipsWrapperElement
			);
		});

		forEach(
			[sortDesktopElement, sortMobileElement],
			(sortElement: HTMLElement) => {
				const sortSelect = new CustomSelect(
					sortElement,
					CustomSelectType.Standard
				);
				sortSelect.subscribe(([type]: [string]) => {
					this.collection.updateSortType(type as SortTypes);
				});
			}
		);

		this.collection.onClearAllFiltersClicked(() => {
			categoryChipsWrapperElement.innerHTML = '';
			marketChipsWrapperElement.innerHTML = '';
			this.filters.market = [];
			this.filters.category = [];
			typeSelect.reset();
			marketSelect.reset();
			UtilsService.removeQueryParams('category');
		});

		this.collection.onModalStateChanged((isOpen: boolean) => {
			typeSelect.emitChanges = isOpen;
			marketSelect.emitChanges = isOpen;
		});

		this.collection.onFilterStateChanged((applyFilters: boolean) => {
			if (applyFilters) {
				typeSelect.applyFilters();
				marketSelect.applyFilters();
			} else {
				typeSelect.restoreLastSavedFilters();
				marketSelect.restoreLastSavedFilters();
			}
		});
	}

	private filterInstruments(): void {
		const clonedInstruments = [...this.initialInstruments];
		const filteredInstruments = filter(
			clonedInstruments,
			(element: HTMLElement) => {
				const queryMatch = !this.query
					? true
					: InstrumentsService.showInstrumentBySymbolName(
							this.query,
							element.dataset.symbol
					  );
				let categoryMatch = true;
				let marketMatch = true;

				if (!isEmpty(this.filters.category)) {
					categoryMatch = includes(
						this.filters.category,
						element.dataset.category
					);
				}

				if (!isEmpty(this.filters.market)) {
					marketMatch = includes(
						this.filters.market,
						element.dataset.market
					);
				}

				return categoryMatch && marketMatch && queryMatch;
			}
		);

		const activeFilters = filter(this.filters, (item) => !isEmpty(item));

		this.collection.updateData(filteredInstruments, activeFilters.length);
	}

	private initReadMore(): void {
		const readMoreBtn = DomService.getElement<HTMLElement>(
			`#read-long-description-btn`
		);
		const longDescription =
			DomService.getElement<HTMLElement>(`#long-description`);

		if (readMoreBtn && longDescription) {
			readMoreBtn.addEventListener('click', (event) => {
				event.preventDefault();
				readMoreBtn.classList.toggle('active');
				longDescription.classList.toggle('hidden');

				if (!readMoreBtn.classList.contains('active')) {
					UtilsService.scrollIntoViewWithOffset(longDescription, 0);
				}
			});
		}
	}

	private initSearch(): void {
		const searchInput = DomService.getElement<HTMLInputElement>(
			'#instrumentsInputSearch'
		);

		if (!searchInput) {
			throw new Error('Missing DOM element');
		}

		const initData = async (): Promise<void> => {
			await InstrumentsService.prepareData();
			this.filterInstruments();
		};

		searchInput.addEventListener('keyup', (event: KeyboardEvent) => {
			this.query = (event.target as HTMLInputElement)?.value
				?.toLowerCase()
				?.trim();

			this.filterInstruments();
		});

		searchInput.addEventListener('click', () => {
			if (!InstrumentsService.areInstrumentsFetched) {
				void initData();
			}
		});
	}
}

DomService.runOnDomReady(() => new InstrumentsSearchPage());
