import { DomService } from '../../services';
import { Slider } from '../slider';

export class TestimonialsCarousel {
	constructor() {
		this.init();
	}

	private init(): void {
		const testimonials = DomService.getElement<HTMLElement>(
			'.section-content--testimonials'
		);

		const rightArrow = DomService.getElement<HTMLElement>(
			'.pagination__item--next-page',
			testimonials
		);
		const leftArrow = DomService.getElement<HTMLElement>(
			'.pagination__item--previous-page',
			testimonials
		);
		const carouselWrapper = DomService.getElement<HTMLElement>(
			'.section-carousel__slider-wrapper'
		);
		const sectionWrapper = DomService.getElement<HTMLElement>(
			'.section-carousel__wrapper'
		);
		const elements = DomService.getElements<HTMLElement>(
			'.section-carousel__main-slide'
		);
		const dots = DomService.getElements<HTMLElement>(
			'.section-carousel__bullet'
		);

		if (
			!rightArrow ||
			!leftArrow ||
			!elements ||
			!dots ||
			!carouselWrapper ||
			!sectionWrapper
		) {
			return;
		}

		leftArrow.classList.remove('pagination__item--disabled');

		const slider = new Slider({
			leftArrow,
			rightArrow,
			carouselWrapper,
			sectionWrapper,
			dots,
			elements
		});
	}
}
