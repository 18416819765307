export class Quote {
	symbol: string;
	ask: number;
	bid: number;
	time: number; // in seconds

	constructor(quote: any) {
		this.symbol = quote.s || quote.i;
		this.ask = quote.a;
		this.bid = quote.b;
		this.time = quote.t;
	}
}
