import { DomService } from '../services';
import { InstrumentPage } from './utils';
import { Chart } from '../modules/chart';

export class HowToPage extends InstrumentPage {
	constructor() {
		super();

		if (
			document.querySelectorAll(
				'body.page-how-to-trade, body.page-how-to-invest-in'
			).length
		) {
			this.initElements();
			this.initBlogCarousel();
			this.initSimilarInstrumentsPagination(3);
			this.initChart();

			setTimeout(() => {
				this.initWebQuotes();
			}, 1000); //  init quotes after 1s to avoid "uninterested" users and not overload Quotes server
		}
	}

	private initChart(): void {
		let symbol;
		const symbolDataElement =
			DomService.getElement<HTMLElement>(`#symbol-data`);

		if (symbolDataElement?.textContent) {
			const instrumentData = JSON.parse(symbolDataElement.textContent);
			symbol = instrumentData?.symbol;
		}

		const chartContainer = DomService.getElement<HTMLElement>(
			'.chart-widget-container'
		);

		if (!chartContainer || !symbol) {
			return;
		}

		this.chart = new Chart(symbol, chartContainer);
	}
}

DomService.runOnDomReady(() => new HowToPage());
