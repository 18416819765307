import { ceil, flow, forEach, inRange, times } from 'lodash-es';

import { CLASS_NAMES, HEADER_HEIGHT } from '../../config';
import { DomService } from '../../services';

export interface ICollectionOptions {
	collection: Array<HTMLElement>;
	pageCount: number;
	contentWrapper?: HTMLElement;
}

const PAGINATION_ITEM_MARGIN = 8;

export abstract class CollectionPagination {
	protected pageIndex: number = 0;
	protected pageCount: number = 10;

	protected collection: Array<HTMLElement>;
	protected paginationPlaceholder: HTMLElement;
	protected elementsNode?: Element;
	protected contentWrapper?: HTMLElement;

	private paginationElements: Array<HTMLElement>;
	private paginationPrevElement: HTMLElement;
	private paginationNextElement: HTMLElement;
	private paginationEllipsis?: Array<HTMLElement>;
	private showEllipsis: boolean = false;
	// Number of available slots for pagination numbers - without 1st and last element
	// This value is higher than 0 if ellipsis is active [showEllipsis]
	private availableSlots: number = 0;

	private readonly scrollOffset: number =
		(DomService.getElement<HTMLElement>('.header')?.offsetHeight ??
			HEADER_HEIGHT) + 10;

	public abstract renderCollection(): void;

	protected initPaginationEvents(): void {
		const paginationPrevElement = DomService.getElement<HTMLElement>(
			'.pagination__item--previous-page',
			this.elementsNode
		);
		const paginationNextElement = DomService.getElement<HTMLElement>(
			'.pagination__item--next-page',
			this.elementsNode
		);

		if (paginationPrevElement && paginationNextElement) {
			this.paginationPrevElement = paginationPrevElement;
			this.paginationNextElement = paginationNextElement;

			this.paginationPrevElement.addEventListener('click', () => {
				this.onPrevElementClick();
			});

			this.paginationNextElement.addEventListener('click', () => {
				this.onNextElementClick();
			});
		}

		this.initPaginationItemsEvents();
	}

	protected renderPagination(): void {
		// Remove previous pagination
		this.paginationPlaceholder.replaceChildren();

		// Create new pagination DOM elements
		times(this.getPaginationItems(), (i: number) => {
			const classNames = ['pagination__item', 'pagination__item--number'];

			if (i === 0) {
				classNames.push('pagination__item--active');
			}

			const liElement = DomService.createElement<HTMLLIElement>({
				tag: 'li',
				classNames,
				template: i + 1
			});
			this.paginationPlaceholder.appendChild(liElement);
		});

		// Add ellipsis DOM elements to pagination
		times(2, () => {
			const liElement = DomService.createElement<HTMLLIElement>({
				tag: 'li',
				classNames: ['pagination__item', 'pagination__item--ellipsis'],
				template: '...',
				attributes: {
					hidden: true
				}
			});
			this.paginationPlaceholder.appendChild(liElement);
		});

		this.initPaginationItemsEvents();
	}

	protected updatePagination(): void {
		if (this.showEllipsis && this.paginationEllipsis) {
			if (
				this.pageIndex === 0 ||
				this.pageIndex === this.paginationElements.length - 1
			) {
				this.paginationEllipsis[0].hidden = true;
				this.paginationEllipsis[1].hidden = false;
			}

			const halfRange = Math.floor(this.availableSlots / 2);
			const startIndex = Math.max(0, this.pageIndex - halfRange);
			const endIndex = this.getEndIndexForEllipsis();

			forEach(
				this.paginationElements,
				(element: HTMLElement, index: number) => {
					element.classList.remove(CLASS_NAMES.paginationActive);

					if (
						index > 0 &&
						index < this.paginationElements.length - 1
					) {
						element.hidden = !inRange(
							index,
							startIndex + 1,
							endIndex
						);
					}
				}
			);

			this.paginationEllipsis[0].hidden =
				!this.paginationElements[1].hidden;
			this.paginationEllipsis[1].hidden =
				!this.paginationElements[this.paginationElements.length - 2]
					.hidden;
		} else {
			forEach(this.paginationElements, (element: HTMLElement) => {
				element.classList.remove(CLASS_NAMES.paginationActive);
			});
		}

		if (this.paginationElements[this.pageIndex]) {
			this.paginationElements[this.pageIndex].classList.add(
				CLASS_NAMES.paginationActive
			);
		}

		if (this.pageIndex !== 0) {
			this.paginationPrevElement.classList.remove(
				CLASS_NAMES.paginationDisabled
			);
		} else {
			this.paginationPrevElement.classList.add(
				CLASS_NAMES.paginationDisabled
			);
		}

		if (this.pageIndex + 1 >= this.getPaginationItems()) {
			this.paginationNextElement.classList.add(
				CLASS_NAMES.paginationDisabled
			);
		} else {
			this.paginationNextElement.classList.remove(
				CLASS_NAMES.paginationDisabled
			);
		}

		const hideOnNoResults = !this.getPaginationItems();
		this.paginationPrevElement.hidden = hideOnNoResults;
		this.paginationNextElement.hidden = hideOnNoResults;
	}

	private getPaginationItems(): number {
		return Math.ceil(this.collection.length / this.pageCount);
	}

	private initPaginationItemsEvents(): void {
		const paginationElements = DomService.getElements<HTMLElement>(
			'.pagination__item--number',
			this.elementsNode
		);
		const paginationWrapper = DomService.getElement<HTMLElement>(
			'.pagination__wrapper',
			this.elementsNode
		);

		if (!paginationElements?.length || !paginationWrapper) {
			return;
		}

		this.paginationElements = Array.from(paginationElements);

		const leftArrow = paginationWrapper.children[0];
		const rightArrow = paginationWrapper.children[2];
		const availableSpace =
			paginationWrapper.clientWidth -
			leftArrow.clientWidth -
			rightArrow.clientWidth -
			PAGINATION_ITEM_MARGIN * 4;
		this.availableSlots = flow(
			(value: number) => ceil(value) - 2, // subtract slot for 1st & last element
			(value: number) => (!(value & 1) ? value + 1 : value)
		)(
			availableSpace /
				(this.paginationElements[0].clientWidth +
					PAGINATION_ITEM_MARGIN)
		);

		this.showEllipsis =
			this.paginationPlaceholder.clientWidth > availableSpace &&
			this.paginationElements.length > 3;
		const endIndex = this.showEllipsis ? this.getEndIndexForEllipsis() : 0;

		if (this.showEllipsis) {
			this.paginationEllipsis = DomService.getElements<HTMLElement>(
				'.pagination__item--ellipsis',
				this.elementsNode
			);

			if (
				this.paginationEllipsis &&
				this.paginationEllipsis.length === 2
			) {
				this.paginationEllipsis[1].hidden = false;
				this.paginationEllipsis[1].style.order = `${
					(this.paginationElements.length - 2) * 2
				}`;
				this.paginationEllipsis[0].style.order = '1';
			} else {
				this.showEllipsis = false;
			}
		}

		forEach(
			this.paginationElements,
			(element: HTMLElement, index: number) => {
				if (this.showEllipsis) {
					// Set order value to index * 2 to leave a slot for the ellipsis movement
					element.style.order = `${index * 2}`;
					let hidden: boolean;

					if (
						index === 0 ||
						index === this.paginationElements.length - 1
					) {
						// Show 1st & last element
						hidden = false;
					} else {
						hidden = !inRange(index, 0, endIndex + 1);
					}

					element.hidden = hidden;
				}

				element.addEventListener('click', () => {
					const newPageIndex = element.innerText;

					this.pageIndex = parseFloat(newPageIndex) - 1;
					this.renderCollection();
					this.updatePagination();
					this.onPaginationChange();
				});
			}
		);
	}

	private onPrevElementClick(): void {
		if (this.pageIndex === 0) {
			return;
		}

		this.pageIndex--;
		this.renderCollection();
		this.updatePagination();
		this.onPaginationChange();
	}

	private onNextElementClick(): void {
		if (this.pageIndex + 1 >= this.getPaginationItems()) {
			return;
		}

		this.pageIndex++;
		this.renderCollection();
		this.updatePagination();
		this.onPaginationChange();
	}

	private onPaginationChange(): void {
		if (!this.contentWrapper) {
			return;
		}

		const { top } = this.contentWrapper.getBoundingClientRect();

		if (top < this.scrollOffset) {
			window.scrollTo({
				top: window.scrollY + top - this.scrollOffset,
				behavior: 'smooth'
			});
		}
	}

	// Return end index for last visible items (except last element) is ellipsis is active
	private getEndIndexForEllipsis(): number {
		const halfRange = Math.floor(this.availableSlots / 2);

		return Math.min(
			this.paginationElements.length - 1,
			this.pageIndex + halfRange
		);
	}
}
