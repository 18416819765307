import { forEach } from 'lodash-es';

import { CLASS_NAMES } from '../../config';
import { DomService } from '../../services';

export class Pagination {
	constructor(
		private readonly elementsSelector: string,
		private readonly paginationNode?: HTMLElement | Element,
		private readonly elementsNode?: HTMLElement | Element,
		private readonly pageCount: number = 5
	) {
		this.init();
	}

	private init(): void {
		const prevElement = DomService.getElement<HTMLElement>(
			'.pagination__item--previous-page',
			this.paginationNode
		);
		const nextElement = DomService.getElement<HTMLElement>(
			'.pagination__item--next-page',
			this.paginationNode
		);
		const items = DomService.getElements<HTMLElement>(
			this.elementsSelector,
			this.elementsNode
		);
		let activePageIndex = 0;

		if (!items || !prevElement || !nextElement) {
			return;
		}

		prevElement.addEventListener('click', () => {
			if (activePageIndex === 0) {
				return;
			}

			if (
				activePageIndex * this.pageCount + this.pageCount >=
				items.length
			) {
				this.toggleItemDisable(nextElement);
			}

			for (let i = 0; i < this.pageCount; i++) {
				const elementToHideIndex = activePageIndex * this.pageCount + i;
				if (elementToHideIndex < items.length) {
					items[elementToHideIndex].classList.add(
						CLASS_NAMES.paginationDisplayNone
					);
				}

				items[
					activePageIndex * this.pageCount - this.pageCount + i
				].classList.remove(CLASS_NAMES.paginationDisplayNone);
			}
			activePageIndex--;

			if (activePageIndex === 0) {
				this.toggleItemDisable(prevElement);
			}
		});

		nextElement.addEventListener('click', () => {
			const currentStartIndex =
				activePageIndex * this.pageCount + this.pageCount;

			if (currentStartIndex >= items.length) {
				return;
			}

			for (let i = 0; i < this.pageCount; i++) {
				items[activePageIndex * this.pageCount + i].classList.add(
					CLASS_NAMES.paginationDisplayNone
				);

				const nextElementIndex =
					activePageIndex * this.pageCount + this.pageCount + i;
				if (nextElementIndex < items.length) {
					items[nextElementIndex].classList.remove(
						CLASS_NAMES.paginationDisplayNone
					);
				}
			}
			activePageIndex++;

			if (activePageIndex === 1) {
				this.toggleItemDisable(prevElement);
			}

			if (
				activePageIndex * this.pageCount + this.pageCount >=
				items.length
			) {
				this.toggleItemDisable(nextElement);
			}
		});

		forEach(Array.from(items), (element: HTMLLIElement, index: number) => {
			const page = Math.ceil((index + 1) / this.pageCount);

			if (page > 1) {
				element.classList.add(CLASS_NAMES.paginationDisplayNone);
			}
		});
	}

	private toggleItemDisable(element: HTMLElement): void {
		element.classList.toggle(CLASS_NAMES.paginationDisabled);
	}
}
