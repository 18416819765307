import { StorageService } from './storage.service';

export class SessionStorageService extends StorageService {
	public static instance: SessionStorageService;

	public init(): void {
		this.isStorageSupported = this.getIsStorageSupported();

		if (this.isStorageSupported) {
			this.storageAPI = sessionStorage;
		}
	}

	public getIsStorageSupported(): boolean {
		try {
			sessionStorage.setItem(this.testKey, this.testKey);
			sessionStorage.removeItem(this.testKey);
			return true;
		} catch (e) {
			return false;
		}
	}

	public static getInstance(): SessionStorageService {
		SessionStorageService.instance = new SessionStorageService();
		return SessionStorageService.instance;
	}
}
