import { mapValues } from 'lodash-es';

const changeSFXDomainToAlternativeDomain = (
	text: string,
	alternativeDomain: string
): string => {
	return text.replace('simplefx.com', (match, p1, p2) => {
		// ignore email e.g. support@simplefx.com
		if (p1 > 0 && p2[p1 - 1] === '@') {
			return match;
		}

		return alternativeDomain;
	});
};

export let API = DEFINED_API;
const hostname = window.location.hostname.replace('develop.', '');

if (!hostname.includes('simplefx.com') && !hostname.includes('localhost')) {
	API = mapValues(DEFINED_API, (item: string): string => {
		return changeSFXDomainToAlternativeDomain(item, hostname);
	});
}
