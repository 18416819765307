import { clone, forEach, head, isEmpty, isEqual, pull } from 'lodash-es';

import { DomService } from '../../services';

export enum CustomSelectType {
	Standard,
	MultiChoice,
	Radio
}

export class CustomSelect {
	private selectValueElement: HTMLDivElement;
	private selectTextElement: HTMLDivElement;
	private initialSelectText: string;
	private selectWrapperElement: HTMLDivElement;
	private listItems: Array<HTMLDivElement> = [];
	private activeItems: Array<string> = [];
	private lastSavedActiveItems: Array<string> = [];
	private subscriber: (value: string | Array<string>) => void;
	public emitChanges: boolean = true;

	constructor(
		public readonly element?: HTMLElement,
		private readonly type: CustomSelectType = CustomSelectType.Standard
	) {
		setTimeout(() => {
			this.init();
		}, 0);
	}

	private init(): void {
		const selectWrapperElement = DomService.getElement<HTMLDivElement>(
			'.custom-select__wrapper',
			this.element
		);
		const selectValueElement = DomService.getElement<HTMLDivElement>(
			'.custom-select__value',
			this.element
		);
		const selectTextElement = DomService.getElement<HTMLDivElement>(
			'.custom-select__value span',
			this.element
		);
		const searchElement = DomService.getElement<HTMLInputElement>(
			'input.custom-select__search',
			this.element
		);
		const noResultElement = DomService.getElement<HTMLElement>(
			'.custom-select__no-result',
			this.element
		);

		if (
			!selectWrapperElement ||
			!selectValueElement ||
			!selectTextElement
		) {
			return;
		}

		this.selectWrapperElement = selectWrapperElement;
		this.selectValueElement = selectValueElement;
		this.selectTextElement = selectTextElement;
		this.initialSelectText = selectTextElement.innerText;

		const listItems = DomService.getElements<HTMLDivElement>(
			'.custom-select__list-item',
			this.selectWrapperElement
		);

		const changeListItemListenerFn = (
			element: HTMLElement,
			event: PointerEvent
		): void => {
			const itemValue = element.dataset.value ?? element.innerHTML;

			if (this.type === CustomSelectType.MultiChoice) {
				const checkbox = DomService.getElement<HTMLInputElement>(
					'input[type="checkbox"]',
					element
				);
				const target = event.target as HTMLElement;

				if (checkbox && target?.nodeName.toLowerCase() === 'input') {
					checkbox.checked
						? this.activeItems.push(itemValue)
						: pull(this.activeItems, itemValue);
				}
			} else if (!this.emitChanges) {
				this.activeItems = [itemValue];

				if (this.type === CustomSelectType.Standard) {
					forEach(this.listItems, (listElement: HTMLElement) =>
						listElement.classList.remove('active')
					);

					if (this.activeItems.includes(itemValue)) {
						element.classList.add('active');
					}
				}
			} else {
				const firstActiveItem = head(this.activeItems);
				if (firstActiveItem !== itemValue && this.subscriber) {
					this.subscriber([itemValue]);
				}

				this.activeItems = [itemValue];
				this.selectWrapperElement.classList.remove('open');
				this.selectTextElement.innerHTML =
					this.type === CustomSelectType.Radio
						? element.innerText
						: element.innerHTML;

				if (this.type === CustomSelectType.Standard) {
					forEach(this.listItems, (listElement: HTMLElement) =>
						listElement.classList.remove('active')
					);

					if (this.activeItems.includes(itemValue)) {
						element.classList.add('active');
					}
				}
			}
		};

		if (!listItems) {
			return;
		}

		this.listItems = listItems;
		this.listItems.forEach((element: HTMLElement) => {
			element.addEventListener('click', (event: PointerEvent) => {
				changeListItemListenerFn(element, event);
			});
		});

		document.addEventListener('click', (event: MouseEvent) => {
			if (!this.selectWrapperElement.contains(event.target as Node)) {
				this.selectWrapperElement.classList.remove('open');

				if (searchElement) {
					searchElement.value = '';
					forEach(this.listItems, (element: HTMLElement) => {
						element.hidden = false;
					});

					if (noResultElement) {
						noResultElement.hidden = true;
					}
				}
			}
		});

		this.selectValueElement.addEventListener('click', () => {
			this.selectWrapperElement.classList.toggle('open');
		});

		if (this.type === CustomSelectType.MultiChoice) {
			const btnElement = DomService.getElement<HTMLDivElement>(
				'.btn',
				this.element
			);

			if (btnElement) {
				btnElement.addEventListener('click', (event: MouseEvent) => {
					this.subscriber(this.activeItems);
					this.selectWrapperElement.classList.remove('open');
				});
			}
		}
	}

	public subscribe(
		subscriber: (value: string | Array<string>) => void
	): void {
		this.subscriber = subscriber;
	}

	public reset(): void {
		this.activeItems = [];
		this.selectTextElement.innerText = this.initialSelectText;

		this.listItems.forEach((element: HTMLElement) => {
			const input = DomService.getElement<HTMLInputElement>(
				'input',
				element
			);
			if (input) {
				input.checked = false;
			}
		});
	}

	public updateItems(items: Array<string>, text?: string): void {
		this.activeItems = clone(items);
		this.lastSavedActiveItems = clone(items);

		if (text) {
			this.selectTextElement.innerHTML = text;
		}

		this.listItems.forEach((element: HTMLElement) => {
			const input = DomService.getElement<HTMLInputElement>(
				'input',
				element
			);
			const itemValue =
				(this.type === CustomSelectType.Radio ? input?.value : null) ??
				element.dataset.value ??
				element.innerHTML;
			const isChecked = this.activeItems.includes(itemValue);

			if (input) {
				input.checked = isChecked;

				if (isChecked && this.type === CustomSelectType.Radio) {
					this.selectTextElement.innerHTML = element.innerText;
				}
			} else if (this.type === CustomSelectType.Standard) {
				if (this.activeItems.includes(itemValue)) {
					element.classList.add('active');
				} else {
					element.classList.remove('active');
				}
			}
		});
	}

	public applyFilters(): void {
		if (isEmpty(this.activeItems)) {
			return;
		}

		this.updateItems(this.activeItems);

		this.subscriber(this.activeItems);
		this.selectWrapperElement.classList.remove('open');
	}

	public restoreLastSavedFilters(): void {
		if (isEqual(this.lastSavedActiveItems, this.activeItems)) {
			return;
		}

		this.activeItems = clone(this.lastSavedActiveItems);
		this.updateItems(this.activeItems);

		this.selectWrapperElement.classList.remove('open');
	}
}
