import { forEach } from 'lodash-es';

import { Tabs, VideoPlayerControls } from '../modules';
import { DomService, UtilsService } from '../services';

export class PlatformsPage extends VideoPlayerControls {
	constructor() {
		super();

		if (document.querySelector('body.page-platforms')) {
			this.init();
			this.initPlayerControls(`.platform__video-player`);
			this.detectWrappedButtons();
		}
	}

	private init(): void {
		const tabs = new Tabs();
	}

	private isWrapped(
		prevElement: HTMLAnchorElement,
		nextElement: HTMLAnchorElement
	): boolean {
		return (
			prevElement.getBoundingClientRect().top <
			nextElement.getBoundingClientRect().top
		);
	}

	private detectWrappedButtons(): void {
		const buttonElements = DomService.getElements<HTMLAnchorElement>(
			'.section-top__buttons .btn'
		);

		if (!buttonElements || UtilsService.isMobile()) {
			return;
		}

		forEach(buttonElements, (element: HTMLAnchorElement, index: number) => {
			const prevElement = buttonElements[index - 1];

			if (prevElement && this.isWrapped(prevElement, element)) {
				element.parentElement?.classList.add('wrapped');
				return false;
			}
		});
	}
}

DomService.runOnDomReady(() => new PlatformsPage());
