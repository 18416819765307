import { DomService, StorageService } from '../services';

export class ZeroFees {
	private readonly storageService: StorageService =
		StorageService.getInstance();

	constructor() {
		if (document.querySelector('body.page-zero-fees')) {
			this.init();
		}
	}

	private init(): void {
		this.storageService.set('zero-fees', 'true');
	}
}

DomService.runOnDomReady(() => new ZeroFees());
