import { forEach } from 'lodash-es';

import { DomService } from '../../services';

export class Lightbox {
	constructor(private readonly itemsSelector: string) {
		this.init();
	}

	private init(): void {
		const items = DomService.getElements<HTMLImageElement>(
			this.itemsSelector
		);
		const lightboxWrapper =
			DomService.getElement<HTMLDivElement>('.lightbox');
		const imgPlaceholder = DomService.getElement<HTMLImageElement>(
			'img',
			lightboxWrapper
		);
		const closeButton =
			DomService.getElement<HTMLDivElement>('.lightbox__close');

		if (
			!closeButton ||
			!lightboxWrapper ||
			!imgPlaceholder ||
			!items?.length
		) {
			return;
		}

		closeButton.addEventListener('click', () => {
			lightboxWrapper.classList.remove('active');
		});

		forEach(items, (item: HTMLImageElement) => {
			item.addEventListener('click', (event: MouseEvent) => {
				imgPlaceholder.src = item.src;
				lightboxWrapper.classList.add('active');
			});
		});
	}
}
