import { DomService } from '../services';
import { InstrumentsCollectionQuotes } from '../modules';

export class MetalsPage {
	constructor() {
		if (document.querySelector('body.page-offer-metals')) {
			this.initInstrumentsTable();
		}
	}

	private initInstrumentsTable(): void {
		const instrumentsCollectionQuotes = new InstrumentsCollectionQuotes();
	}
}

DomService.runOnDomReady(() => new MetalsPage());
