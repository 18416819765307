import { find, forEach } from 'lodash-es';

import { DeviceEnum } from '../enums';
import { DomService } from './dom.service';
import { UtilsService } from './utils.service';

export class TableOfContentService {
	private readonly isMobile: boolean;
	private readonly scrollOffset: number = 20;
	private readonly tableOfContent: HTMLElement;
	private readonly documentContent: HTMLElement;
	private linksElements: Array<HTMLElement> = [];

	constructor() {
		const tableOfContent =
			DomService.getElement<HTMLElement>(`.table-of-content`);
		const documentContent =
			DomService.getElement<HTMLElement>(`.document-content`);

		this.isMobile = [
			DeviceEnum.Mobile,
			DeviceEnum.LargeMobile,
			DeviceEnum.Tablet
		].includes(UtilsService.getDevice());

		if (documentContent && tableOfContent && !this.isMobile) {
			this.tableOfContent = tableOfContent;
			this.documentContent = documentContent;
			this.init();
		}
	}

	private init(): void {
		const sectionElements = DomService.getElements<HTMLElement>(
			'h2',
			this.documentContent
		);
		const linksElements = DomService.getElements<HTMLElement>(
			'a',
			this.tableOfContent
		);

		if (linksElements) {
			this.linksElements = linksElements;
			this.initLinksListener();
		}

		if (sectionElements?.length) {
			this.initScrollListenerForDocumentSections(sectionElements);
		}
	}

	private initLinksListener(): void {
		forEach(this.linksElements, (element: HTMLElement) => {
			element.addEventListener('click', (event: MouseEvent) => {
				event.preventDefault();

				const targetId = element.dataset.forid;
				const targetElement = DomService.getElement<HTMLElement>(
					`#${targetId}`,
					this.documentContent
				);

				if (targetElement) {
					UtilsService.scrollIntoViewWithOffset(
						targetElement,
						this.scrollOffset
					);
				}
			});
		});
	}

	private initScrollListenerForDocumentSections(
		sectionElements: Array<HTMLElement>
	): void {
		window.addEventListener('scroll', () => {
			const [visibleSection, sectionIndex] =
				this.getVisibleElement(sectionElements);

			if (!visibleSection) {
				return;
			}

			forEach(this.linksElements, (element: Element) =>
				element.classList.remove('active')
			);

			this.markTitleAsActive(sectionIndex);
		});
	}

	private markTitleAsActive(index: number): void {
		if (!this.linksElements[index]) {
			return;
		}

		this.linksElements[index].classList.add('active');
	}

	private getVisibleElement(
		collection: Array<HTMLElement>
	): [HTMLElement | undefined, number] {
		let elementIndex: number = 0;
		const visibleElement = find(
			collection,
			(element: HTMLElement, index: number) => {
				const isInViewPort = this.isElementInViewPort(element);

				if (!isInViewPort) {
					return false;
				}

				if (!index && isInViewPort) {
					// First element in the view port
					return true;
				}

				if (isInViewPort) {
					elementIndex = index;
				}

				return isInViewPort;
			}
		);

		return [visibleElement, elementIndex];
	}

	private isElementInViewPort(element: Element): boolean {
		const elementRect = element.getBoundingClientRect();
		const startPositionY = elementRect.y;
		const endPositionY = elementRect.y + elementRect.height;

		return (
			(startPositionY > 0 &&
				startPositionY < window.innerHeight * 0.75) ||
			(endPositionY > 0 && endPositionY < window.innerHeight) ||
			(startPositionY < 0 && endPositionY > window.innerHeight)
		);
	}
}
