import { debounce, forEach, includes, isNumber, toNumber } from 'lodash-es';

import { CLASS_NAMES } from '../../config';
import { DomService } from '../../services';
import {
	CollectionPagination,
	type ICollectionOptions
} from './collection-pagination';

export enum SortTypes {
	NameAsc = 'asc',
	NameDesc = 'desc',
	Newest = 'newest',
	PopularDescInstruments = 'popularDescInstruments'
}

export interface ICollectionFiltersOptions extends ICollectionOptions {
	sortField?: string;
	initialSortType?: SortTypes;
}

export class CollectionFilters extends CollectionPagination {
	private initialCollection: Array<HTMLElement> = [];
	private clearAllElement: HTMLElement;
	private filterCountElement?: HTMLElement;
	private collectionResultElement: HTMLElement;
	private noDataElement: HTMLElement;
	private clearAllFiltersSubscriber: () => void;
	private afterCollectionRenderedSubscriber: () => void;
	private modalStateChangedSubscriber: (isOpen: boolean) => void;
	private filterStateChangedSubscriber: (applyFilters: boolean) => void;

	private readonly options: {
		sortField: string;
	} = {
		sortField: 'symbol'
	};

	private searchValueText: string = '';
	private sortType: SortTypes = SortTypes.NameAsc;

	constructor(options: ICollectionFiltersOptions) {
		super();

		this.pageCount = options.pageCount;
		this.contentWrapper = options.contentWrapper;

		if (options.sortField) {
			this.options.sortField = options.sortField;
		}

		this.sortType = options.initialSortType ?? this.sortType;

		this.init(options.collection);
	}

	private init(collection: Array<HTMLElement>): void {
		const paginationPlaceholder = DomService.getElement<HTMLElement>(
			'.pagination__items-placeholder'
		);
		const clearAllElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-clear-all'
		);
		const collectionResultElement = DomService.getElement<HTMLElement>(
			'.collection-filters__results-number'
		);
		const noDataElement = DomService.getElement<HTMLElement>(
			'.collection-filters__no-data'
		);

		if (
			!paginationPlaceholder ||
			!clearAllElement ||
			!collectionResultElement ||
			!noDataElement
		) {
			return;
		}

		this.initialCollection = collection;
		this.collection = [...this.initialCollection];
		this.paginationPlaceholder = paginationPlaceholder;
		this.clearAllElement = clearAllElement;
		this.collectionResultElement = collectionResultElement;
		this.noDataElement = noDataElement;

		this.initSearch();
		this.initPaginationEvents();
		this.initClearAllBtnEvents();
		this.initMobileFiltersModal();
	}

	private initMobileFiltersModal(): void {
		const filterButtonElement = DomService.getElement<HTMLButtonElement>(
			'.collection-filters__filter-btn'
		);
		this.filterCountElement = DomService.getElement<HTMLButtonElement>(
			'span',
			filterButtonElement
		);
		const filterModalWrapperElement =
			DomService.getElement<HTMLButtonElement>(
				'.collection-filters__modal'
			);
		const filterModalContentElement = DomService.getElement<HTMLElement>(
			'.collection-filters__modal-content'
		);
		const filterModalCloseButtonElement =
			DomService.getElement<HTMLElement>(
				'.collection-filters__modal-close'
			);
		const modalFilterButtonElement = DomService.getElement<HTMLElement>(
			'.collection-filters__modal-btn-wrapper .btn'
		);

		if (
			!filterButtonElement ||
			!filterModalWrapperElement ||
			!filterModalCloseButtonElement ||
			!modalFilterButtonElement ||
			!filterModalContentElement
		) {
			return;
		}

		const closeModal = (): void => {
			filterModalWrapperElement.classList.remove(
				'collection-filters__modal--open'
			);
			DomService.enableBodyScroll();

			if (this.modalStateChangedSubscriber) {
				this.modalStateChangedSubscriber(true);
			}
		};

		filterButtonElement.addEventListener('click', () => {
			filterModalWrapperElement.classList.add(
				'collection-filters__modal--open'
			);
			filterModalContentElement.scrollTo(0, 0);

			DomService.disableBodyScroll();

			if (this.modalStateChangedSubscriber) {
				this.modalStateChangedSubscriber(false);
			}
		});

		filterModalCloseButtonElement.addEventListener('click', () => {
			if (this.filterStateChangedSubscriber) {
				this.filterStateChangedSubscriber(false);
			}

			closeModal();
		});

		modalFilterButtonElement.addEventListener('click', () => {
			if (this.filterStateChangedSubscriber) {
				this.filterStateChangedSubscriber(true);
			}

			closeModal();
		});
	}

	private initSearch(): void {
		const collectionWrapper = DomService.getElement<HTMLInputElement>(
			'.collection-filters'
		);
		const searchInputElement =
			DomService.getElement<HTMLInputElement>('#inputSearch');
		const closeInputElement = DomService.getElement<HTMLElement>(
			'.form__icon-close',
			collectionWrapper
		);

		const onInputSearch = (event: KeyboardEvent): void => {
			this.searchValueText = (
				event.target as HTMLInputElement
			)?.value.toLowerCase();

			if (closeInputElement) {
				if (this.searchValueText.length) {
					closeInputElement.classList.add(
						CLASS_NAMES.inputIconActive
					);
				} else {
					closeInputElement.classList.remove(
						CLASS_NAMES.inputIconActive
					);
				}
			}

			this.updateData([...this.initialCollection]);
		};

		const debounceHandler = debounce(onInputSearch, 400);

		if (searchInputElement) {
			searchInputElement.addEventListener('keyup', debounceHandler);
		}

		if (closeInputElement && searchInputElement) {
			closeInputElement.addEventListener('click', () => {
				searchInputElement.value = '';
				searchInputElement.dispatchEvent(new KeyboardEvent('keyup'));
			});
		}
	}

	private initClearAllBtnEvents(): void {
		this.clearAllElement.addEventListener('click', () => {
			this.clearAllElement.classList.remove('active');

			this.updateData([...this.initialCollection], 0);

			if (this.clearAllFiltersSubscriber) {
				this.clearAllFiltersSubscriber();
			}
		});
	}

	private readonly compareAsc = (a: HTMLElement, b: HTMLElement): any =>
		(a.dataset[this.options.sortField] as string).localeCompare(
			b.dataset[this.options.sortField] as string
		);

	private sortInstruments(items: Array<HTMLElement>): Array<HTMLElement> {
		if (!items.length || items.length === 1) {
			return items;
		}

		return items.sort(this.compareAsc);
	}

	private searchItems(items: Array<HTMLElement>): Array<HTMLElement> {
		if (!this.searchValueText) {
			return items;
		}

		const searchedItems = [];
		let i = items.length;

		while (i--) {
			const item = items[i];

			if (
				item.innerText
					.toLowerCase()
					.includes(this.searchValueText.toLowerCase())
			) {
				searchedItems.unshift(item);
			}
		}

		return searchedItems;
	}

	public onClearAllFiltersClicked(subscriber: () => void): void {
		this.clearAllFiltersSubscriber = subscriber;
	}

	public onAfterCollectionRendered(subscriber: () => void): void {
		this.afterCollectionRenderedSubscriber = subscriber;
	}

	public onModalStateChanged(subscriber: (isOpen: boolean) => void): void {
		this.modalStateChangedSubscriber = subscriber;
	}

	public onFilterStateChanged(
		subscriber: (applyFilters: boolean) => void
	): void {
		this.filterStateChangedSubscriber = subscriber;
	}

	public renderCollection(): void {
		const slicedItems = this.collection.slice(
			this.pageIndex * this.pageCount,
			this.pageIndex * this.pageCount + this.pageCount
		);

		forEach(this.initialCollection, (element: HTMLElement) => {
			if (includes(slicedItems, element)) {
				element.classList.remove(CLASS_NAMES.displayNone);
			} else {
				element.classList.add(CLASS_NAMES.displayNone);
			}
		});

		if (this.afterCollectionRenderedSubscriber) {
			this.afterCollectionRenderedSubscriber();
		}
	}

	public updateSortType(type: SortTypes): void {
		this.sortType = type;

		const sortBySymbolName = (a?: string, b?: string): number => {
			if (!a || !b) {
				return 0;
			}

			return a.localeCompare(b);
		};

		switch (type) {
			case SortTypes.NameAsc:
				this.collection = this.sortInstruments([...this.collection]);
				break;

			case SortTypes.NameDesc:
				this.collection = this.sortInstruments([
					...this.collection
				]).reverse();
				break;

			case SortTypes.Newest:
				this.collection = [...this.collection].sort(
					(a: HTMLElement, b: HTMLElement) => {
						const dateA = toNumber(a.dataset.date) ?? 0;
						const dateB = toNumber(b.dataset.date) ?? 0;

						const result = dateB - dateA;

						if (result === 0) {
							return sortBySymbolName(
								a.dataset.symbol,
								b.dataset.symbol
							);
						}

						return result;
					}
				);
				break;

			case SortTypes.PopularDescInstruments:
				this.collection = [...this.collection].sort(
					(a: HTMLElement, b: HTMLElement) => {
						// sort by popularity
						const popularityA = toNumber(a.dataset.popularity) ?? 0;
						const popularityB = toNumber(b.dataset.popularity) ?? 0;

						if (!popularityA && !popularityB) {
							// elements without popularity sort by hot flag
							const isHotA = toNumber(a.dataset.isHot) === 1;
							const isHotB = toNumber(b.dataset.isHot) === 1;

							if (isHotA && !isHotB) {
								return -1;
							}

							if (!isHotA && isHotB) {
								return 1;
							}

							// elements without popularity and hot flag sort by name
							return sortBySymbolName(
								a.dataset.symbol,
								b.dataset.symbol
							);
						}

						if (!popularityA) {
							return 1;
						}

						if (!popularityB) {
							return -1;
						}

						return popularityA - popularityB;
					}
				);
		}

		forEach(this.collection, (element: HTMLElement) => {
			// Use appendChild to change DOM elements' order
			this.contentWrapper?.appendChild(element);
		});

		this.renderCollection();
	}

	public updateData(
		collection: Array<HTMLElement>,
		activeFiltersLength?: number
	): void {
		this.pageIndex = 0;

		this.collection = this.searchItems(collection);

		// Sort updated data
		this.updateSortType(this.sortType);
		this.renderPagination();
		this.updatePagination();

		if (isNumber(activeFiltersLength)) {
			const clearAllClassList = this.clearAllElement.classList;
			activeFiltersLength
				? clearAllClassList.add('active')
				: clearAllClassList.remove('active');

			if (this.filterCountElement) {
				this.filterCountElement.innerText = activeFiltersLength
					? `(${activeFiltersLength})`
					: '';
			}
		}

		if (this.collectionResultElement) {
			this.collectionResultElement.innerText =
				this.collection.length.toString();
		}

		if (this.noDataElement) {
			this.noDataElement.hidden = !!this.collection.length;
		}
	}
}
