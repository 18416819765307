import { has, forEach } from 'lodash-es';
import QRCode from 'qrcode';

import { API, STORAGE_KEYS, USERSNAP_COUNTRIES } from './config';
import { DeviceEnum } from './enums';
import {
	AnalyticsService,
	CookieService,
	CountriesService,
	DomService,
	SessionStorageService,
	StorageService,
	UtilsService
} from './services';
import { type ICountry } from './models';
import { SignUp } from './modules';

import '../styles/styles.scss';

import './pages/about';
import './pages/affiliate';
import './pages/bitcoin-lightning-network';
import './pages/commodities';
import './pages/connect-for-business';
import './pages/crypto';
import './pages/education';
import './pages/equities';
import './pages/forex';
import './pages/faq';
import './pages/help-center';
import './pages/home';
import './pages/how-to';
import './pages/indices';
import './pages/instruments';
import './pages/instrument-single-item';
import './pages/mobile-app';
import './pages/offer';
import './pages/payments';
import './pages/platforms';
import './pages/metals';
import './pages/single-tutorial';
import './pages/status';
import './pages/trading-glossary';
import './pages/web-trader';
import './pages/zero-fees';
import { URLS } from '../lambda/utils';

class App {
	private readonly utilsService: UtilsService = UtilsService.getInstance();
	private readonly storageService: StorageService =
		StorageService.getInstance();

	private readonly sessionStorageService: SessionStorageService =
		SessionStorageService.getInstance();

	private readonly cookieService: CookieService = CookieService.getInstance();
	private country: ICountry;
	private usersnapApi: UsersnapApi;
	private usersnapWidgetShown: boolean = false;

	public queryString: string;

	constructor() {
		this.initFooter();
		this.initUsersnapApi();
		void this.initCountry();
		this.initQueryParams();
		this.initMenu();
		this.initHeaderDisclaimer();
		this.initScrollToTop();
		this.initFloatingButtons();
		this.initCookies();
		this.initPromoCode();
		this.initLanguageDropdown();
		this.initCollapsibleElements();
		this.initSectionsAnimation();
		this.initStoreButtons();
		this.addSmoothScrollBehavior();
		this.updateLinksToMobileAppStore();
		AnalyticsService.initAnalyticsCommonEvents();
	}

	private initFooter(): void {
		const footerIframe =
			DomService.getElement<HTMLIFrameElement>('#footer-iframe');

		if (footerIframe) {
			window.addEventListener(
				'message',
				(event) => {
					if (event.data && has(event.data, 'height')) {
						footerIframe.height = `${event.data.height}px`;
					}
				},
				false
			);

			window.addEventListener('resize', () => {
				footerIframe.height = `${footerIframe.contentDocument?.body.scrollHeight}px`;
			});
		}
	}

	private initUsersnapApi(): void {
		window.onUsersnapLoad = (api: UsersnapApi) => {
			this.usersnapApi = api;
			this.showUsersnapWidget();
		};
	}

	private showUsersnapWidget(): void {
		if (
			!this.usersnapWidgetShown &&
			this.usersnapApi &&
			this.country &&
			USERSNAP_COUNTRIES.includes(this.country.countryCode)
		) {
			void this.usersnapApi.init();
			this.usersnapWidgetShown = true;
		}
	}

	private async initCountry(): Promise<void> {
		const body = DomService.getElement<HTMLElement>('body');
		const registerForm = DomService.getElement<HTMLElement>(
			'.section-cta-footer__form'
		);
		const registerSocialIcons = DomService.getElement<HTMLElement>(
			'.section-cta-footer__social-icons-wrapper'
		);
		const countryCustomModal = DomService.getElement<HTMLElement>(
			'#countryCustomModal'
		);
		const descriptionCustomModal = DomService.getElement<HTMLElement>(
			'.modal__description',
			countryCustomModal
		);
		const continueButtonModal = DomService.getElement<HTMLElement>(
			'button',
			countryCustomModal
		);
		const signupBox = DomService.getElement<HTMLElement>(
			'.single-article__box--signup'
		);
		const geoBlockingModal =
			DomService.getElement<HTMLElement>('#geoBlockingModal');
		const geoBlockingButton = DomService.getElement<HTMLElement>(
			'button',
			geoBlockingModal
		);
		const geoBlockingSignupText = DomService.getElement<HTMLElement>(
			'span.sign-up-text',
			geoBlockingModal
		);
		const geoBlockingCountryNameText = DomService.getElement<HTMLElement>(
			'span.country-name-text',
			geoBlockingModal
		);

		if (
			!body ||
			!registerForm ||
			!countryCustomModal ||
			!descriptionCustomModal ||
			!geoBlockingModal
		) {
			// geoBlockingModal can be missing in EJS files when BOT is detected (using AWS WAF) in lambda
			// In that case standard html is returned:
			// - without geoBlocking modal
			// - without custom modal
			// - with register form (form won't work, but it doesn't matter to the bot)
			// - with registerSocialIcons
			// - with enabled links to webtrader
			return;
		}

		try {
			const storageCountryCustomModalDisplayed =
				(this.storageService.get(
					STORAGE_KEYS.COUNTRY_CUSTOM_MODAL
				) as Array<string>) || [];
			const storageGeoBlockingModalDisplayed =
				(this.sessionStorageService.get(
					STORAGE_KEYS.GEO_BLOCKING_MODAL
				) as Array<string>) || [];

			this.country = await CountriesService.getCountryByIp();

			if (
				this.country.displayCustomAlertHome &&
				!storageCountryCustomModalDisplayed.includes(
					this.country.countryCode
				)
			) {
				const translation = UtilsService.getTranslation(
					this.country.displayCustomAlertHome
				);

				if (translation) {
					countryCustomModal.hidden = false;
					descriptionCustomModal.innerHTML = translation;
				}
			}

			if (this.country.displayGeoblockingAlert || !this.country.signup) {
				if (geoBlockingCountryNameText) {
					geoBlockingCountryNameText.innerText = `(${this.country.countryName})`;
					geoBlockingCountryNameText.hidden = false;
				}

				if (
					!storageGeoBlockingModalDisplayed.includes(
						this.country.countryCode
					)
				) {
					geoBlockingModal.hidden = false;

					if (this.country.signup && geoBlockingSignupText) {
						geoBlockingSignupText.hidden = false;
					}
				}
			}

			if (!this.country.signup) {
				registerForm.remove();
				signupBox?.remove();
				registerSocialIcons?.remove();
				geoBlockingModal.classList.add('modal--signup-disabled');
				body.classList.add('signup-disabled');
				this.disableWebtraderLinks(geoBlockingModal);
			} else {
				const signUp = new SignUp();
			}

			this.initModalsClose(
				() => {
					storageCountryCustomModalDisplayed.push(
						this.country.countryCode
					);

					this.storageService.set(
						STORAGE_KEYS.COUNTRY_CUSTOM_MODAL,
						JSON.stringify(storageCountryCustomModalDisplayed)
					);
				},
				countryCustomModal,
				continueButtonModal
			);

			const geoBlockingCallback = (): void => {
				storageGeoBlockingModalDisplayed.push(this.country.countryCode);

				this.sessionStorageService.set(
					STORAGE_KEYS.GEO_BLOCKING_MODAL,
					JSON.stringify(storageGeoBlockingModalDisplayed)
				);
			};

			this.initModalsClose(
				geoBlockingCallback,
				geoBlockingModal,
				geoBlockingButton
			);

			geoBlockingModal.addEventListener('click', (event: MouseEvent) => {
				if (
					(event.target as HTMLElement)?.classList.contains(
						'modal__backdrop'
					)
				) {
					geoBlockingCallback();
					geoBlockingModal.hidden = true;
				}
			});

			this.showUsersnapWidget();
		} catch (error) {
			console.error(error);
		}
	}

	private initQueryParams(): void {
		const queryStringStorageKey = 'query_string';
		const locationSearch = window.location.search;
		const queryString =
			locationSearch ??
			this.storageService.get(queryStringStorageKey) ??
			'';

		if (queryString) {
			this.storageService.set(queryStringStorageKey, queryString);
			// @ts-error
			// TODO: fix TS error
			this.queryString = queryString as any;
			this.updateGoToAppLinks();
		}

		if (locationSearch) {
			this.utilsService.setQueryString(locationSearch);
		}
	}

	private updateGoToAppLinks(): void {
		const linkElements = document.querySelectorAll('.go-to-app-link');

		linkElements.forEach((element: HTMLLinkElement) => {
			element.href += this.queryString;
		});
	}

	private initMenu(): void {
		const $menuTrigger = DomService.getElement<HTMLElement>(
			'#mobile-menu-trigger'
		);
		const $navMenu = DomService.getElement<HTMLElement>(
			'.header__menu-mobile'
		);
		const $body = DomService.getElement<HTMLElement>('body');

		if ($menuTrigger) {
			$menuTrigger.addEventListener('click', () => {
				$menuTrigger.classList.toggle('active');

				if ($navMenu) {
					$navMenu.classList.toggle('header__menu-mobile--open');
				}

				if ($body) {
					$body.classList.toggle('mobile-menu-opened');
				}
			});

			this.checkNewPagesExist('.collapsible');
		}
		this.checkNewPagesExist('.header__menu-link');
	}

	private initHeaderDisclaimer(): void {
		if (
			[DeviceEnum.Desktop, DeviceEnum.LargeDesktop].includes(
				UtilsService.getDevice()
			)
		) {
			return;
		}

		const headerContainerElement =
			DomService.getElement<HTMLElement>('.header__container');

		const headerDisclaimerElements =
			DomService.getElements<HTMLElement>('.header-disclaimer');

		if (!headerContainerElement || !headerDisclaimerElements) {
			return;
		}

		forEach(headerDisclaimerElements, (element: HTMLElement) =>
			element.addEventListener('click', () => {
				headerContainerElement.classList.toggle('disclaimer-active');
			})
		);
	}

	private initFloatingButtons(): void {
		if (
			[DeviceEnum.Desktop, DeviceEnum.LargeDesktop].includes(
				UtilsService.getDevice()
			)
		) {
			return;
		}

		const floatingButtons = DomService.getElements<HTMLElement>(
			'.livechat__wrapper a'
		);

		if (!floatingButtons?.length) {
			return;
		}

		forEach(floatingButtons, (button: HTMLElement) => {
			button.addEventListener('click', (event: MouseEvent) => {
				if (!button.classList.contains('mobile-active')) {
					event.preventDefault();
				}

				button.classList.toggle('mobile-active');
			});
		});

		document.addEventListener('click', (event: MouseEvent) => {
			forEach(floatingButtons, (button: HTMLElement) => {
				if (!button.contains(event.target as Node)) {
					button.classList.remove('mobile-active');
				}
			});
		});
	}

	private initScrollToTop(): void {
		const scrollToTopHiddenClass = 'scroll-top-hidden';
		const scrollToTopTrigger = DomService.getElement<HTMLElement>(
			'.scroll-to-top__wrapper'
		);

		if (scrollToTopTrigger) {
			if (window.scrollY === 0) {
				scrollToTopTrigger.hidden = true;
				document.body.classList.add(scrollToTopHiddenClass);
			}

			scrollToTopTrigger.addEventListener('click', () => {
				window.scrollTo(0, 0);
			});

			window.addEventListener('scroll', function () {
				if (window.scrollY === 0) {
					scrollToTopTrigger.hidden = true;
					document.body.classList.add(scrollToTopHiddenClass);
				} else if (scrollToTopTrigger.hidden) {
					scrollToTopTrigger.hidden = false;
					document.body.classList.remove(scrollToTopHiddenClass);
				}
			});
		}
	}

	private initCookies(): void {
		const cookiesBox = DomService.getElement<HTMLElement>(
			'.cookies-box__wrapper'
		);
		const acceptBtn = DomService.getElement<HTMLElement>(
			'.cookies-box__accept-btn'
		);

		if (acceptBtn && cookiesBox) {
			// TODO: remove after fix getting cookie in lambda
			const cookie = this.cookieService.get(
				STORAGE_KEYS.COOKIES_ACCEPTED
			);

			if (!cookie) {
				cookiesBox.hidden = false;
			}

			acceptBtn.addEventListener('click', () => {
				cookiesBox.style.display = 'none';
				this.cookieService.set(STORAGE_KEYS.COOKIES_ACCEPTED, true);
			});
		}
	}

	private initModalsClose(
		callback: () => void,
		modal: HTMLElement,
		modalButton?: HTMLElement
	): void {
		if (modalButton) {
			modalButton.addEventListener('click', () => {
				callback();
				modal.hidden = true;
			});
		}
	}

	private initPromoCode(): void {
		const promoCodeModal =
			DomService.getElement<HTMLElement>('#promoCodeModal');
		const closeBtn = DomService.getElement<HTMLElement>(
			'.modal__close-icon',
			promoCodeModal
		);

		if (closeBtn && promoCodeModal) {
			closeBtn.addEventListener('click', () => {
				promoCodeModal.style.display = 'none';
			});
		}
	}

	private initLanguageDropdown(): void {
		// Desktop symbols-dropdown
		const element = DomService.getElement<HTMLElement>(
			'.header #langTrigger'
		);
		if (!element) {
			return;
		}

		const parentElement = element.parentElement;
		if (!parentElement) {
			return;
		}

		element.addEventListener('click', () =>
			parentElement.classList.toggle('open')
		);
		document.addEventListener('click', (event: MouseEvent) => {
			if (!parentElement.contains(event.target as Node)) {
				parentElement.classList.remove('open');
			}
		});

		// Mobile select
		const select = DomService.getElement<HTMLSelectElement>(
			'.header #languageSelect'
		);
		if (!select) {
			return;
		}

		select.addEventListener('change', (event: Event) => {
			const target = event.target as HTMLSelectElement;
			const value = target.value;
			const selectedLang =
				target.options[target.selectedIndex].dataset.lang;

			if (selectedLang) {
				this.cookieService.set(
					STORAGE_KEYS.USER_SELECTED_LANGUAGE,
					selectedLang,
					undefined,
					true
				);
			}

			window.location.href = value === 'en' ? '' : value;
		});

		this.initLanguageItemClickListeners();
	}

	private initLanguageItemClickListeners(): void {
		DomService.getElements<HTMLElement>('.header__language-item')?.forEach(
			(item: HTMLElement) => {
				item.addEventListener('click', () => {
					if (item.dataset.lang) {
						this.cookieService.set(
							STORAGE_KEYS.USER_SELECTED_LANGUAGE,
							item.dataset.lang,
							undefined,
							true
						);
					}
				});
			}
		);
	}

	private initCollapsibleElements(): void {
		const elements = document.querySelectorAll('.collapsible__toggle');

		forEach(elements, (item: HTMLElement) => {
			item.addEventListener('click', () => {
				item.classList.toggle('active');

				const content = item.nextElementSibling as HTMLElement;
				const parentElement = item.parentElement as HTMLElement;

				content.classList.toggle('active');

				if (
					parentElement &&
					parentElement.classList.contains('faq__group')
				) {
					parentElement.classList.toggle('active');
				}

				if (content.style.maxHeight) {
					content.style.maxHeight = '';
				} else {
					content.style.maxHeight = content.scrollHeight * 2 + 'px'; // double max space to cover possible paddings visible only on active state
				}
			});
		});
	}

	private initSectionsAnimation(): void {
		let observer: IntersectionObserver;
		const animatedSectionsElements =
			DomService.getElements<HTMLElement>(`.section-animated`);

		if (!animatedSectionsElements) {
			return;
		}

		if ('IntersectionObserver' in window) {
			const observerOptions = {
				threshold: window.innerWidth > 765 ? 0.2 : 0
			};

			const observerCallback = (
				entries: IntersectionObserverEntry[]
			): void => {
				entries.forEach((entry: IntersectionObserverEntry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add('active');
						observer.unobserve(entry.target);
					}
				});
			};

			observer = new IntersectionObserver(
				observerCallback,
				observerOptions
			);

			animatedSectionsElements.forEach((element: HTMLElement) => {
				observer.observe(element);
			});
		} else {
			animatedSectionsElements.forEach((element: HTMLElement) => {
				element.classList.add('active');
			});
		}
	}

	private initStoreButtons(): void {
		if (UtilsService.isMobile()) {
			return;
		}

		const showTopClassName = 'store-button__qr-wrapper--top';
		const showBottomClassName = 'store-button__qr-wrapper--bottom';
		const googlePlayButtons = DomService.getElements<HTMLElement>(
			'.google-play-button, .google-play-button__blue-border'
		);

		const appStoreButtons = DomService.getElements<HTMLElement>(
			'.app-store-button, .app-store-button__blue-border'
		);

		if (!googlePlayButtons?.length || !appStoreButtons?.length) {
			return;
		}

		const uniId = AnalyticsService.getUniId();

		let iosUrl: string;
		let androidUrl: string;

		const storeQrCodes: Record<'ios' | 'android', string> = {
			android: '',
			ios: ''
		};

		if (uniId) {
			iosUrl = AnalyticsService.getLinkToMobileAppInAppStore();
			androidUrl = AnalyticsService.getLinkToMobileAppInGooglePlay();
		}

		const handleStoreButton = (
			button: HTMLElement,
			storeUrl: string,
			type: 'ios' | 'android'
		): void => {
			const qrWrapperEl = DomService.getElement<HTMLElement>(
				'.store-button__qr-wrapper',
				button.parentElement
			);
			const imageElement = DomService.getElement<HTMLImageElement>(
				'img',
				qrWrapperEl
			);
			const sourceElement = DomService.getElement<HTMLSourceElement>(
				'source',
				qrWrapperEl
			);

			if (!qrWrapperEl) {
				return;
			}
			const qrWrapperClientRect = qrWrapperEl.getBoundingClientRect();
			let activeClass = showTopClassName;

			button.addEventListener('mouseenter', () => {
				const handler = async (): Promise<void> => {
					if (uniId && imageElement) {
						if (!storeQrCodes[type]) {
							storeQrCodes[type] =
								await QRCode.toDataURL(storeUrl);
						}

						imageElement.src = storeQrCodes[type];
						sourceElement?.remove();
					}

					const buttonClientRect = button.getBoundingClientRect();
					// 70 - header height
					activeClass =
						buttonClientRect.top < qrWrapperClientRect.height + 70
							? showBottomClassName
							: showTopClassName;

					qrWrapperEl.classList.add(activeClass);
				};

				void handler();
			});

			button.addEventListener('mouseleave', () => {
				qrWrapperEl.classList.remove(activeClass);
			});
		};

		forEach(appStoreButtons, (button: HTMLElement) =>
			handleStoreButton(button, iosUrl, 'ios')
		);
		forEach(googlePlayButtons, (button: HTMLElement) =>
			handleStoreButton(button, androidUrl, 'android')
		);
	}

	private addSmoothScrollBehavior(): void {
		// Timeout to scroll without smooth effect on init
		setTimeout(() => {
			document.documentElement.style.scrollBehavior = 'smooth';
		}, 100);
	}

	private checkNewPagesExist(selector: string): void {
		const $navMenuItems = DomService.getElements(selector);

		if (!$navMenuItems) return;

		forEach($navMenuItems, ($navMenuItem: HTMLElement) => {
			const $newBadgeItems = DomService.getElements(
				'.badge-new',
				$navMenuItem
			);

			if (!$newBadgeItems?.length) return;

			forEach($newBadgeItems, ($newItem: HTMLElement) => {
				const url = $newItem.dataset.url ?? '';
				const pageSeen = this.storageService.get(url);

				if (!pageSeen) {
					$navMenuItem.classList.add('new');
				}
			});
		});
	}

	private disableWebtraderLinks(geoBlockingModal: HTMLElement): void {
		const webtraderLinks = DomService.getElements<HTMLElement>(
			`a[href*="app"][href*="${API.cookiesDomain}"]`
		);

		if (!webtraderLinks) return;

		forEach(webtraderLinks, (item: HTMLElement) => {
			item.addEventListener('click', (event: MouseEvent) => {
				event.preventDefault();
				geoBlockingModal.hidden = false;
			});
		});
	}

	private updateLinksToMobileAppStore(): void {
		const uniId = AnalyticsService.getUniId();

		if (!uniId) {
			return;
		}

		const iosElements = DomService.getElements<HTMLAnchorElement>(
			`a[href^="${APPLICATIONS_URLS.iOS}"]`
		);
		const iosUrl = AnalyticsService.getLinkToMobileAppInAppStore();
		forEach(iosElements, (element: HTMLAnchorElement) => {
			element.href = iosUrl;
		});

		const androidElements = DomService.getElements<HTMLAnchorElement>(
			`a[href^="${APPLICATIONS_URLS.Android}"]`
		);
		const androidUrl = AnalyticsService.getLinkToMobileAppInGooglePlay();
		forEach(androidElements, (element: HTMLAnchorElement) => {
			element.href = androidUrl;
		});
	}
}

DomService.runOnDomReady(() => new App());
