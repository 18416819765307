const mainBlueColor = 'rgb(14, 164, 209)';
const scaleColor = 'rgb(228, 231, 236)';

export const CHART_OPTIONS = {
	layout: {
		textColor: '#475467',
		background: {
			type: 'solid',
			color: 'white'
		},
		fontFamily: 'Roboto'
	},
	rightPriceScale: {
		borderColor: scaleColor,
		visible: true
	},
	leftPriceScale: {
		visible: false
	},
	timeScale: {
		borderColor: scaleColor,
		allowBoldLabels: false,
		fixLeftEdge: true,
		fixRightEdge: true
	},
	grid: {
		vertLines: {
			visible: false
		},
		horzLines: {
			visible: false
		}
	},
	crosshair: {
		vertLine: {
			color: mainBlueColor,
			style: 0,
			labelBackgroundColor: mainBlueColor
		},
		horzLine: {
			color: mainBlueColor,
			style: 0,
			labelBackgroundColor: mainBlueColor
		}
	}
};

export const AREA_OPTIONS = {
	lineColor: mainBlueColor,
	lineWidth: 2,
	crosshairMarkerBorderWidth: 0,
	topColor: 'rgba(14, 164, 209, 0.13)',
	bottomColor: 'rgba(14, 164, 209, 0.00)'
};
