export class StorageService {
	private readonly prefix: string = 'SFXHome.';
	public static instance: StorageService;
	public readonly testKey: string = 'random__key__for__test';
	public storageAPI: Storage;
	public isStorageSupported: boolean;

	constructor() {
		this.init();
	}

	public init(): void {
		this.isStorageSupported = this.getIsStorageSupported();
		if (this.isStorageSupported) {
			this.storageAPI = localStorage;
		}
	}

	public static getInstance(): StorageService {
		if (!StorageService.instance) {
			StorageService.instance = new StorageService();
		}

		return StorageService.instance;
	}

	public getIsStorageSupported(): boolean {
		try {
			localStorage.setItem(this.testKey, this.testKey);
			localStorage.removeItem(this.testKey);
			return true;
		} catch (e) {
			return false;
		}
	}

	public get(key: string): Record<string, any> | any[] | string | null {
		if (!this.isStorageSupported) {
			return null;
		}

		let item = this.storageAPI.getItem(this.prefix + key);

		try {
			if (item) {
				item = JSON.parse(item);
			}
		} catch (e) {
			// stays same as it is
		}

		return item;
	}

	public set(
		key: string,
		value: Record<string, any> | any[] | string | number
	): void {
		try {
			const item =
				typeof value === 'object' ? JSON.stringify(value) : `${value}`;
			this.storageAPI.setItem(this.prefix + key, item);
		} catch (e) {
			// Local storage not supported or private mode
		}
	}

	public remove(key: string): void {
		try {
			this.storageAPI.removeItem(this.prefix + key);
		} catch (e) {
			// Local storage not supported or private mode
		}
	}

	public clear(): void {
		try {
			this.storageAPI.clear();
		} catch (e) {
			// Local storage not supported or private mode
		}
	}
}
