import { forEach, includes } from 'lodash-es';

import { CLASS_NAMES } from '../../config';
import { DomService } from '../../services';
import {
	CollectionPagination,
	type ICollectionOptions
} from './collection-pagination';

export interface IPaymentsFiltersOptions extends ICollectionOptions {
	elementsNode?: Element;
}

export class PaymentsFilters extends CollectionPagination {
	constructor(options: IPaymentsFiltersOptions) {
		super();

		this.pageCount = options.pageCount;
		this.elementsNode = options.elementsNode;
		this.contentWrapper = options.contentWrapper;

		this.init(options.collection);
	}

	private init(collection: Array<HTMLElement>): void {
		const paginationPlaceholder = DomService.getElement<HTMLElement>(
			'.pagination__items-placeholder',
			this.elementsNode
		);

		if (!paginationPlaceholder) {
			return;
		}

		this.collection = [...collection];
		this.paginationPlaceholder = paginationPlaceholder;

		this.initPaginationEvents();
	}

	public renderCollection(): void {
		const slicedItems = this.collection.slice(
			this.pageIndex * this.pageCount,
			this.pageIndex * this.pageCount + this.pageCount
		);

		forEach(this.collection, (element: HTMLElement) => {
			if (includes(slicedItems, element)) {
				element.classList.remove(CLASS_NAMES.displayNone);
			} else {
				element.classList.add(CLASS_NAMES.displayNone);
			}
		});
	}

	public updateData(collection: Array<any>): void {
		this.pageIndex = 0;
		this.collection = collection;

		this.renderCollection();
		this.renderPagination();
		this.updatePagination();
	}
}
