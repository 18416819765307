import { type ICountry } from '../models';
import axios from 'axios';

import { API } from '../config';
import { CookieService } from './cookie.service';

export class CountriesService {
	private static readonly cookieService: CookieService =
		CookieService.getInstance();

	private static _instance: CountriesService;

	private static country: ICountry;

	constructor() {
		if (CountriesService._instance) {
			return CountriesService._instance;
		}

		CountriesService._instance = this;
	}

	private static async fetchCountryByIp(): Promise<ICountry> {
		let devCountryCode = null;
		if (!ENV.isProduction) {
			devCountryCode = this.cookieService.get(`developCountryCode`);
		}

		return await axios
			.get(
				`${API.backend}/v3/countries/byIp${
					!ENV.isProduction && devCountryCode
						? '?debugResultCountryCode=' + devCountryCode
						: ''
				}`
			)
			.then((response) => response.data.data as ICountry);
	}

	public static async getCountryByIp(): Promise<ICountry> {
		if (this.country) {
			return this.country;
		}

		this.country = await this.fetchCountryByIp();

		return this.country;
	}
}
