import { assign, forEach, values } from 'lodash-es';

import { AnalyticsService, DomService } from '../../services';
import { SignUpService } from './sign-up.service';
import { SocialProvider } from '../../enums';
import { isAppleDevice } from './sign-up.utils';
import { TELEGRAM_CONFIG } from '../../config';

export class SignUp {
	private readonly signUpService: SignUpService;
	private registerButton: HTMLButtonElement;
	private buttonLoaderElement: HTMLElement;
	private inputElement: HTMLInputElement;
	private consentsCheckbox: HTMLInputElement;
	private emailRequiredElement: HTMLElement;
	private consentsRequiredElement: HTMLElement;
	private signUpSuccessModal: HTMLElement;
	private signUpErrorModal: HTMLElement;
	private isLoading: boolean = false;

	constructor() {
		this.signUpService = new SignUpService();
		this.init();
		this.initModals();
		this.initExternalProviders();
	}

	private init(): void {
		const registerForm = DomService.getElement<HTMLElement>(
			'.section-cta-footer__form'
		);
		const registerButton = DomService.getElement<HTMLButtonElement>(
			'button',
			registerForm
		);
		const buttonLoaderElement = DomService.getElement<HTMLElement>(
			'.btn-loader',
			registerButton
		);
		const inputElement = DomService.getElement<HTMLInputElement>(
			'#accountEmail',
			registerForm
		);
		const consentsCheckbox = DomService.getElement<HTMLInputElement>(
			'#signUpConsents',
			registerForm
		);
		const emailRequiredElement = DomService.getElement<HTMLElement>(
			'.section-cta-footer__email-required',
			registerForm
		);
		const consentsRequiredElement = DomService.getElement<HTMLElement>(
			'.section-cta-footer__consents-required',
			registerForm
		);

		if (
			!registerForm ||
			!registerButton ||
			!buttonLoaderElement ||
			!inputElement ||
			!consentsCheckbox ||
			!emailRequiredElement ||
			!consentsRequiredElement
		) {
			return;
		}

		this.registerButton = registerButton;
		this.buttonLoaderElement = buttonLoaderElement;
		this.inputElement = inputElement;
		this.consentsCheckbox = consentsCheckbox;
		this.emailRequiredElement = emailRequiredElement;
		this.consentsRequiredElement = consentsRequiredElement;

		registerButton.addEventListener('click', () => this.submitByEmail());

		this.inputElement.addEventListener('input', () => {
			const isEmailCorrect = this.signUpService.isEmailValid(
				this.inputElement
			);

			this.registerButton.disabled = !isEmailCorrect;
			this.emailRequiredElement.hidden = isEmailCorrect;

			if (this.inputElement.parentElement) {
				const elementClassList =
					this.inputElement.parentElement.classList;
				isEmailCorrect
					? elementClassList.remove('error')
					: elementClassList.add('error');
			}
		});

		this.consentsCheckbox.addEventListener('change', () => {
			this.registerButton.disabled = !this.consentsCheckbox.checked;
			this.consentsRequiredElement.hidden = this.consentsCheckbox.checked;
		});

		window.recaptchaCallback = this.onSubmitReCaptcha.bind(this);
	}

	private initModals(): void {
		const signUpSuccessModal = DomService.getElement<HTMLElement>(
			'#signUpSuccessModal'
		);
		const successCloseBtn = DomService.getElement<HTMLElement>(
			'.modal__close-icon',
			signUpSuccessModal
		);
		const continueSuccessBtn = DomService.getElement<HTMLElement>(
			'.modal__buttons button',
			signUpSuccessModal
		);

		if (successCloseBtn && signUpSuccessModal && continueSuccessBtn) {
			this.signUpSuccessModal = signUpSuccessModal;
			successCloseBtn.addEventListener('click', () => {
				signUpSuccessModal.hidden = true;
			});
			continueSuccessBtn.addEventListener('click', () => {
				signUpSuccessModal.hidden = true;
			});
		}

		const signUpErrorModal =
			DomService.getElement<HTMLElement>('#signUpErrorModal');
		const errorCloseBtn = DomService.getElement<HTMLElement>(
			'.modal__close-icon',
			signUpErrorModal
		);
		const continueErrorBtn = DomService.getElement<HTMLElement>(
			'.modal__buttons .btn',
			signUpErrorModal
		);

		if (errorCloseBtn && signUpErrorModal && continueErrorBtn) {
			this.signUpErrorModal = signUpErrorModal;
			errorCloseBtn.addEventListener('click', () => {
				signUpErrorModal.hidden = true;
			});
			continueErrorBtn.addEventListener('click', () => {
				signUpErrorModal.hidden = true;
			});
		}
	}

	private submitByEmail(): void {
		const isEmailCorrect = this.signUpService.isEmailValid(
			this.inputElement
		);
		const areFormConsentsCorrect =
			this.signUpService.areFormConsentsChecked(this.consentsCheckbox);

		if (!isEmailCorrect) {
			this.emailRequiredElement.hidden = false;
			this.inputElement.parentElement?.classList.add('error');
		}

		if (!areFormConsentsCorrect) {
			this.consentsRequiredElement.hidden = false;
		}

		if (this.isLoading || !isEmailCorrect || !areFormConsentsCorrect) {
			this.registerButton.disabled = true;
			return;
		}

		this.executeCaptcha();
	}

	private executeCaptcha(): void {
		if (!window.grecaptcha) {
			throw Error('Missing grecaptcha');
		}

		void window.grecaptcha.execute();
	}

	private resetCaptcha(): void {
		if (!window.grecaptcha) {
			throw Error('Missing grecaptcha');
		}

		window.grecaptcha.reset();
	}

	private onSubmitReCaptcha(captchaToken: string): void {
		if (!captchaToken?.length) {
			throw Error('recaptchaCallback received no captcha token');
		}

		this.sendEmail(captchaToken);
	}

	private sendEmail(captchaToken: string): void {
		this.isLoading = true;
		this.showLoader();

		this.signUpService
			.submitByEmail(
				this.inputElement.value,
				this.consentsCheckbox.checked,
				captchaToken
			)
			.then(() => {
				AnalyticsService.sendEvent('registration', {
					method: 'email'
				});
				this.showSuccessModal();
			})
			.catch(() => {
				AnalyticsService.sendEvent('registration_fail', {
					method: 'email'
				});
				this.showErrorModal();
			})
			.finally(() => {
				this.isLoading = false;
				this.inputElement.value = '';
				this.hideLoader();
				this.resetCaptcha();
			});
	}

	private showSuccessModal(): void {
		this.signUpSuccessModal.hidden = false;
	}

	private showErrorModal(): void {
		this.signUpErrorModal.hidden = false;
	}

	private showLoader(): void {
		this.registerButton.disabled = true;
		this.buttonLoaderElement.classList.remove('hide');
	}

	private hideLoader(): void {
		this.registerButton.disabled = false;
		this.buttonLoaderElement.classList.add('hide');
	}

	private initExternalProviders(): void {
		forEach(values(SocialProvider), (key: string) => {
			const elements = DomService.getElements<HTMLElement>(
				`[data-login-by-social-provider=${key}]`
			);

			forEach(elements, (providerElement: HTMLElement) => {
				const isTelegram =
					key.toLowerCase() === SocialProvider.Telegram;

				if (isTelegram && isAppleDevice()) {
					providerElement.remove();
				} else {
					providerElement.addEventListener('click', () => {
						if (this.isLoading) {
							return;
						}

						providerElement.parentElement?.classList.add('active');

						this.isLoading = true;

						if (isTelegram) {
							this.signUpService
								.registerByTelegram()
								.then(({ nonce }: { nonce: string }) => {
									const params = {
										bot_id: TELEGRAM_CONFIG.BOT_ID,
										scope: TELEGRAM_CONFIG.SCOPE,
										public_key: TELEGRAM_CONFIG.PUBLIC_KEY,
										nonce,
										callback_url: `${TELEGRAM_CONFIG.CALLBACK_PATH}/?code=${nonce}`
									};

									AnalyticsService.sendEvent('registration', {
										method: 'telegram'
									});

									window.location =
										Telegram.Passport.auth(params);
								})
								.catch(() => {
									AnalyticsService.sendEvent(
										'registration_fail',
										{
											method: 'telegram'
										}
									);
									this.showErrorModal();
									providerElement.parentElement?.classList.remove(
										'active'
									);
								})
								.finally(() => {
									this.isLoading = false;
								});
						} else {
							this.signUpService
								.registerBySocial(key)
								.then(
									({
										redirectUrl
									}: {
										redirectUrl: string;
									}) => {
										if (redirectUrl) {
											AnalyticsService.sendEvent(
												'registration',
												{
													method: key
												}
											);

											window.location.href = redirectUrl;
										}
									}
								)
								.catch(() => {
									AnalyticsService.sendEvent(
										'registration_fail',
										{
											method: key
										}
									);
									this.showErrorModal();
									providerElement.parentElement?.classList.remove(
										'active'
									);
								})
								.finally(() => {
									this.isLoading = false;
								});
						}
					});
				}
			});
		});
	}
}
