import { API } from './api.config';

export const TELEGRAM_CONFIG = {
	CALLBACK_PATH: encodeURI(`${API.app}/auth/telegram`),
	BOT_ID: TELEGRAM.botId,
	PUBLIC_KEY: TELEGRAM.publicKey,
	SCOPE: {
		data: ['email'],
		v: 1
	}
};
