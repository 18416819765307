import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { flow, forEach, kebabCase, keyBy, sortBy, toArray } from 'lodash-es';

import { API, AXIOS_OPTIONS } from '../config';
import { DomService } from '../services';
import { type IPaymentOperator, PaymentOperatorGateStatus } from '../models';
import { CollectionSearch } from '../modules';

enum ServerStatus {
	MajorOutage,
	Working,
	PartialOutage
}

enum ServerName {
	WebTrader = 'WebTrader',
	Mobile = 'Mobile',
	Live = 'Live',
	Demo = 'Demo',
	Feed = 'FeedStatus',
	Backend = 'Backend'
}

interface IServerStatus {
	Name: string;
	Status: ServerStatus;
}

export class StatusPage {
	constructor() {
		if (document.querySelector('body.page-status')) {
			this.getPaymentOperatorsStatus();
			this.getServersStatus();
		}
	}

	private initSearch(): void {
		const containerElement = DomService.getElement<HTMLInputElement>(
			'.status__box--operators'
		);

		if (containerElement) {
			const search = new CollectionSearch(
				'.status__column--display-name',
				'.status__row',
				containerElement,
				'currency'
			);
		}
	}

	private getPaymentOperatorsStatus(): void {
		const operatorsContainerElement = DomService.getElement<HTMLElement>(
			'.status__operators-container'
		);

		axios
			.get(`${API.backend}/v3/transactions/operators`, AXIOS_OPTIONS)
			.then((response: AxiosResponse) => response.data)
			.then((response: { data: Record<string, IPaymentOperator> }) => {
				const operatorStatusRowTemplate =
					DomService.getElement<HTMLTemplateElement>(
						'#operatorStatusRow'
					);

				if (!operatorsContainerElement || !operatorStatusRowTemplate) {
					return;
				}

				operatorsContainerElement.innerText = '';

				flow(
					toArray(),
					(items: Array<IPaymentOperator>) =>
						sortBy(items, 'operatorDisplayName'),
					(items: Array<IPaymentOperator>) =>
						forEach(items, (item: IPaymentOperator) => {
							const cloned =
								operatorStatusRowTemplate.content.cloneNode(
									true
								);

							const displayNameElement =
								DomService.getElement<HTMLElement>(
									'.status__column--display-name',
									cloned
								);
							const greenElements =
								DomService.getElements<HTMLElement>(
									'.status--green',
									cloned
								);
							const warningElements =
								DomService.getElements<HTMLElement>(
									'.status--warning',
									cloned
								);

							if (
								!displayNameElement ||
								!greenElements ||
								!warningElements
							) {
								return;
							}

							displayNameElement.textContent =
								item.operatorDisplayName;
							displayNameElement.dataset.currency =
								item.availableCurrencies;

							const isOperational = item.gateStatus
								? item.gateStatus ===
								  PaymentOperatorGateStatus.Ok
								: item.isOperational;

							if (isOperational) {
								warningElements.forEach((element) =>
									element.remove()
								);
							} else {
								greenElements.forEach((element) =>
									element.remove()
								);
							}

							operatorsContainerElement.appendChild(cloned);
						})
				)(response.data);

				this.initSearch();
			})
			.catch((error: AxiosError) => {
				if (operatorsContainerElement) {
					operatorsContainerElement.innerText = '';
				}
				console.error(error);
			});
	}

	private getServersStatus(): void {
		const serversLoaderElement = DomService.getElement<HTMLElement>(
			'.status__servers-loader'
		);

		axios
			.get(`${API.status}/index.json`)
			.then((response: AxiosResponse) => response.data)
			.then((response: Array<IServerStatus>) => {
				const serversStatus = keyBy(response, 'Name');

				const tradingLiveElement = DomService.getElement<HTMLElement>(
					'.status__column--trading-live'
				);

				if (tradingLiveElement && serversStatus[ServerName.Live]) {
					const className =
						ServerStatus[serversStatus[ServerName.Live].Status];
					tradingLiveElement.classList.add(kebabCase(className));
				}

				const tradingDemoElement = DomService.getElement<HTMLElement>(
					'.status__column--trading-demo'
				);

				if (tradingDemoElement && serversStatus[ServerName.Demo]) {
					const className =
						ServerStatus[serversStatus[ServerName.Demo].Status];
					tradingDemoElement.classList.add(kebabCase(className));
				}

				const feedDemoElement = DomService.getElement<HTMLElement>(
					'.status__column--trading-feed'
				);

				if (feedDemoElement && serversStatus[ServerName.Feed]) {
					feedDemoElement.classList.add(
						kebabCase(
							ServerStatus[serversStatus[ServerName.Feed].Status]
						)
					);
				}

				const backendDemoElement = DomService.getElement<HTMLElement>(
					'.status__column--trading-backend'
				);

				if (backendDemoElement && serversStatus[ServerName.Backend]) {
					backendDemoElement.classList.add(
						kebabCase(
							ServerStatus[
								serversStatus[ServerName.Backend].Status
							]
						)
					);
				}

				const webtraderElement = DomService.getElement<HTMLElement>(
					'.status__column--webtrader'
				);

				if (webtraderElement && serversStatus[ServerName.WebTrader]) {
					this.updatePlatformRow(
						ServerName.WebTrader,
						webtraderElement,
						serversStatus
					);
				}

				const mobileElement = DomService.getElement<HTMLElement>(
					'.status__column--mobile'
				);

				if (mobileElement && serversStatus[ServerName.Mobile]) {
					this.updatePlatformRow(
						ServerName.Mobile,
						mobileElement,
						serversStatus
					);
				}

				if (serversLoaderElement) {
					serversLoaderElement.remove();
				}
			})
			.catch((error: AxiosError) => {
				if (serversLoaderElement) {
					serversLoaderElement.innerText = '';
				}
				console.error(error);
			});
	}

	private updatePlatformRow(
		serverName: ServerName,
		element: HTMLElement,
		serversStatus: Record<string, IServerStatus>
	): void {
		const className =
			serversStatus[serverName].Status === ServerStatus.Working
				? ServerStatus.Working
				: ServerStatus.PartialOutage;

		element.classList.add(kebabCase(ServerStatus[className]));

		if (
			serversStatus[ServerName.Feed] &&
			serversStatus[ServerName.Feed].Status !== ServerStatus.Working
		) {
			element.classList.add('feed-degraded');
		}

		if (
			serversStatus[ServerName.Live] &&
			serversStatus[ServerName.Live].Status !== ServerStatus.Working
		) {
			element.classList.add('trading-degraded');
		}
	}
}

DomService.runOnDomReady(() => new StatusPage());
