import Hammer from 'hammerjs';
import { assign } from 'lodash-es';

import { DomService, UtilsService } from '../../services';
import { CLASS_NAMES } from '../../config';

interface IOptions {
	autoPlay: boolean;
	autoPlayIntervalDuration: number;
	initialTranslateXValue?: number;
	gapWidth: number;
	loop?: boolean;
}

const ANIMATION_DURATION = 500; // ms

export class FullWidthCarousel {
	private readonly wrapperElement: HTMLElement;
	private readonly sliderContainer: HTMLElement;
	private leftArrow: HTMLElement;
	private rightArrow: HTMLElement;
	private elementWidth: number = 0;
	private translateXValue: number = 0;
	private carouselElements: Array<HTMLElement>;
	private carouselIntervalId: number;
	private isCarouselActive: boolean;
	private isAnimating: boolean = false;
	private onPanEndAnimation: Animation;
	private readonly isLeftToRightDirection: boolean =
		UtilsService.isLeftToRightDirection();

	private readonly options: IOptions = {
		autoPlay: false,
		autoPlayIntervalDuration: 3500,
		initialTranslateXValue: undefined,
		gapWidth: 16
	};

	constructor(
		wrapperElement: HTMLElement,
		sliderContainer: HTMLElement,
		itemsClass: string,
		options?: Partial<IOptions>
	) {
		if (options) {
			this.options = assign({}, this.options, options);
			this.translateXValue = this.options.initialTranslateXValue ?? 0;
		}

		if (wrapperElement && sliderContainer) {
			this.wrapperElement = wrapperElement;
			this.sliderContainer = sliderContainer;
			this.init(itemsClass);
			this.renderInitialTranslateXValue();
			this.initHammer();
		}
	}

	private get itemWidth(): number {
		return this.elementWidth + this.options.gapWidth;
	}

	private init(itemsClass: string): void {
		const rightArrow = DomService.getElement<HTMLElement>(
			'.pagination__item--next-page',
			this.wrapperElement
		);
		const leftArrow = DomService.getElement<HTMLElement>(
			'.pagination__item--previous-page',
			this.wrapperElement
		);
		const paginationElement = DomService.getElement<HTMLElement>(
			'ul.pagination',
			this.wrapperElement
		);

		const articlesElements = DomService.getElements<HTMLElement>(
			itemsClass,
			this.wrapperElement
		);

		if (
			!rightArrow ||
			!leftArrow ||
			!articlesElements?.length ||
			!paginationElement
		) {
			return;
		}

		const containerClientRect =
			this.sliderContainer.getBoundingClientRect();
		this.leftArrow = leftArrow;
		this.rightArrow = rightArrow;
		this.carouselElements = Array.from(articlesElements);

		if (!this.carouselElements.length) {
			return;
		}

		const elementRect = this.carouselElements[0].getBoundingClientRect();
		this.elementWidth = Math.floor(elementRect.width);

		if (
			containerClientRect.width >
			this.elementWidth * this.carouselElements.length
		) {
			paginationElement.remove();
			return;
		}

		!this.options.loop
			? leftArrow.classList.add(CLASS_NAMES.paginationDisabled)
			: leftArrow.classList.remove(CLASS_NAMES.paginationDisabled);

		this.rightArrow.addEventListener('click', () => {
			if (this.isAnimating) {
				return;
			}

			this.stopInterval();
			this.updateLoopCarousel('next');
		});

		this.leftArrow.addEventListener('click', () => {
			if (this.isAnimating) {
				return;
			}

			this.stopInterval();
			this.updateLoopCarousel('previous');
		});

		if (this.options.autoPlay) {
			this.startInterval();

			document.addEventListener('visibilitychange', () => {
				document.visibilityState === 'hidden'
					? this.stopInterval()
					: this.startInterval();
			});

			this.wrapperElement.addEventListener(
				UtilsService.isTouchDevice() ? 'touchstart' : 'mouseenter',
				() => {
					if (!this.isCarouselActive) {
						return;
					}

					this.stopInterval();
				}
			);

			if (!UtilsService.isTouchDevice()) {
				this.wrapperElement.addEventListener('mouseleave', () => {
					if (this.isCarouselActive) {
						return;
					}

					this.startInterval();
				});
			}
		}
	}

	private stopInterval(): void {
		this.isCarouselActive = false;

		if (this.carouselIntervalId) {
			clearInterval(this.carouselIntervalId);
		}
	}

	private startInterval(): void {
		if (!this.options.autoPlay) {
			return;
		}

		this.isCarouselActive = true;

		this.carouselIntervalId = window.setInterval(() => {
			this.updateLoopCarousel('next');
		}, this.options.autoPlayIntervalDuration);
	}

	private initHammer(): void {
		if (!this.sliderContainer || !this.carouselElements.length) {
			return;
		}

		const hammerManager = new Hammer(this.sliderContainer, {
			touchAction: 'pan-y'
		});

		hammerManager.on('panmove', this.onPanMove.bind(this));
		hammerManager.on('panend', this.onPanEnd.bind(this));
		hammerManager.on('pancancel', this.onPanEnd.bind(this));
	}

	private onPanMove({ direction, deltaX, srcEvent }: HammerInput): void {
		if (
			srcEvent instanceof PointerEvent &&
			srcEvent?.pointerType === 'mouse'
		) {
			return;
		}

		this.onPanEndAnimation?.cancel();
		this.stopInterval();

		const directionMultiplier =
			direction === Hammer.DIRECTION_LEFT ? -1 : 1;
		let transformTo = this.translateXValue + (directionMultiplier + deltaX);

		if (
			this.options.loop &&
			this.sliderContainer.firstElementChild &&
			this.sliderContainer.lastElementChild
		) {
			if (deltaX > 0) {
				const { left, right } =
					this.sliderContainer.firstElementChild.getBoundingClientRect();
				const moveElement = this.isLeftToRightDirection
					? right > 0
					: left > window.innerWidth;

				if (moveElement) {
					if (this.isLeftToRightDirection) {
						this.sliderContainer.prepend(
							this.sliderContainer.lastElementChild
						);
						transformTo -= this.itemWidth;
						this.translateXValue -= this.itemWidth;
					} else {
						this.sliderContainer.appendChild(
							this.sliderContainer.firstElementChild
						);
						transformTo -= this.itemWidth;
						this.translateXValue -= this.itemWidth;
					}
				}
			} else {
				const { left, right } =
					this.sliderContainer.firstElementChild.getBoundingClientRect();
				const moveElement = this.isLeftToRightDirection
					? right < 0
					: left < window.innerWidth;

				if (moveElement) {
					if (this.isLeftToRightDirection) {
						this.sliderContainer.appendChild(
							this.sliderContainer.firstElementChild
						);
					} else {
						this.sliderContainer.prepend(
							this.sliderContainer.lastElementChild
						);
					}

					transformTo += this.itemWidth;
					this.translateXValue += this.itemWidth;
				}
			}
		}

		this.sliderContainer.style.transform = `translateX(${transformTo}px)`;
	}

	private onPanEnd({
		direction,
		deltaX,
		velocityX,
		srcEvent
	}: HammerInput): void {
		if (
			srcEvent instanceof PointerEvent &&
			srcEvent?.pointerType === 'mouse'
		) {
			return;
		}

		this.onPanEndAnimation?.cancel();
		this.isAnimating = true;

		const directionMultiplier =
			direction === Hammer.DIRECTION_LEFT ? -1 : 1;
		const transformFrom =
			this.translateXValue + (directionMultiplier + deltaX);

		let transformTo = transformFrom / this.itemWidth;
		if (velocityX > 1 || deltaX > 0) {
			transformTo += 0.5;
		} else if (velocityX < 1) {
			transformTo -= 0.5;
		}
		transformTo = Math.round(transformTo) * this.itemWidth;

		if (
			!this.options.loop &&
			this.sliderContainer.firstElementChild &&
			this.sliderContainer.lastElementChild
		) {
			if (this.isLeftToRightDirection) {
				if (deltaX > 0) {
					const { right } =
						this.sliderContainer.firstElementChild.getBoundingClientRect();

					if (right >= window.innerWidth) {
						transformTo = 0;
					}
				} else {
					const { left } =
						this.sliderContainer.lastElementChild.getBoundingClientRect();

					if (left < 0) {
						transformTo =
							-(this.carouselElements.length - 1) *
							this.itemWidth;
					}
				}
			} else {
				if (deltaX > 0) {
					const { left } =
						this.sliderContainer.lastElementChild.getBoundingClientRect();

					if (left > 0) {
						transformTo =
							(this.carouselElements.length - 1) * this.itemWidth;
					}
				} else {
					const { left } =
						this.sliderContainer.firstElementChild.getBoundingClientRect();

					if (left <= window.innerWidth) {
						transformTo = 0;
					}
				}
			}
		}

		this.onPanEndAnimation = this.sliderContainer.animate(
			[
				{ transform: `translateX(${transformFrom}px` },
				{ transform: `translateX(${transformTo}px` }
			],
			{
				duration: 300
			}
		);

		this.onPanEndAnimation.oncancel = () => {
			this.isAnimating = false;
		};
		this.onPanEndAnimation.onfinish = () => {
			this.sliderContainer.style.transform = `translateX(${transformTo}px)`;
			this.translateXValue = transformTo;
			this.updateControllersState();
			this.isAnimating = false;

			this.startInterval();
		};
	}

	private updateLoopCarousel(direction: 'next' | 'previous'): void {
		if (this.isAnimating) {
			return;
		}

		this.isAnimating = true;

		if (
			this.options.loop &&
			direction === 'previous' &&
			this.sliderContainer.lastElementChild
		) {
			this.sliderContainer.prepend(this.sliderContainer.lastElementChild);
		}

		let transformFrom: number;
		let transformTo: number;

		if (this.isLeftToRightDirection) {
			transformFrom =
				direction === 'previous' && this.options.loop
					? this.translateXValue - this.itemWidth
					: this.translateXValue;
			transformTo =
				direction === 'previous'
					? this.options.loop
						? this.translateXValue
						: this.translateXValue + this.itemWidth
					: this.translateXValue - this.itemWidth;
		} else {
			transformFrom =
				direction === 'previous' && this.options.loop
					? this.translateXValue + this.itemWidth
					: this.translateXValue;
			transformTo =
				direction === 'previous'
					? this.options.loop
						? this.translateXValue
						: this.translateXValue - this.itemWidth
					: this.translateXValue + this.itemWidth;
		}

		const animation = this.sliderContainer.animate(
			[
				{ transform: `translateX(${transformFrom}px` },
				{ transform: `translateX(${transformTo}px` }
			],
			ANIMATION_DURATION
		);

		animation.onfinish = () => {
			if (
				this.sliderContainer.firstElementChild &&
				this.sliderContainer.lastElementChild
			) {
				if (direction === 'next' && this.options.loop) {
					const { left, right } =
						this.sliderContainer.firstElementChild.getBoundingClientRect();
					const moveFirstElement = this.isLeftToRightDirection
						? right < 0
						: left > window.innerWidth;

					if (moveFirstElement) {
						transformTo =
							transformTo +
							(this.isLeftToRightDirection
								? this.itemWidth
								: -this.itemWidth);

						this.sliderContainer.appendChild(
							this.sliderContainer.firstElementChild
						);
					}
				}

				this.sliderContainer.style.transform = `translateX(${transformTo}px)`;
				this.translateXValue = transformTo;
			}

			this.updateControllersState();
			this.isAnimating = false;
		};
	}

	private updateControllersState(): void {
		if (this.options.loop) {
			return;
		}

		if (this.sliderContainer.firstElementChild) {
			const { left, right } =
				this.sliderContainer.firstElementChild.getBoundingClientRect();
			const blockArrow = this.isLeftToRightDirection
				? left >= 0
				: right <= window.innerWidth;

			blockArrow
				? this.leftArrow.classList.add(CLASS_NAMES.paginationDisabled)
				: this.leftArrow.classList.remove(
						CLASS_NAMES.paginationDisabled
				  );
		}

		if (this.sliderContainer.lastElementChild) {
			const { left, right } =
				this.sliderContainer.lastElementChild.getBoundingClientRect();
			const blockArrow = this.isLeftToRightDirection
				? right <= window.innerWidth
				: left >= 0;

			blockArrow
				? this.rightArrow.classList.add(CLASS_NAMES.paginationDisabled)
				: this.rightArrow.classList.remove(
						CLASS_NAMES.paginationDisabled
				  );
		}
	}

	private renderInitialTranslateXValue(): void {
		if (!this.translateXValue) {
			return;
		}

		this.sliderContainer.style.transform = `translateX(${this.translateXValue}px)`;
	}
}
