import { forEach, last } from 'lodash-es';

import { AREA_OPTIONS, CHART_OPTIONS } from '../../config';
import {
	CandleService,
	DomService,
	ResolutionToInterval,
	type ResolutionToIntervalKey
} from '../../services';

export class Chart {
	private readonly candleService: CandleService;
	private areaSeries: IAreaSeries;
	private chart: ILightweightChart;
	private isIntervalChanging: boolean = false;
	private activeResolutionKey: ResolutionToIntervalKey = '1D';
	private lastCandleTime: number = 0;

	constructor(
		private readonly symbol: string,
		private readonly container: HTMLElement
	) {
		if (this.symbol && this.container) {
			this.candleService = new CandleService();
			void this.init();
			this.initChartIntervals();
		}
	}

	private async init(): Promise<void> {
		const candles = await this.candleService.getCandles(
			this.symbol,
			ResolutionToInterval[this.activeResolutionKey]
		);

		if (!candles.length) {
			return;
		}

		this.lastCandleTime = last(candles)?.time ?? 0;

		this.chart = window.LightweightCharts.createChart(
			this.container,
			CHART_OPTIONS
		);

		this.areaSeries = this.chart.addAreaSeries(AREA_OPTIONS);
		this.areaSeries.setData(candles);

		window.addEventListener('resize', () => {
			const elementRect = this.container.getBoundingClientRect();
			this.chart.resize(elementRect.width, elementRect.height);
		});
	}

	private initChartIntervals(): void {
		const activeClassName = 'instrument__chart-interval--active';
		const chartIntervals = DomService.getElements<HTMLElement>(
			'.instrument__chart-interval'
		);

		if (chartIntervals) {
			forEach(chartIntervals, (button: HTMLElement) => {
				button.addEventListener('click', () => {
					const resolutionKey = button.dataset
						.resolution as string as ResolutionToIntervalKey;

					if (this.activeResolutionKey === resolutionKey) {
						return;
					}

					this.isIntervalChanging = true;
					this.activeResolutionKey = resolutionKey;

					forEach(chartIntervals, (element: HTMLElement) => {
						element.classList.remove(activeClassName);
					});

					button.classList.add(activeClassName);

					void this.onIntervalChange();
				});
			});
		}
	}

	private async onIntervalChange(): Promise<void> {
		const candles = await this.candleService.getCandles(
			this.symbol,
			ResolutionToInterval[this.activeResolutionKey]
		);

		if (!candles.length || !this.areaSeries) {
			return;
		}

		this.lastCandleTime = last(candles)?.time ?? 0;
		this.areaSeries.setData(candles);
		this.chart.timeScale().scrollToRealTime();
		this.isIntervalChanging = false;

		const timeVisible = ['1H', '4H'].includes(this.activeResolutionKey);
		this.chart.applyOptions({
			timeScale: {
				timeVisible
			}
		});
	}

	public updateCandle(price: number): void {
		if (this.areaSeries && !this.isIntervalChanging) {
			this.areaSeries.update({
				time: CandleService.getCandleTime(
					this.lastCandleTime,
					ResolutionToInterval[this.activeResolutionKey]
				),
				value: price
			});
		}
	}
}
