import {
	escape,
	filter,
	forEach,
	includes,
	intersection,
	isArray,
	isEmpty,
	isString,
	pull
} from 'lodash-es';

import { URL_PARAMS } from '../config';
import { DomService, UtilsService } from '../services';
import { CollectionFilters, SortTypes } from '../modules/collection-filters';
import { CustomSelect, CustomSelectType } from '../modules/custom-select';

type FilterField = 'level' | 'type' | 'device';

interface IDataToSet {
	typeToSet?: string;
	deviceToSet?: string;
	levelToSet?: string;
}

interface IFilters {
	topic: Array<string>;
	level?: string | null;
	type?: string | null;
	device?: string | null;
}

export class EducationPage {
	private initialArticles: Array<HTMLElement> = [];
	private filters: IFilters = {
		topic: []
	};

	private typesData: Array<string>;

	private collection: CollectionFilters;

	constructor() {
		if (document.querySelector('body.page-education')) {
			this.initElements();
			this.initData();
		}
	}

	private initData(): void {
		const availableData =
			DomService.getElement<HTMLScriptElement>('script#selectsData')
				?.textContent;

		if (!isString(availableData)) {
			return;
		}

		let typeToSet: string | undefined;
		let deviceToSet: string | undefined;
		let levelToSet: string | undefined;

		try {
			const data = JSON.parse(availableData);
			this.typesData = data.types;

			const typeParam = escape(
				URL_PARAMS.get('type') ?? undefined
			)?.toLowerCase();

			const deviceParam = escape(
				URL_PARAMS.get('device') ?? undefined
			)?.toLowerCase();
			const levelParam = escape(
				URL_PARAMS.get('level') ?? undefined
			)?.toLowerCase();

			if (
				typeParam &&
				!isEmpty(typeParam) &&
				this.typesData.includes(typeParam)
			) {
				this.filters.type = typeParam;
				typeToSet = typeParam;
			}

			if (
				!isEmpty(deviceParam) &&
				isArray(data.device) &&
				data.device.includes(deviceParam)
			) {
				this.filters.device = deviceParam;
				deviceToSet = deviceParam;
			}

			if (
				!isEmpty(levelParam) &&
				isArray(data.level) &&
				data.level.includes(levelParam)
			) {
				this.filters.level = levelParam;
				levelToSet = levelParam;
			}
		} catch (_) {
			console.error(`Can't parse data`);
		}

		this.initSelects({
			typeToSet,
			deviceToSet,
			levelToSet
		});
	}

	private initElements(): void {
		const articles = DomService.getElements<HTMLElement>(
			'.related-articles__item'
		);
		const contentWrapper = DomService.getElement<HTMLElement>(
			'.related-articles__wrapper'
		);

		if (!articles) {
			return;
		}

		this.initialArticles = articles;

		this.collection = new CollectionFilters({
			collection: articles,
			pageCount: 12,
			sortField: 'date',
			contentWrapper,
			initialSortType: SortTypes.NameDesc
		});
	}

	private initSelects({
		typeToSet,
		deviceToSet,
		levelToSet
	}: IDataToSet): void {
		const topicElement =
			DomService.getElement<HTMLElement>('#topic-select');
		const levelElement =
			DomService.getElement<HTMLElement>('#level-select');
		const typeElement = DomService.getElement<HTMLElement>('#type-select');
		const deviceElement =
			DomService.getElement<HTMLElement>('#device-select');
		const sortDesktopElement = DomService.getElement<HTMLElement>(
			'#sort-select-desktop'
		);
		const sortMobileElement = DomService.getElement<HTMLElement>(
			'#sort-select-mobile'
		);

		const topicChipsWrapperElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-chips-wrapper--topic'
		);
		const levelChipsWrapperElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-chips-wrapper--level'
		);
		const typeChipsWrapperElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-chips-wrapper--type'
		);
		const deviceChipsWrapperElement = DomService.getElement<HTMLElement>(
			'.collection-filters__filters-chips-wrapper--device'
		);

		if (
			!topicElement ||
			!levelElement ||
			!typeElement ||
			!deviceElement ||
			!sortDesktopElement ||
			!sortMobileElement ||
			!topicChipsWrapperElement ||
			!levelChipsWrapperElement ||
			!typeChipsWrapperElement ||
			!deviceChipsWrapperElement
		) {
			return;
		}

		const onSelectUpdate = (
			newElements: string,
			select: CustomSelect,
			filtersField: FilterField,
			chipsWrapper: HTMLElement,
			removeCallback?: () => void
		): void => {
			if (!newElements) {
				return;
			}

			chipsWrapper.innerHTML = '';
			this.filters[filtersField] = newElements;

			const newElement = document.createElement('div');
			const label = select.element
				? this.getSelectLabel(newElements, select.element)
				: newElements;

			newElement.classList.add('chips--filter');
			newElement.innerText = label;

			newElement.addEventListener(
				'click',
				() => {
					newElement.remove();
					this.filters[filtersField] = null;
					this.filterItems();

					if (removeCallback) {
						removeCallback();
					}

					select.reset();
				},
				{ once: true }
			);

			chipsWrapper.appendChild(newElement);

			this.filterItems();
		};

		const topicSelect = new CustomSelect(
			topicElement,
			CustomSelectType.MultiChoice
		);
		topicSelect.subscribe((topics: Array<string>) => {
			topicChipsWrapperElement.innerHTML = '';
			this.filters.topic = topics;

			topics.forEach((topic: string) => {
				const label = this.getSelectLabel(topic, topicElement);
				const newElement = document.createElement('div');
				newElement.classList.add('chips--filter');
				newElement.innerText = label;

				newElement.addEventListener(
					'click',
					() => {
						newElement.remove();
						topicSelect.updateItems(
							pull(this.filters.topic, topic)
						);
						this.filterItems();
					},
					{ once: true }
				);

				topicChipsWrapperElement.appendChild(newElement);

				this.filterItems();
			});
		});

		const levelSelect = new CustomSelect(
			levelElement,
			CustomSelectType.Radio
		);
		levelSelect.subscribe(([level]: [string]) => {
			onSelectUpdate(
				level,
				levelSelect,
				'level',
				levelChipsWrapperElement,
				() => UtilsService.removeQueryParams('level')
			);
			UtilsService.updateQueryParams('level', level);
		});
		if (levelToSet) {
			onSelectUpdate(
				levelToSet,
				levelSelect,
				'level',
				levelChipsWrapperElement,
				() => UtilsService.removeQueryParams('level')
			);

			setTimeout(() => {
				levelSelect.updateItems([levelToSet]);
			}, 0);
		}

		const typeSelect = new CustomSelect(
			typeElement,
			CustomSelectType.Radio
		);
		typeSelect.subscribe(([type]: [string]) => {
			onSelectUpdate(
				type,
				typeSelect,
				'type',
				typeChipsWrapperElement,
				() => UtilsService.removeQueryParams('type')
			);
			UtilsService.updateQueryParams('type', type);
		});

		if (typeToSet) {
			onSelectUpdate(
				typeToSet,
				typeSelect,
				'type',
				typeChipsWrapperElement,
				() => UtilsService.removeQueryParams('type')
			);

			setTimeout(() => {
				typeSelect.updateItems([typeToSet]);
			}, 0);
		}

		const deviceSelect = new CustomSelect(
			deviceElement,
			CustomSelectType.Radio
		);
		deviceSelect.subscribe(([device]: [string]) => {
			onSelectUpdate(
				device,
				deviceSelect,
				'device',
				deviceChipsWrapperElement,
				() => UtilsService.removeQueryParams('device')
			);
			UtilsService.updateQueryParams('device', device);
		});

		if (deviceToSet) {
			onSelectUpdate(
				deviceToSet,
				deviceSelect,
				'device',
				deviceChipsWrapperElement,
				() => UtilsService.removeQueryParams('device')
			);

			setTimeout(() => {
				deviceSelect.updateItems([deviceToSet]);
			}, 0);
		}

		forEach(
			[sortDesktopElement, sortMobileElement],
			(sortElement: HTMLElement) => {
				const sortSelect = new CustomSelect(
					sortElement,
					CustomSelectType.Standard
				);
				sortSelect.subscribe(([type]: [string]) => {
					this.collection.updateSortType(type as SortTypes);
				});
			}
		);

		this.collection.onClearAllFiltersClicked(() => {
			topicChipsWrapperElement.innerHTML = '';
			levelChipsWrapperElement.innerHTML = '';
			typeChipsWrapperElement.innerHTML = '';
			deviceChipsWrapperElement.innerHTML = '';
			this.filters.topic = [];
			this.filters.level = null;
			this.filters.type = null;
			this.filters.device = null;
			topicSelect.reset();
			levelSelect.reset();
			typeSelect.reset();
			deviceSelect.reset();

			UtilsService.removeQueryParams('device');
			UtilsService.removeQueryParams('level');
			UtilsService.removeQueryParams('type');
		});

		this.collection.onModalStateChanged((isOpen: boolean) => {
			topicSelect.emitChanges = isOpen;
			levelSelect.emitChanges = isOpen;
			typeSelect.emitChanges = isOpen;
			deviceSelect.emitChanges = isOpen;
		});

		this.collection.onFilterStateChanged((applyFilters: boolean) => {
			if (applyFilters) {
				topicSelect.applyFilters();
				levelSelect.applyFilters();
				typeSelect.applyFilters();
				deviceSelect.applyFilters();
			} else {
				topicSelect.restoreLastSavedFilters();
				levelSelect.restoreLastSavedFilters();
				typeSelect.restoreLastSavedFilters();
				deviceSelect.restoreLastSavedFilters();
			}
		});
	}

	private filterItems(): void {
		const clonedArticles = [...this.initialArticles];
		const filteredCollection = filter(
			clonedArticles,
			(element: HTMLElement) => {
				let topicMatch = true;
				let levelMatch = true;
				let typeMatch = true;
				let deviceMatch = true;

				if (!isEmpty(this.filters.topic)) {
					const valuesIntersection = intersection(
						element.dataset.tags?.split(','),
						this.filters.topic
					);
					topicMatch = !isEmpty(valuesIntersection);
				}

				if (!isEmpty(this.filters.level)) {
					levelMatch = includes(
						element.dataset.tags,
						this.filters.level
					);
				}

				if (!isEmpty(this.filters.type)) {
					typeMatch = includes(
						element.dataset.tags,
						this.filters.type
					);
				}

				if (!isEmpty(this.filters.device)) {
					deviceMatch = includes(
						element.dataset.tags,
						this.filters.device
					);
				}

				return topicMatch && levelMatch && typeMatch && deviceMatch;
			}
		);

		const activeFilters = filter(this.filters, (item) => !isEmpty(item));

		this.collection.updateData(filteredCollection, activeFilters.length);
	}

	private getSelectLabel(
		labelValue: string,
		labelElement: HTMLElement
	): string {
		return (
			DomService.getElement<HTMLLabelElement>(
				`label[data-value='${labelValue}']`,
				labelElement
			)?.innerText?.trim() ?? labelValue
		);
	}
}

DomService.runOnDomReady(() => new EducationPage());
