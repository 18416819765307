import axios from 'axios';
import {
	filter,
	find,
	flatten,
	flow,
	forEach,
	isEmpty,
	keyBy,
	map,
	some,
	toArray
} from 'lodash-es';

import { API } from '../config';
import {
	type IInstrument,
	type Instruments,
	type SearchInstrument
} from '../models';
import { UtilsService } from './utils.service';

export class InstrumentsService {
	private static _instance: InstrumentsService;
	private static instruments: Instruments;
	private static instrumentsToSearch: Array<SearchInstrument>;

	constructor() {
		if (InstrumentsService._instance) {
			return InstrumentsService._instance;
		}

		InstrumentsService._instance = this;
	}

	private static getCategoryKeyFromInstrumentType(type: string): string {
		return type.toLowerCase().replace(/\s/g, '-').replace('.', '-');
	}

	private static async fetchInstruments(): Promise<Instruments> {
		return await axios
			.get<Array<IInstrument>>(API.instruments)
			.then((response) =>
				keyBy(
					response.data,
					(instrument: IInstrument) => instrument.symbol
				)
			);
	}

	private static showInstrument(
		query: string,
		instrument: SearchInstrument
	): boolean {
		return some(
			[
				instrument.displayName,
				instrument.symbol,
				instrument.friendlyName,
				instrument.description,
				instrument.stockExchangeName,
				instrument.ticker,
				UtilsService.getTranslation(
					instrument.industryNameTranslationKey
				),
				...instrument.tagsList,
				instrument.tags,
				instrument.type,
				instrument.priceCurrency,
				instrument.marginCurrency,
				instrument.countryCode
			],
			(data: string) =>
				query ? data?.toLowerCase()?.includes(query) ?? false : true
		);
	}

	public static get areInstrumentsFetched(): boolean {
		return !!this.instruments;
	}

	// To improve user experience trigger prepareData when needed e.g. after focus (and before any key event)
	// in the search input
	// Do not call this method on DOM ready
	public static async prepareData(): Promise<void> {
		if (this.areInstrumentsFetched) {
			return;
		}

		this.instruments = await this.fetchInstruments();
		this.instrumentsToSearch = flow(
			(instruments: Instruments) => toArray(instruments),
			(instruments: Array<IInstrument>) =>
				filter(
					instruments,
					(instrument: IInstrument) => !!instrument.tradeMode
				),
			(instruments: Array<IInstrument>) =>
				map(
					instruments,
					({
						symbol,
						displayName,
						friendlyName,
						description,
						stockExchangeName,
						type,
						tagsList,
						ticker,
						industryNameTranslationKey,
						tags,
						priceCurrency,
						marginCurrency,
						countryCode
					}: IInstrument) => ({
						symbol,
						displayName,
						friendlyName,
						description,
						stockExchangeName,
						ticker,
						industryNameTranslationKey,
						category:
							InstrumentsService.getCategoryKeyFromInstrumentType(
								type
							),
						type,
						tagsList,
						tags,
						priceCurrency,
						marginCurrency,
						countryCode
					})
				)
		)(this.instruments) as Array<SearchInstrument>;
	}

	// Use searchInstruments only if you are sure the instruments are fetched
	public static searchInstruments(
		query: string,
		callback: (instrument: SearchInstrument, show: boolean) => void
	): void {
		if (!this.areInstrumentsFetched) {
			throw new Error('Instruments are missing');
		}

		forEach(this.instrumentsToSearch, (instrument: SearchInstrument) => {
			const show = this.showInstrument(query, instrument);
			callback(instrument, show);
		});
	}

	public static showInstrumentBySymbolName(
		query: string,
		symbolName?: string
	): boolean {
		if (isEmpty(symbolName)) {
			return false;
		}

		const checkSymbolName = (): boolean =>
			symbolName?.toLowerCase().includes(query) ?? false;

		if (!this.areInstrumentsFetched) {
			return checkSymbolName();
		}

		const instrument = find(
			this.instrumentsToSearch,
			({ symbol }: SearchInstrument) => {
				return symbol?.toLowerCase() === symbolName;
			}
		);

		if (!instrument) {
			return checkSymbolName();
		}

		return this.showInstrument(query, instrument);
	}

	public static getInstrumentsDidYouMeanWords(): Array<string> {
		if (!this.areInstrumentsFetched) {
			throw new Error('Instruments are missing');
		}

		return flow(
			(items) =>
				map(items, (instrument: IInstrument) => {
					const data: Array<string> = [
						instrument.displayName,
						instrument.ticker,
						instrument.description
					];

					if (instrument.industryNameTranslationKey) {
						data.push(
							UtilsService.getTranslation(
								instrument.industryNameTranslationKey
							) ?? ''
						);
					}

					if (instrument.tagsList.length) {
						data.push(...instrument.tagsList);
					}

					return data;
				}),
			flatten
		)(this.instrumentsToSearch);
	}
}
