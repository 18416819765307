/*
	Lightweight Charts - Working with time zones
	URL: https://github.com/tradingview/lightweight-charts/blob/7104e9a4fb399f18db7a2868a91b3246014c4324/docs/time-zones.md
*/
export function timeToLocal(originalTime: number): number {
	const d = new Date(originalTime);
	return (
		Date.UTC(
			d.getFullYear(),
			d.getMonth(),
			d.getDate(),
			d.getHours(),
			d.getMinutes(),
			d.getSeconds(),
			d.getMilliseconds()
		) / 1000
	);
}
