import { isNumber } from 'lodash-es';

import { DomService } from '../../services';

export class Tabs {
	private readonly tabActiveClass: string = 'tab__item--active';
	private readonly contentActiveClass: string = 'tab__content--active';

	constructor(
		private readonly onTabChange?: (tabClicked: string) => void,
		private readonly initialActiveItemIndex?: number,
		private readonly elementsNode?: HTMLElement
	) {
		if (this.elementsNode) {
			const tabWrapper = DomService.getElement<HTMLElement>(
				'.tab__wrapper',
				this.elementsNode
			);
			const contentWrapper = DomService.getElement<HTMLElement>(
				'.tab__content-wrapper',
				this.elementsNode
			);

			if (!tabWrapper || !contentWrapper) {
				return;
			}

			this.initTab(tabWrapper, contentWrapper);
		} else {
			this.findAndInitAllTabs();
		}
	}

	private initTab(
		wrapperElement: HTMLElement,
		contentWrapper: HTMLElement
	): void {
		const tabItems = DomService.getElements<HTMLSelectElement>(
			'.tab__item:not(.tab__item--not-clickable)',
			wrapperElement
		);

		if (!tabItems) {
			return;
		}

		if (isNumber(this.initialActiveItemIndex)) {
			tabItems[this.initialActiveItemIndex].classList.add(
				this.tabActiveClass
			);
			const contents = DomService.getElements<HTMLSelectElement>(
				`.tab__content`,
				contentWrapper
			);

			if (contents && contents.length > this.initialActiveItemIndex) {
				contents[this.initialActiveItemIndex].classList.add(
					this.contentActiveClass
				);
			}
		}

		tabItems.forEach((element: HTMLElement, tabIndex: number) => {
			element.addEventListener('click', () => {
				const activeItem = DomService.getElement<HTMLSelectElement>(
					`.${this.tabActiveClass}`,
					wrapperElement
				);

				if (!activeItem) {
					return;
				}

				const activeContent = DomService.getElement<HTMLSelectElement>(
					`.${this.contentActiveClass}`,
					contentWrapper
				);
				activeItem.classList.remove(this.tabActiveClass);

				if (activeContent) {
					activeContent.classList.remove(this.contentActiveClass);
				}

				element.classList.add(this.tabActiveClass);

				if (contentWrapper.children[tabIndex]) {
					(
						contentWrapper.children[tabIndex] as HTMLElement
					).classList.add(this.contentActiveClass);
				}

				if (this.onTabChange) {
					this.onTabChange(element.innerText);
				}
			});
		});
	}

	private findAndInitAllTabs(): void {
		const tabWrappers =
			DomService.getElements<HTMLSelectElement>('.tab__wrapper');
		const contentWrappers = DomService.getElements<HTMLSelectElement>(
			'.tab__content-wrapper'
		);

		if (!tabWrappers || !contentWrappers) {
			return;
		}

		tabWrappers.forEach((wrapperElement: HTMLElement, index: number) => {
			const contentWrapper = contentWrappers[index];
			this.initTab(wrapperElement, contentWrapper);
		});
	}
}
