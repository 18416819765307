import { InstrumentsCollectionQuotes, Tabs } from '../modules';
import { DomService } from '../services';

export class EquitiesPage {
	constructor() {
		if (document.querySelector('body.page-offer-equities')) {
			this.initInstrumentsTable();
		}
	}

	private getActiveTabElement(): HTMLElement | undefined {
		return DomService.getElement<HTMLElement>(`.tab__content--active`);
	}

	private initInstrumentsTable(): void {
		const firstTableElement = this.getActiveTabElement();

		if (!firstTableElement) {
			return;
		}

		const instrumentsCollectionQuotes = new InstrumentsCollectionQuotes(
			undefined,
			undefined,
			firstTableElement
		);

		const tabs = new Tabs(() => {
			instrumentsCollectionQuotes.updateElements(
				this.getActiveTabElement()
			);
		});
	}
}

DomService.runOnDomReady(() => new EquitiesPage());
